import { Button, Flex } from "@mantine/core"
import { useTranslation } from "react-i18next"

type Props = {
  onClose: () => void
  onSave: () => void
}

export const QuestionTableFooter = ({ onSave, onClose }: Props) => {
  const { t } = useTranslation("survey")

  return (
    <Flex
      justify="end"
      gap={12}
      p={16}
      style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
    >
      <Button variant="light" color="gray" onClick={onClose}>
        {t("cancel")}
      </Button>
      <Button
        onClick={() => {
          onSave()
          onClose()
        }}
      >
        {t("questions.selectQuestions")}
      </Button>
    </Flex>
  )
}
