import { useQuery } from "@tanstack/react-query"

import { CompanyResponses } from "@kiosk/types/endpoints/companies"

import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const getCompanyUsers = async (
  id: string,
): Promise<CompanyResponses.GetCompanyUsers> => {
  const response = await apiClient.get(`/companies/${id}/users`)
  return response.data
}

export const useCompanyUsersQuery = (id: string) => {
  return useQuery({
    queryKey: companyKeys.companyUsers(id),
    queryFn: () => getCompanyUsers(id),
    refetchOnMount: true,
  })
}
