import { Group, Text } from "@mantine/core"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { SurveyStatus } from "@kiosk/types/endpoints/surveys"

type Props = {
  status: SurveyStatus
}

export const SurveyStatusPill = ({ status }: Props) => {
  const { t } = useTranslation("survey")

  const { label, color } = useMemo(() => {
    const statusMap: Record<SurveyStatus, { label: string; color: string }> = {
      NOT_STARTED: { label: t("statuses.notStarted"), color: "gray.1" },
      IN_PROGRESS: { label: t("statuses.inProgress"), color: "yellow.1" },
      MISSING_ASSIGNEE: {
        label: t("statuses.missingAssignee"),
        color: "orange.1",
      },
      DONE: { label: t("statuses.done"), color: "green.1" },
    } as const

    return statusMap[status]
  }, [t, status])

  return (
    <Group bg={color} h={26} px={10} style={{ borderRadius: 6 }}>
      <Text>{label}</Text>
    </Group>
  )
}
