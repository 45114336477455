import {
  Text,
  Button,
  Group,
  Progress,
  Stack,
  ActionIcon,
  Modal,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import { orderBy } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { SurveyResponses, SurveyStatus } from "@kiosk/types/endpoints/surveys"

import { useDeleteSurveyMutation } from "@kiosk/front/api/surveys/deleteSurvey"
import { Table } from "@kiosk/front/components/generic"
import { Column } from "@kiosk/front/components/generic/types"
import { SurveyStatusPill } from "@kiosk/front/pages/Surveys/SurveyStatusPill"
import {
  isSurveyEmpty,
  isSurveyOver,
  isSurveyStarted,
} from "@kiosk/front/pages/Surveys/utils"
import { routes } from "@kiosk/front/utils/constants"
import { searchInArray } from "@kiosk/front/utils/text"

const DeleteSurveyButton = ({ survey }: { survey: SurveyResponses.Survey }) => {
  const { t } = useTranslation("survey")
  const [opened, { open, close }] = useDisclosure(false)

  const { mutateAsync: deleteSurvey, isPending } = useDeleteSurveyMutation(
    survey.id,
  )

  return (
    <>
      <ActionIcon
        variant="subtle"
        aria-label={t("actions.delete")}
        disabled={isSurveyOver(survey) && !isSurveyEmpty(survey)}
        onClick={open}
      >
        <IconTrash color="gray" />
      </ActionIcon>
      <Modal opened={opened} onClose={close} title={t("confirmDeleteTitle")}>
        <Stack gap={32}>
          <Text>{t("confirmDeleteContent")}</Text>
          <Group justify="end">
            <Button variant="light" color="gray" onClick={close}>
              {t("actions.cancel")}
            </Button>
            <Button
              color="red"
              loading={isPending}
              onClick={async () => {
                await deleteSurvey()
                showNotification({
                  color: "green",
                  message: t("surveyDeleted"),
                })
                close()
              }}
            >
              {t("actions.delete")}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  )
}

const SurveysTable = ({ surveys }: { surveys: SurveyResponses.Survey[] }) => {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")

  const columns: Column<SurveyResponses.Survey>[] = useMemo(
    () => [
      { key: "title", colId: "title", title: t("labels.name") },
      {
        key: "status",
        width: 160,
        colId: "status",
        title: t("labels.status"),
        render: (status: SurveyStatus) => <SurveyStatusPill status={status} />,
      },
      {
        key: "progress",
        colId: "id",
        width: 160,
        title: t("labels.progress"),
        render: (_: string, record: SurveyResponses.Survey) => {
          const progress =
            (record.answersCount / Math.max(1, record.assignmentsCount)) * 100
          return (
            <Stack gap={0} flex={1}>
              {record.answersCount} / {record.assignmentsCount}
              <Progress
                color={
                  progress < 50
                    ? "yellow.4"
                    : progress < 100
                      ? "green.4"
                      : "green"
                }
                value={progress}
                size={4}
              />
            </Stack>
          )
        },
      },
      {
        key: "launchDate",
        colId: "launchDate",
        title: t("labels.launchDate"),
        render: (launchDate: Date) => dayjs(launchDate).format("ll"),
      },
      {
        key: "followUpDate",
        colId: "followUpDate",
        title: t("labels.followUpDate"),
        render: (followUpDate: Date) => dayjs(followUpDate).format("ll"),
      },
      {
        key: "dueDate",
        colId: "dueDate",
        title: t("labels.dueDate"),
        render: (dueDate: Date) => dayjs(dueDate).format("ll"),
      },
      {
        key: "actions",
        colId: "id",
        title: "",
        render: (id: string, survey: SurveyResponses.Survey) => (
          <Group flex={1} justify="center">
            <Button
              variant="subtle"
              onClick={() =>
                navigate(routes.EDIT_SURVEY.path.replace(":id", id))
              }
            >
              {isSurveyStarted(survey)
                ? t("actions.seeResponse")
                : t("actions.edit")}
            </Button>
          </Group>
        ),
      },
      {
        key: "delete",
        colId: "id",
        title: "",
        render: (_: string, survey: SurveyResponses.Survey) => (
          <DeleteSurveyButton survey={survey} />
        ),
      },
    ],
    [navigate, t],
  )

  return (
    <Table
      dataSource={orderBy(surveys, ({ dueDate }) => dueDate, "desc")}
      columns={columns}
      withColumnBorders
    />
  )
}

interface SurveyListProps {
  search: string
  surveys: SurveyResponses.Survey[]
}
export const SurveysList = (props: SurveyListProps) => {
  return (
    <SurveysTable
      surveys={searchInArray(props.surveys, props.search, "title")}
    />
  )
}
