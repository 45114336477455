import { z } from "zod"

import { i18n } from "@kiosk/i18n"

const baseSurveyDataStructure = {
  title: z.string().min(1),
  launchDate: z.coerce.date(),
  followUpDate: z.coerce.date(),
  dueDate: z.coerce.date(),
} as const

const surveyDataSchema = z
  .object(baseSurveyDataStructure)
  .refine((schema) => schema.launchDate < schema.followUpDate, {
    message: i18n.t("survey:errors.followUpAfterLaunch"),
    path: ["followUpDate"],
  })
  .refine((schema) => schema.launchDate < schema.dueDate, {
    message: i18n.t("survey:errors.dueAfterLaunch"),
    path: ["dueDate"],
  })
  .refine((schema) => schema.followUpDate < schema.dueDate, {
    message: i18n.t("survey:errors.dueAfterFollowUp"),
    path: ["dueDate"],
  })

const fullSurveySchema = z.object({
  ...baseSurveyDataStructure,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  companyId: z.string(),
  creatorId: z.string(),
  status: z.union([
    z.literal("NOT_STARTED"),
    z.literal("IN_PROGRESS"),
    z.literal("MISSING_ASSIGNEE"),
    z.literal("DONE"),
  ]),
  assignmentsCount: z.number(),
  answersCount: z.number(),
})

export const getSurveySchema = fullSurveySchema
export const listSurveysSchema = z.array(fullSurveySchema)

export const createSurveySchema = surveyDataSchema
export type CreateSurveyBody = z.infer<typeof createSurveySchema>

export const updateSurveySchema = createSurveySchema
export type UpdateSurveyBody = z.infer<typeof updateSurveySchema>
