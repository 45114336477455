import { Button, Group, Modal, rem } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { useDeleteTaskMutation } from "@kiosk/front/api/tasks/deleteTask"

type Props = {
  taskId: string | undefined
  close: () => void
  ownerId: string | undefined
  opened: boolean
  closeEditTaskModal: () => void
  resetForm: () => void
}

export const DeleteTaskModal = ({
  taskId,
  opened,
  close,
  closeEditTaskModal,
  resetForm,
}: Props) => {
  const { t } = useTranslation(["common", "task"])
  const { mutateAsync: deleteTask, isPending } = useDeleteTaskMutation()
  const onConfirm = async () => {
    if (taskId) {
      await deleteTask(taskId)
    }
    closeEditTaskModal()
    close()
    resetForm()
  }

  return (
    <Modal.Root centered opened={opened} onClose={close}>
      <Modal.Overlay />
      <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
        <Modal.Header>
          <Modal.Title c="gray.9" fz="xxl" fw={600}>
            {t("task:deleteTaskTitle")}
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Group justify="flex-end">
            <Button color="gray.2" c="gray.9" onClick={close}>
              {t("common:buttons.cancel")}
            </Button>
            <Button
              color="red.8"
              onClick={onConfirm}
              disabled={isPending}
              loading={isPending}
            >
              {t("task:deleteTask")}
            </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
