import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import i18n, { LanguageDetectorModule } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import _ from "lodash"
import { initReactI18next } from "react-i18next"

import commonEn from "./locales/en/common.json"
import commonFr from "./locales/fr/common.json"
import companyEn from "./locales/en/company.json"
import companyFr from "./locales/fr/company.json"
import csrdEn from "./locales/en/csrd.json"
import csrdFr from "./locales/fr/csrd.json"
import dashboardEn from "./locales/en/dashboard.json"
import dashboardFr from "./locales/fr/dashboard.json"
import dataPointsEn from "./locales/en/dataPoints.json"
import dataPointsFr from "./locales/fr/dataPoints.json"
import dimensionsEn from "./locales/en/dimensions.json"
import dimensionsFr from "./locales/fr/dimensions.json"
import indicatorsEn from "./locales/en/indicators.json"
import indicatorsFr from "./locales/fr/indicators.json"
import onboardingEn from "./locales/en/onboarding.json"
import onboardingFr from "./locales/fr/onboarding.json"
import settingsEn from "./locales/en/settings.json"
import settingsFr from "./locales/fr/settings.json"
import sourcesEn from "./locales/en/sources.json"
import sourcesFr from "./locales/fr/sources.json"
import surveyEn from "./locales/en/survey.json"
import surveyFr from "./locales/fr/survey.json"
import tablesEn from "./locales/en/tables.json"
import tablesFr from "./locales/fr/tables.json"
import taskEn from "./locales/en/task.json"
import taskFr from "./locales/fr/task.json"
import taskCategoryEn from "./locales/en/taskCategory.json"
import taskCategoryFr from "./locales/fr/taskCategory.json"

export const DEFAULT_NAMESPACE = "common" as const

export const SUPPORTED_LOCALES = ["en", "fr"] as const

export type Locale = (typeof SUPPORTED_LOCALES)[number]

export const LOCALES: { label: string; value: Locale }[] = [
  { value: "en", label: "English" },
  { value: "fr", label: "Français" },
]

const userLanguageDetector: LanguageDetectorModule = {
  type: "languageDetector",
  detect: () => {
    if (typeof localStorage === "undefined") return undefined
    const lang = localStorage.getItem("i18nextLng")
    if (lang) return lang

    // TODO: load language from user settings (token if authenticated)
    return undefined
  },
  init: _.noop,
  cacheUserLanguage: (lng: string) => {
    if (typeof localStorage === "undefined") return undefined
    localStorage.setItem("i18nextLng", lng)
  },
}

export const resources = {
  en: {
    common: commonEn,
    company: companyEn,
    csrd: csrdEn,
    dashboard: dashboardEn,
    dataPoints: dataPointsEn,
    dimensions: dimensionsEn,
    indicators: indicatorsEn,
    onboarding: onboardingEn,
    settings: settingsEn,
    sources: sourcesEn,
    survey: surveyEn,
    tables: tablesEn,
    task: taskEn,
    taskCategory: taskCategoryEn,
  },
  fr: {
    common: commonFr,
    company: companyFr,
    csrd: csrdFr,
    dashboard: dashboardFr,
    dataPoints: dataPointsFr,
    dimensions: dimensionsFr,
    indicators: indicatorsFr,
    onboarding: onboardingFr,
    settings: settingsFr,
    sources: sourcesFr,
    survey: surveyFr,
    tables: tablesFr,
    task: taskFr,
    taskCategory: taskCategoryFr,
  },
}

dayjs.extend(LocalizedFormat)

i18n.on("initialized", (initOptions) => {
  dayjs.locale(initOptions.lng)
})

i18n.on("languageChanged", () => {
  dayjs.locale(i18n.language)
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(userLanguageDetector)
  .init({
    ns: Object.keys(resources.en),
    defaultNS: DEFAULT_NAMESPACE,
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export { i18n }
