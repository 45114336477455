import { Group, Text, ThemeIcon } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react"
import { useCallback, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

import { NavBarItemProps } from "@kiosk/front/utils/constants/useNavbarItems"

export const NavBarItem = ({
  label,
  href,
  icon: Icon,
  subItems,
}: NavBarItemProps) => {
  const location = useLocation()
  const { ref, hovered } = useHover()

  const isActive = useMemo(
    () =>
      hovered ||
      location.pathname === href ||
      (subItems && location.pathname.includes(href)),
    [subItems, hovered, href, location.pathname],
  )

  const isActiveSubLink = useCallback(
    (subHref: string) => location.pathname === subHref,
    [location.pathname],
  )

  return (
    <>
      <Link to={href}>
        <Group
          ref={ref}
          justify="space-between"
          p={12}
          bg={isActive ? "green.1" : "initial"}
          style={{ borderRadius: "var(--mantine-radius-md)" }}
        >
          <Group gap={12}>
            <ThemeIcon variant="white" color={isActive ? "black" : "gray.5"}>
              <Icon />
            </ThemeIcon>
            <Text
              c={isActive ? "black" : "gray.5"}
              fw={isActive ? 600 : "normal"}
            >
              {label}
            </Text>
          </Group>
          {subItems && (
            <ThemeIcon variant="white" color={isActive ? "black" : "gray.5"}>
              {location.pathname.includes(href) ? (
                <IconChevronDown />
              ) : (
                <IconChevronRight />
              )}
            </ThemeIcon>
          )}
        </Group>
      </Link>
      {subItems && location.pathname.includes(href)
        ? subItems.map(({ label: subLabel, href: subHref }) => (
            <Link key={subLabel} to={subHref}>
              <Group
                ref={ref}
                gap={12}
                p="12px 48px"
                bg={isActiveSubLink(subHref) ? "green.1" : "initial"}
                style={{ borderRadius: "var(--mantine-radius-md)" }}
              >
                <Text
                  c={isActiveSubLink(subHref) ? "black" : "gray.5"}
                  fw={isActiveSubLink(subHref) ? 600 : "normal"}
                >
                  {subLabel}
                </Text>
              </Group>
            </Link>
          ))
        : null}
    </>
  )
}
