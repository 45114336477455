import type { SVGProps } from "react"

import { config } from "@kiosk/front/config"

import GreensterLogo from "../../assets/img/greenster.png"
import KioskLogo from "../../assets/img/kiosk_full.svg"

const logoComponents: Record<"kiosk" | "greenster", string> = {
  kiosk: KioskLogo,
  greenster: GreensterLogo,
}

export const Logo = (props: SVGProps<SVGSVGElement>) => {
  const source = logoComponents[config.logo]
  return <img src={source} width={props.width} height={props.height} />
}
