import { Textarea, TextInput } from "@mantine/core"

import { TextBlockRecord } from "@kiosk/types/data/dato"

import { InputProps } from "@kiosk/front/components/csrd/questions/types"

export const TextBlockQuestion = ({
  content,
  formProps,
}: InputProps<TextBlockRecord>) => {
  return content.inputType === "text" ? (
    <TextInput
      {...formProps}
      onChange={(event) => formProps.onChange(event.currentTarget.value)}
    />
  ) : (
    <Textarea
      {...formProps}
      onChange={(event) => formProps.onChange(event.currentTarget.value)}
    />
  )
}
