import { z } from "zod"

const baseDimensionDataStructure = {
  dimensionType: z.union([z.literal("explicit"), z.literal("typed")]),
  dimensionDatoId: z.string(),
  optionId: z.string(),
}

const baseAssignmentDataStructure = {
  assigneeId: z.string().nullable(),
  value: z.number().nullable(),
  comment: z.string().nullable(),
  fileName: z.string().nullable(),
  filePath: z.string().nullable(),
  fileSignedUrl: z.string().nullable(),
}

const fullAssignmentSchema = z.object({
  ...baseAssignmentDataStructure,
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  questionId: z.string(),
  assignee: z
    .object({
      id: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
    })
    .nullable(),
  assignmentDimensions: z.array(
    z.object({
      ...baseDimensionDataStructure,
      id: z.string(),
      createdAt: z.coerce.date(),
      updatedAt: z.coerce.date(),
      assignmentId: z.string(),
    }),
  ),
})

export const listAssignmentsSchema = z.array(fullAssignmentSchema)

export const createAssignmentSchema = z.object({
  assigneeId: z.string().nullable().optional(),
  value: z.number().nullable().optional(),
  comment: z.string().nullable().optional(),
  fileName: z.string().nullable().optional(),
  filePath: z.string().nullable().optional(),
  assignmentDimensions: z.array(z.object(baseDimensionDataStructure)),
})
export type CreateAssignmentBody = z.infer<typeof createAssignmentSchema>

export const updateAssignmentSchema = createAssignmentSchema
export type UpdateAssignmentBody = z.infer<typeof updateAssignmentSchema>

export const createBulkAssignmentSchema = z.array(createAssignmentSchema)
export type CreateBulkAssignmentBody = z.infer<
  typeof createBulkAssignmentSchema
>

export const getContributorAssignmentsSchema = z.object({
  surveyId: z.string(),
})

export const answerAssignmentSchema = z.object({
  value: z.number().nullable(),
  comment: z.string().nullable(),
  fileName: z.string().nullable(),
  filePath: z.string().nullable(),
})
