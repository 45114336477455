import { useQuery } from "@tanstack/react-query"

import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/types/endpoints/surveys"

import { listSurveyQuestionsSchema } from "@kiosk/shared/schemas/surveyQuestions"
import { getSurveySchema } from "@kiosk/shared/schemas/surveys"

import { surveysKeys } from "@kiosk/front/api/surveys/surveysKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const useGetSurveyQuery = (id: string) =>
  useQuery({
    queryKey: surveysKeys.get(id),
    queryFn: async (): Promise<{
      survey: SurveyResponses.Survey
      surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
    }> => {
      const surveyPromise = apiClient.get<SurveyResponses.Survey>(
        `/surveys/${id}`,
      )
      const surveyQuestionsPromise = apiClient.get<
        SurveyQuestionResponses.SurveyQuestion[]
      >(`/surveys/${id}/questions`)

      const [surveyResponse, surveyQuestionsResponse] = await Promise.all([
        surveyPromise,
        surveyQuestionsPromise,
      ])

      return {
        survey: await getSurveySchema.parseAsync(surveyResponse.data),
        surveyQuestions: await listSurveyQuestionsSchema.parseAsync(
          surveyQuestionsResponse.data,
        ),
      }
    },
  })
