import { Box, Flex, Group, Stack, Text, TextInput } from "@mantine/core"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useAuthState } from "@kiosk/front/api/account/queryMe"

export const StructureDefintion = () => {
  const { user } = useAuthState()
  const { t } = useTranslation("onboarding")

  const structure = useMemo(
    () => [
      { label: t("definitions.company"), examples: user?.company?.name },
      { label: t("definitions.region"), examples: t("examples.region") },
      { label: t("definitions.site"), examples: t("examples.site") },
    ],
    [t, user?.company?.name],
  )

  return (
    <Flex direction="column" gap={0}>
      {structure.map(({ label, examples }, index) => (
        <Group wrap="nowrap" key={`tree-${index}`} ml={index * 8} gap={4}>
          {index > 0 && (
            <Flex
              w={9}
              mb={40}
              ml={(index - 1) * 13}
              h="calc(100% - 40px)"
              style={{
                borderBottomLeftRadius: 8,
                borderBottom: "solid 1px var(--mantine-color-gray-4)",
                borderLeft: "solid 1px var(--mantine-color-gray-4)",
              }}
            />
          )}
          <Stack flex={1} gap={0} pt={index === 0 ? 0 : 20}>
            <TextInput
              readOnly
              value={label}
              label={t("level", { level: index + 1 })}
            />
            <Group align="flex-end">
              {index < structure.length - 1 && (
                <Flex
                  flex={0}
                  ml={8}
                  w={1}
                  h={24}
                  style={{
                    borderLeft: "solid 1px var(--mantine-color-gray-4)",
                  }}
                />
              )}
              <Box w={`calc(100% - ${index * 40 + 36}px)`}>
                <Text c="gray.4" fz="sm" truncate="end">
                  {examples}
                </Text>
              </Box>
            </Group>
          </Stack>
        </Group>
      ))}
    </Flex>
  )
}
