import { ActionIcon, Flex, Group, Stack, Text } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"
import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { useListDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { useListAssignmentsQuery } from "@kiosk/front/api/surveys/assignments/listAssignments"
import { useGetSurveyQuestionQuery } from "@kiosk/front/api/surveys/questions/getSurveyQuestion"
import { BackButton } from "@kiosk/front/components/BackButton"
import { Table } from "@kiosk/front/components/generic"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { AssignmentDimensionsCell } from "@kiosk/front/pages/Surveys/EditQuestion/AssignmentDimensionsCell"
import { AnswerCell } from "@kiosk/front/pages/Surveys/SurveyAnswers/AnswerCell"

type Props = {
  surveyQuestion: SurveyQuestionResponses.SurveyQuestion
  assignments: AssignmentResponses.Assignment[]
  allDimensions: ConsolidatedDimension[]
}

const SurveyAnswersContent = ({
  surveyQuestion,
  assignments,
  allDimensions,
}: Props) => {
  const { t } = useTranslation("survey")
  const navigate = useNavigate()

  const columns = useMemo(
    () => [
      {
        key: "label",
        colId: "assignmentDimensions",
        title: t("breakdownsHeader"),
        render: (
          assignmentDimensions: AssignmentResponses.AssignmentDimension[],
        ) => (
          <AssignmentDimensionsCell
            assignmentDimensions={assignmentDimensions}
            allDimensions={allDimensions}
          />
        ),
      },
      {
        key: "answer",
        colId: "value",
        title: t("answersHeader"),
        render: (value: string, record: AssignmentResponses.Assignment) => (
          <AnswerCell {...{ value, record }} />
        ),
      },
      {
        key: "assignee",
        colId: "assignee",
        title: t("assigneeHeader"),
        render: (assignee: AssignmentResponses.Assignment["assignee"]) => (
          <Stack gap={0}>
            <Text>
              {assignee?.firstName} {assignee?.lastName}
            </Text>
            <Text c="gray.5">{assignee?.email}</Text>
          </Stack>
        ),
      },
      {
        key: "file",
        colId: "fileName",
        title: t("fileHeader"),
        width: 200,
        render: (
          _: string,
          { fileSignedUrl, fileName }: AssignmentResponses.Assignment,
        ) =>
          fileSignedUrl &&
          fileName && (
            <Group wrap="nowrap" maw={200}>
              <ActionIcon
                variant="subtle"
                color="gray.4"
                onClick={() => window.open(fileSignedUrl, "_blank")}
              >
                <IconDownload size={24} />
              </ActionIcon>
              <Text truncate="end">{fileName}</Text>
            </Group>
          ),
      },
    ],
    [t, allDimensions],
  )

  return (
    <Stack gap={30}>
      <Flex align="center" justify="space-between" gap={16}>
        <BackButton
          onClick={async () => {
            navigate(`/sources/edit_survey/${surveyQuestion.surveyId}`)
          }}
        />
        <Text fw={600} size="xl" variant="unstyled" truncate="end" flex={1}>
          {surveyQuestion.label}
        </Text>
      </Flex>
      <Table
        dataSource={assignments}
        getRowKey={(assignment: AssignmentResponses.Assignment) =>
          assignment.id
        }
        columns={columns}
        withColumnBorders
        flex={1}
        highlightOnHoverColor="emerald.0"
        footer={<></>}
      />
    </Stack>
  )
}

export const SurveyAnswers = () => {
  const { id } = useParams() as { id: string }
  const getSurveyQuestionQuery = useGetSurveyQuestionQuery(id)
  const getAssignmentsQuery = useListAssignmentsQuery(id)
  const getAllDimensionsQuery = useListDimensionsQuery()

  return (
    <PageLayout>
      <QueryWrapper query={getSurveyQuestionQuery}>
        {({ data: surveyQuestion }) => (
          <QueryWrapper query={getAssignmentsQuery}>
            {({ data: assignments }) => (
              <QueryWrapper query={getAllDimensionsQuery}>
                {({ data: allDimensions }) => (
                  <SurveyAnswersContent
                    surveyQuestion={surveyQuestion}
                    assignments={assignments}
                    allDimensions={allDimensions}
                  />
                )}
              </QueryWrapper>
            )}
          </QueryWrapper>
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
