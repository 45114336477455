import { Container, Stack, Loader } from "@mantine/core"
import { useParams } from "react-router-dom"

import { useListDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { useGetContributorAssignmentsQuery } from "@kiosk/front/api/surveys/assignments/getContributorAssignments"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { AnswerSurveyContent } from "@kiosk/front/pages/Surveys/AnswerSurvey/AnswerSurveyContent"

export const AnswerSurvey = () => {
  const { id } = useParams() as { id: string }
  const getContributorAssignmentsQuery = useGetContributorAssignmentsQuery(id)
  const { data: allDimensions } = useListDimensionsQuery()

  // TODO: better loading
  if (!allDimensions)
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )

  return (
    <PageLayout>
      <QueryWrapper query={getContributorAssignmentsQuery}>
        {({ data: survey }) => (
          <AnswerSurveyContent {...{ survey, allDimensions }} />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
