import { Button } from "@mantine/core"

import { BooleanRecord } from "@kiosk/types/data/dato"

import { InputProps } from "@kiosk/front/components/csrd/questions/types"

export const PlainBooleanChoice = ({
  content,
  formProps,
}: InputProps<BooleanRecord>) => {
  const { yesLabel = "Yes", noLabel = "No" } = content

  return (
    <Button.Group>
      <Button
        miw={140}
        color={formProps.value === true ? "green" : "gray.5"}
        variant={formProps.value === true ? "outlight" : "outline"}
        onClick={() => formProps.onChange(true)}
        style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
      >
        {yesLabel}
      </Button>
      <Button
        miw={140}
        color={formProps.value === false ? "green" : "gray.5"}
        variant={formProps.value === false ? "outlight" : "outline"}
        onClick={() => formProps.onChange(false)}
        style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
      >
        {noLabel}
      </Button>
    </Button.Group>
  )
}
