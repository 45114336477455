// TODO: merge this form with CSRDForm

import { Box, Group, Space, Stack, Text, Textarea } from "@mantine/core"
import { useForm } from "@mantine/form"
import _, { Dictionary } from "lodash"
import { useMemo } from "react"

import { CSRDQuestionAnswer } from "@kiosk/types/csrd"
import {
  DatoDisclosureRequirementNamesResponse as DatoCategoriesTopicsNamesResponse,
  DatoDisclosureRequirementResponse,
  DisclosureRequirementDetails,
} from "@kiosk/types/data/dato"

import { CSRD_DISCLOSURE_REQUIREMENT_BY_ID } from "@kiosk/shared/datocms/queries"
import { isDefined } from "@kiosk/shared/utils/helpers"

import {
  useDatoCategoriesTopicsNames,
  useDatoQuery,
} from "@kiosk/front/api/datocms/datocms"
import {
  useDisclosureRequirementAnswersQuery,
  useMarkDisclosureRequirementAsDoneMutation,
  useUpsertDisclosureRequirementAnswersMutation,
} from "@kiosk/front/api/reports"
import { reportsKeys } from "@kiosk/front/api/reports/reportsKey"
import { Callout } from "@kiosk/front/components/Callout"
import { DatoStructuredText } from "@kiosk/front/components/DatoStructuredText"
import { EsrsBadge } from "@kiosk/front/components/csrd/EsrsBadge"
import { MaterialitySwitch } from "@kiosk/front/components/csrd/MaterialSwitch"
import { SaveFooter } from "@kiosk/front/components/csrd/SaveFooter"
import { TopicBadge } from "@kiosk/front/components/csrd/TopicBadge"
import { MultiQueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import config, {
  findEsrsById,
  findEsrsByIro2Label,
} from "@kiosk/front/features/iro2/config"
import { queryClient } from "@kiosk/front/lib/queryClient"

interface Section {
  name: string
  subTopics: {
    esrs: string
    name: string
    datoId: string
  }[]
}

interface IRO2PageProps {
  initialValues: Record<string, CSRDQuestionAnswer>
  disclosureRequirement: DisclosureRequirementDetails
  categoriesTopicsNames: DatoCategoriesTopicsNamesResponse
}

/**
 * IRO-2 is a special page.
 * In it, users will say which ESRS are material to them.
 *
 * In IRO-2, companies can specify which ESRS are not material to their business.
 * For that, we need to map the enumeration to the matching ESRS.
 *
 * Note that the page is currently strictly in English and is not updated from Dato
 * like the other ones.
 */
const IRO2Page = (props: IRO2PageProps) => {
  const nonMaterialTopicsQuestionId =
    config.disclosureRequirement.nonMaterialTopicsQuestionId
  const initialNonMaterialTopics = (props.initialValues[
    nonMaterialTopicsQuestionId
  ]?.answer.value ?? []) as string[]

  // Says whether an ESRS (identified by its value in the IRO-2 question enum)
  // is material or not.
  const initialMaterialityState: Dictionary<boolean> = Object.fromEntries(
    config.esrs.map((esrs) => [
      esrs.iro2EnumLabel,
      !initialNonMaterialTopics.includes(esrs.iro2EnumLabel),
    ]),
  )

  const form = useForm({
    initialValues: { ...props.initialValues, ...initialMaterialityState },
  })

  const materialityState = useMemo(
    () =>
      Object.fromEntries(
        config.esrs.map((esrs) => [
          esrs.iro2EnumLabel,
          form.getValues()[esrs.iro2EnumLabel],
        ]),
      ),
    [form],
  )
  const nonMaterialTopics = Object.entries(materialityState)
    .filter(([_esrs, isMaterial]) => !isMaterial)
    .map(([esrs, _isMaterial]) => esrs)

  const { mutateAsync: markAsDone, isPending: isMarkAsDonePending } =
    useMarkDisclosureRequirementAsDoneMutation()

  const {
    mutateAsync: upsertDisclosureRequirements,
    isPending: isUpsertPending,
  } = useUpsertDisclosureRequirementAnswersMutation(
    config.disclosureRequirement.datoId,
  )

  const sections: Section[] = _(config.esrs)
    .groupBy((esrs) => esrs.category)
    .entries()
    .map(([categoryId, esrsList]) => {
      const category = props.categoriesTopicsNames.allCsrdCategories.find(
        (cat) => cat.id === categoryId,
      )!

      return {
        name: category.name,
        subTopics: esrsList.map((esrs) => ({
          esrs: esrs.code,
          name: category.topics.find((topic) => topic.id === esrs.datoId)!.name,
          datoId: esrs.datoId,
        })),
      }
    })
    .value()

  const materialityAssessmentQuestion =
    props.disclosureRequirement.questions.find(
      (q) => q.id === "a7mMBhwTTU-YAdtIo5BOzA",
    )
  return (
    <Stack flex={1} pos="relative">
      <Stack p={24} align="stretch" justify="center">
        <Group wrap="nowrap">
          <EsrsBadge topic="2-IRO-2" />
          <Box>
            <Text fz="md" c="gray.9" fw={600} lineClamp={2}>
              {props.disclosureRequirement.name}
            </Text>
          </Box>
        </Group>
        {props.disclosureRequirement.instructions ? (
          <DatoStructuredText
            content={props.disclosureRequirement.instructions}
          />
        ) : null}
      </Stack>

      <form
        onSubmit={form.onSubmit((values) => {
          // map the switches (material/non material) to the list of ESRS
          // that are *not* material, as it’s what’s recorded in the DB for IRO-2.
          const nonMaterialTopicsAnswer: CSRDQuestionAnswer = {
            datoQuestionId:
              config.disclosureRequirement.nonMaterialTopicsQuestionId,
            answer: {
              type: "ChoiceRecord",
              value: config.esrs
                .filter((esrs) => !values[esrs.iro2EnumLabel])
                .map((esrs) => esrs.iro2EnumLabel),
            },
            dimensions: [],
          }

          const explanations: CSRDQuestionAnswer[] = []
          for (const esrs of config.esrs) {
            const answer = values[esrs.followUpQuestion.datoId]
            if (isDefined(answer) && typeof answer !== "boolean") {
              explanations.push(answer)
            }
          }

          const payload: CSRDQuestionAnswer[] = [
            nonMaterialTopicsAnswer,
            ...explanations,
          ]
          return upsertDisclosureRequirements(payload)
        })}
      >
        <Stack gap="xl" pl={30} pr={30}>
          {sections.map((s) => (
            <Stack key={s.name}>
              <Text fz="md" c="gray.9" fw={600} mb={10}>
                {s.name}
              </Text>
              <Stack gap="md" ml={30} mr={30}>
                {s.subTopics.map((st) => {
                  const esrs = findEsrsById(st.datoId)!
                  const inputProps = form.getInputProps(esrs.iro2EnumLabel)
                  return (
                    <Group key={st.name} w="auto" justify="space-between">
                      <Group>
                        <TopicBadge topic={st.esrs} />
                        <Text>{st.name}</Text>
                      </Group>
                      <MaterialitySwitch
                        {...inputProps}
                        value={inputProps.value}
                      />
                    </Group>
                  )
                })}
              </Stack>
            </Stack>
          ))}
          {_.isEmpty(nonMaterialTopics) ? null : (
            <>
              <Text fz="md" c="gray.9" fw={600}>
                {materialityAssessmentQuestion!.label}
              </Text>
              <Callout message={materialityAssessmentQuestion!.hint!} />
              {nonMaterialTopics.map((enumValue) => {
                const esrs = findEsrsByIro2Label(enumValue)!

                const relatedQuestion = props.disclosureRequirement.questions
                  .flatMap((q) => q.relatedQuestions)
                  .filter((q) => q !== undefined)
                  .find((q) => q!.id === esrs.followUpQuestion.datoId)!

                const inputProps = form.getInputProps(
                  esrs.followUpQuestion.datoId,
                )

                return (
                  <Stack key={enumValue}>
                    <Text fw="bold">{relatedQuestion.label}</Text>
                    <Textarea
                      {...inputProps}
                      value={inputProps?.value?.answer.value}
                      onChange={(event) => {
                        return inputProps.onChange({
                          datoQuestionId: esrs.followUpQuestion.datoId,
                          answer: {
                            type: "TextBlockRecord",
                            value: event.currentTarget.value,
                          },
                          dimensions: [],
                        })
                      }}
                    />
                  </Stack>
                )
              })}
            </>
          )}
        </Stack>
        <Space h={120} />
        <SaveFooter
          hasUnsavedChanges={form.isDirty()}
          isPendingUpdate={isUpsertPending || isMarkAsDonePending}
          onMarkAsDone={() => {
            markAsDone(config.disclosureRequirement.datoId)
          }}
          onCancel={() => {
            form.reset()
          }}
          onSave={() =>
            queryClient.invalidateQueries({
              queryKey: reportsKeys.getMateriality(),
            })
          } // TODO: cleanup, handled by the form
        />
      </form>
    </Stack>
  )
}

export const IRO2 = () => {
  const disclosureRequirementAnswersQuery =
    useDisclosureRequirementAnswersQuery(config.disclosureRequirement.datoId)

  const disclosureRequirementQuery =
    useDatoQuery<DatoDisclosureRequirementResponse>({
      query: CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
      variables: { id: config.disclosureRequirement.datoId },
    })

  const disclosureRequirementNamesQuery = useDatoCategoriesTopicsNames()

  return (
    <MultiQueryWrapper
      queries={{
        disclosureRequirementAnswers: {
          query: disclosureRequirementAnswersQuery,
          allowEmptyArray: true,
        },
        disclosureRequirement: {
          query: disclosureRequirementQuery,
        },
        disclosureRequirementNames: {
          query: disclosureRequirementNamesQuery,
        },
      }}
    >
      {({
        disclosureRequirementAnswers,
        disclosureRequirement,
        disclosureRequirementNames,
      }) => (
        <IRO2Page
          initialValues={disclosureRequirementAnswers}
          disclosureRequirement={disclosureRequirement.disclosureRequirement}
          categoriesTopicsNames={disclosureRequirementNames}
        />
      )}
    </MultiQueryWrapper>
  )
}
