import { Button, Group, Stack, Text } from "@mantine/core"
import { IconCheck, IconEdit, IconTag } from "@tabler/icons-react"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { DimensionBreakdownValue } from "@kiosk/types/dimensions"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { TypedDimensionFillerWrapper } from "@kiosk/front/components/dimensions/TypedDimensionFillerWrapper"
import { OptionsPicker } from "@kiosk/front/pages/Surveys/EditQuestion/OptionsPicker"
import {
  enrichDimensionBreakdowns,
  EnrichedDimensionBreakdown,
  generateCartesianProduct,
} from "@kiosk/front/utils/dimensions"

type SharedProps = {
  initialBreakdowns: DimensionBreakdownValue[]
  onGenerateBreakdowns: (breakdowns: EnrichedDimensionBreakdown[]) => void
}

type RootProps = SharedProps & {
  dimensionIds: string[]
}

type FormProps = SharedProps & { dimensions: ConsolidatedDimension[] }

const BreakdownSelectForm = ({
  dimensions,
  initialBreakdowns,
  onGenerateBreakdowns,
}: FormProps) => {
  const { t } = useTranslation("survey")

  const { emptyState, initialState } = useMemo(() => {
    const dimensionsById = _.keyBy(dimensions, (dimension) => dimension.id)

    const emptyState = _.mapValues(dimensionsById, () => [])

    const initialValues = _.mapValues(
      _.groupBy(initialBreakdowns, (breakdown) => breakdown.dimensionId),
      (breakdowns) => breakdowns.map((breakdown) => breakdown.optionId),
    )
    const initialState = {
      ...emptyState,
      ...initialValues,
    }

    return { emptyState, initialState }
  }, [dimensions, initialBreakdowns])

  // Record<dimensionId, optionId[]>
  const [selectedOptionIds, setSelectedOptionIds] =
    useState<Record<string, string[]>>(initialState)

  const isValid = useMemo(
    () => Object.values(selectedOptionIds).every((options) => options.length),
    [selectedOptionIds],
  )
  const [isCommitted, setIsCommitted] = useState(initialBreakdowns.length > 0)

  const onGenerate = useCallback(() => {
    const combinations = generateCartesianProduct(selectedOptionIds)

    const enrichedBreakdowns = combinations.map((combination) => {
      return enrichDimensionBreakdowns(dimensions, combination)
    })

    onGenerateBreakdowns(enrichedBreakdowns)
    setIsCommitted(true)
  }, [onGenerateBreakdowns, selectedOptionIds, dimensions])

  useEffect(() => {
    // Launch generate if initial breakdowns exist
    if (isCommitted && initialBreakdowns.length) {
      onGenerate()
    }
    // TODO: investigate why onGenerate creates an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBreakdowns, isCommitted])

  return (
    <Stack
      style={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "var(--mantine-color-gray-2)",
        borderRadius: 6,
      }}
      bg="gray.0"
      p={16}
      gap={20}
    >
      <Group gap="sm" align="center">
        <IconTag />
        <Text fw={600}>{t("breakdownSelect")}</Text>
      </Group>
      <Group gap="sm" align="center">
        <Text ml={36}>{t("selectBreakdown")}</Text>
      </Group>
      {dimensions.map((dimension) => (
        <OptionsPicker
          key={dimension.id}
          optionIds={selectedOptionIds[dimension.id]}
          dimension={dimension}
          onChange={(options) =>
            setSelectedOptionIds((selectedOptionIds) => ({
              ...selectedOptionIds,
              [dimension.id]: options,
            }))
          }
          isCommitted={isCommitted}
        />
      ))}
      {isValid && !isCommitted && (
        <Button
          variant="outline"
          style={(theme) => ({
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 6,
          })}
          color="black"
          bg="white"
          leftSection={<IconCheck size={20} />}
          onClick={onGenerate}
        >
          {t("generateQuestion")}
        </Button>
      )}
      {isCommitted && (
        <Button
          variant="outline"
          style={(theme) => ({
            border: `1px solid ${theme.colors.gray[3]}`,
            borderRadius: 6,
          })}
          color="black"
          bg="white"
          leftSection={<IconEdit size={20} />}
          onClick={() => {
            setSelectedOptionIds(emptyState)
            onGenerateBreakdowns([])
            setIsCommitted(false)
          }}
        >
          {t("resetBreakdown")}
        </Button>
      )}
    </Stack>
  )
}

export const DimensionsBreakdownSelect = ({
  dimensionIds,
  initialBreakdowns,
  onGenerateBreakdowns,
}: RootProps) => {
  return (
    <TypedDimensionFillerWrapper datoDimensionIds={dimensionIds}>
      {({ dimensions }) => (
        <BreakdownSelectForm
          initialBreakdowns={initialBreakdowns}
          dimensions={dimensions}
          onGenerateBreakdowns={onGenerateBreakdowns}
        />
      )}
    </TypedDimensionFillerWrapper>
  )
}
