import _ from "lodash"

import { CSRDQuestionAnswer } from "@kiosk/types/csrd"
import { CSRDQuestions, Content } from "@kiosk/types/data/dato"
import { DimensionBreakdownValue } from "@kiosk/types/dimensions"

export const formatCsrdReportAnswerFormKey = (
  datoQuestionId: string,
  dimensionBreakdown?: DimensionBreakdownValue[],
): string => {
  if (!dimensionBreakdown?.length) return datoQuestionId
  return `${datoQuestionId}-${dimensionBreakdown.map((dimension) => `${dimension.dimensionId}:${dimension.optionId}`).join("-")}`
}

// TODO: nice to have a test here
const findChildrenAnswers = (
  question: CSRDQuestions<Content>,
  savedAnswers: Record<string, CSRDQuestionAnswer>,
): CSRDQuestionAnswer[] => {
  const flatAnswers = Object.values(savedAnswers)

  if (!question.relatedQuestions) return []
  return question.relatedQuestions.flatMap((relatedQuestion) => {
    const relatedAnswers = flatAnswers.filter(
      (answer) => answer.datoQuestionId === relatedQuestion.id,
    )
    return [
      ...relatedAnswers,
      ...findChildrenAnswers(relatedQuestion, savedAnswers),
    ]
  })
}

// TODO: nice to have a test here
export const getDimensionBreakdownParentQuestion = (
  question: CSRDQuestions<Content>,
  savedAnswers: Record<string, CSRDQuestionAnswer>,
): DimensionBreakdownValue[] => {
  if (!question.dimensions?.length) return []
  const childrenAnswers = findChildrenAnswers(question, savedAnswers)
  return _.uniqBy(
    childrenAnswers.flatMap((answer) => answer.dimensions),
    (dimension) => `${dimension.dimensionId}:${dimension.optionId}`,
  )
}
