import _ from "lodash"

import { i18n } from "@kiosk/i18n"

export const routes = {
  SETTINGS: {
    path: "/settings/:tab",
    name: i18n.t("routes.settings"),
  },
  TASKS: { path: "/tasks", name: i18n.t("routes.tasks") },
  SOURCES: { path: "/sources", name: i18n.t("routes.sources") },
  TABLES: { path: "/sources/tables", name: i18n.t("routes.tables") },
  SURVEYS: {
    path: "/sources/surveys",
    name: i18n.t("routes.surveys"),
  },
  EDIT_SURVEY: {
    path: "/sources/edit_survey/:id",
    name: i18n.t("routes.editSurvey"),
  },
  EDIT_QUESTION: {
    path: "/sources/edit_question/:id",
    name: i18n.t("routes.editQuestion"),
  },
  SURVEY_ANSWERS: {
    path: "/sources/survey_answers/:id",
    name: i18n.t("routes.surveyAnswers"),
  },
  ANSWER_SURVEY: {
    path: "/answer_survey/:id",
    name: i18n.t("routes.answerSurvey"),
  },
  DIMENSIONS: {
    path: "/dimensions",
    name: i18n.t("routes.dimensions"),
  },
  DASHBOARD: { path: "/", name: i18n.t("routes.dashboard") },
  COMPANIES: { path: "/companies", name: i18n.t("routes.companies") },
  USERS: {
    path: "/companies/:id/users",
    name: i18n.t("routes.users"),
  },
  // NOTE: Added this route just for testing purposes, will decide in next spring on how the onboarding should look like
  SETUP_ACCOUNT: {
    path: "/onboarding/setup-account",
    name: "setup-account",
  },
  CSRD: {
    path: "/csrd/:datoTopicId?",
    name: i18n.t("routes.csrd"),
  },
  CSRD_DISCLOSURE_REQUIREMENT: {
    path: "/csrd/:datoTopicId/:datoSubTopicId",
    name: i18n.t("routes.csrd"),
  },
  CONGRATULATIONS: {
    path: "/congratulations",
    name: i18n.t("routes.congratulations"),
  },
  ONBOARDING: {
    path: "/onboarding",
    name: i18n.t("routes.onboarding"),
  },
} as const

export type SettingsTab = "account" | "users" | "integrations" | "language"

export const composeSettingsTabRoute = (tab: SettingsTab) =>
  routes.SETTINGS.path.replace(":tab", tab)

export const routesMap: Record<string, string> = _.mapValues(
  _.keyBy(routes, (route) => route.path),
  (route) => route.name,
)
