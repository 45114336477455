import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { CategoryBody } from "@kiosk/shared/schemas/categories"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { taskKeys } from "./taskKeys"

export interface UpdateCategoryPayload {
  categoryId: string
  data: CategoryBody
}

export const updateCategory = async ({
  categoryId,
  data,
}: UpdateCategoryPayload) => {
  const response = await apiClient.put(`/categories/${categoryId}`, data)
  return response.data
}

export const useUpdateCategoryMutation = (
  setEditable: Dispatch<SetStateAction<boolean>>,
) => {
  const { t } = useTranslation("task")
  return useMutation({
    mutationFn: updateCategory,
    onSuccess: async () => {
      setEditable(false)
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      notifications.show({
        title: t("common:messages.success"),
        message: t("actions.updateCategorySuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
