import { Tabs, Flex } from "@mantine/core"
import { orderBy } from "lodash"
import { Dispatch, SetStateAction } from "react"

import { CsrdCategory } from "@kiosk/types/data/dato"

import { FilterButton } from "@kiosk/front/components/FilterButton"

export const SelectTopicPanel = ({
  topicId,
  setTopicId,
  csrdCategory,
}: {
  topicId: string | undefined
  csrdCategory: CsrdCategory
  setTopicId: Dispatch<SetStateAction<string | undefined>>
}) => (
  <Tabs.Panel value={csrdCategory.id}>
    <Flex gap="sm" direction="row" wrap="wrap" px={24} py={16}>
      {orderBy(csrdCategory.topics, ({ name }) => name).map((topic) => (
        <FilterButton
          key={topic.id}
          isSelected={topicId === topic.id}
          onClick={() => setTopicId(topic.id)}
          label={topic.name}
        />
      ))}
    </Flex>
  </Tabs.Panel>
)
