import { Group, Stack, Text } from "@mantine/core"

import { SingleValueDataPoint } from "@kiosk/front/api/dashboard/types"
import { formatNumber } from "@kiosk/front/utils/format"

type Props = {
  dataPoint: SingleValueDataPoint
}

export const DashboardBigNumber = ({ dataPoint }: Props) => (
  <Stack p={16} gap={8} justify="space-between">
    <Text fz="md" c="gray.5">
      {dataPoint.label}
    </Text>
    <Group gap={8} align="baseline">
      <Text fz="xxl" fw={600}>
        {dataPoint.value ? formatNumber(dataPoint.value) : "--"}
      </Text>
      {dataPoint.unit && (
        <Text fz="xl" fw={500} c="gray.5">
          {dataPoint.unit}
        </Text>
      )}
    </Group>
  </Stack>
)
