import { Flex, Stack, Text, Title } from "@mantine/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useCompleteOnBoardingMutation } from "@kiosk/front/api/onboarding/completeOnBoarding"
import { useUploadPrivateFileMutation } from "@kiosk/front/api/uploadFile"
import { DashedDropzone } from "@kiosk/front/components/DashedDropzone"
import { Footer } from "@kiosk/front/pages/Onboarding/Footer"
import { routes } from "@kiosk/front/utils/constants/routes"

export const UploadFileStep = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [uploadedFile, setUploadedFile] = useState<
    Express.Multer.File | undefined
  >(undefined)

  const {
    mutate: uploadFile,
    error: uploadError,
    isError: hasUploadError,
    isPending: isUploading,
  } = useUploadPrivateFileMutation({
    destination: "dsn-file/",
    sendNotificationOnError: true,
    onSuccess: (uploadedFile) => {
      if (uploadedFile) {
        setUploadedFile(uploadedFile.file)
      }
    },
  })

  const { mutate: completeOnBoarding, isPending } =
    useCompleteOnBoardingMutation(() => navigate(routes.CONGRATULATIONS.path))

  const handleFormSubmit = () => {
    if (!uploadedFile) return
    completeOnBoarding({
      file: {
        filename: uploadedFile.originalname,
        filePath: uploadedFile.path,
      },
    })
  }

  const handleFormSkip = () => {
    completeOnBoarding(undefined)
  }

  const clearFileUrl = () => {
    setUploadedFile(undefined)
  }

  return (
    <Flex
      direction="column"
      flex={1}
      w="100%"
      align="center"
      gap={40}
      justify="space-between"
    >
      <Stack flex={1} h="100%" maw={830} w="100%" gap={48} px={24}>
        <Stack>
          <Title fz="xxl" fw={600} c="black">
            &#128218; &nbsp;
            {t("onboarding.almostDoneTitle")}
          </Title>
          <Text c="gray.5" fz={16}>
            {t("onboarding.uploadDescription")}
          </Text>
        </Stack>
        <Stack gap={0}>
          <DashedDropzone
            uploadFile={uploadFile}
            uploadError={uploadError}
            hasUploadError={hasUploadError}
            isUploading={isUploading}
            clearFileUrl={clearFileUrl}
            title={t("onboarding.uploadDsnFile")}
            description={t("onboarding.uploadDsnFileDescription")}
          />
        </Stack>
      </Stack>
      <Footer
        isLoading={isPending}
        onContinue={handleFormSubmit}
        onSkipStep={handleFormSkip}
        disableContinue={!uploadedFile || isUploading || isUploading}
      />
    </Flex>
  )
}
