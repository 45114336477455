import { BarChart } from "@mantine/charts"
import _ from "lodash"
import { useMemo } from "react"

import { DimensionedDataPoint } from "@kiosk/front/api/dashboard/types"
import { colors } from "@kiosk/front/assets/theme/colors"

type Props = {
  dataPoint: DimensionedDataPoint
  projectedDimensionId: string
  stackedDimensionId?: string
}

const colorScale = colors.green.reduce<string[]>((acc, color, index) => {
  if (index % 3) acc.push(color)
  return acc
}, [])

type BarChartData = {
  xAxis: string
} & Record<string, number>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TextOverflowingTick = (props: any) => {
  const { x, y, payload } = props

  const label =
    payload.value.length > 20
      ? `${payload.value.slice(0, 20)}...`
      : payload.value
  return (
    <g transform={`translate(${x},${y})`} overflow="visible">
      <text x={0} y={0} dy={16} fill="#666" overflow="visible">
        <tspan textAnchor="middle" x="0" fontSize="12px" overflow="visible">
          {label}
        </tspan>
      </text>
    </g>
  )
}

export const DashboardBarChart = ({
  dataPoint,
  projectedDimensionId,
  stackedDimensionId,
}: Props) => {
  const barStacks = useMemo(() => {
    const valuesByProjectionDimensionOptions = _.groupBy(
      dataPoint.values,
      (value) => {
        return value.dimensions.find(
          (d) => d.dimensionId === projectedDimensionId,
        )?.optionLabel
      },
    )

    return _.map(valuesByProjectionDimensionOptions, (values, optionLabel) => {
      return values.reduce<BarChartData>(
        (acc, v) => {
          const stackLabel = stackedDimensionId
            ? v.dimensions.find((d) => d.dimensionId === stackedDimensionId)!
                .optionLabel
            : "value"

          acc[stackLabel] = v.value
          return acc
        },
        { xAxis: optionLabel } as BarChartData,
      )
    })
  }, [dataPoint.values, projectedDimensionId, stackedDimensionId])

  const stackSeries = useMemo(() => {
    if (!stackedDimensionId) return [{ name: "value", color: colorScale[0] }]

    return _.uniq(
      dataPoint.values
        .flatMap((v) => v.dimensions)
        .filter((d) => d.dimensionId === stackedDimensionId)
        .map((d) => d.optionLabel),
    ).map((d, index) => ({
      name: d,
      color: colorScale[index % colorScale.length],
    }))
  }, [dataPoint.values, stackedDimensionId])

  return (
    <BarChart
      h={300}
      data={barStacks}
      dataKey="xAxis"
      type="stacked"
      series={stackSeries}
      xAxisProps={{ tick: <TextOverflowingTick />, interval: 0 }}
    />
  )
}
