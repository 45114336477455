import { useQuery } from "@tanstack/react-query"

import { CompanyResponses } from "@kiosk/types/endpoints/companies"

import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const getCompanies =
  async (): Promise<CompanyResponses.GetCompanies> => {
    const response =
      await apiClient.get<CompanyResponses.GetCompanies>("/companies")
    return response.data
  }

export const useCompaniesQuery = () => {
  return useQuery({
    queryKey: companyKeys.all(),
    queryFn: getCompanies,
    refetchOnMount: true,
  })
}
