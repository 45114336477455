import { Modal, ModalBaseProps, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

export const BackToYouModal = (props: ModalBaseProps) => {
  const { t } = useTranslation("sources")

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={t("integrationRequestSent")}
      size="md"
      centered={true}
    >
      <Text mb={20}>{t("integrationRequestBackToYou")}</Text>
    </Modal>
  )
}
