import { Stack, Table } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { head, uniq } from "lodash"

import { CSRDQuestions, Content } from "@kiosk/types/data/dato"

import { isDefined } from "@kiosk/shared/utils/helpers"

import { CSRDQuestionContent } from "@kiosk/front/components/csrd/CSRDQuestionContent"
import { FormShape } from "@kiosk/front/components/csrd/types"
import { formatCsrdReportAnswerFormKey } from "@kiosk/front/components/csrd/utils"
import { EnrichedDimensionBreakdown } from "@kiosk/front/utils/dimensions"

interface RelatedQuestionsTableProps {
  title: string
  relatedQuestions: CSRDQuestions<Content>[]
  dimensionBreakdowns: EnrichedDimensionBreakdown[]
  form: UseFormReturnType<FormShape>
}

/**
 * Renders a tree of questions as a table.
 *
 * Current assumptions
 * -------------------
 * - only quantitative questions are in the related questions list
 * - there is only one axis (dimension) (that will change later)
 * - questions are not nested
 * - the person who chose the Table type for the main question knows these assumptions
 */
export const RelatedQuestionsTable = ({
  relatedQuestions,
  dimensionBreakdowns,
  form,
  title,
}: RelatedQuestionsTableProps) => {
  // We only support 1D for now.
  const dimensionBreakdown = dimensionBreakdowns.map(head).filter(isDefined)

  const dimensionLabel = dimensionBreakdown.find(
    (d) => d?.dimensionLabel,
  )!.dimensionLabel

  const columns = uniq(dimensionBreakdown.map((dbd) => dbd.optionLabel))

  return (
    <Stack p={24} gap={16}>
      <Table withColumnBorders withTableBorder pt={20}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th colSpan={columns.length}>{dimensionLabel}</Table.Th>
          </Table.Tr>
          <Table.Tr>
            <Table.Th>{title}</Table.Th>
            {columns.map((c) => (
              <Table.Th key={c}>{c}</Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {relatedQuestions.flatMap((q) => (
            <Table.Tr key={q.label}>
              <Table.Td>{q.label}</Table.Td>
              {dimensionBreakdown.map((dbd) => {
                const key = formatCsrdReportAnswerFormKey(q.id, [dbd])
                return (
                  <Table.Td key={key}>
                    <CSRDQuestionContent
                      datoQuestionId={q.id}
                      content={q.content}
                      breakdown={[dbd]}
                      formProps={form.getInputProps(key)}
                    />
                  </Table.Td>
                )
              })}
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  )
}
