import {
  Button,
  Group,
  NumberInput,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { IconCheck, IconExclamationCircle, IconFlag } from "@tabler/icons-react"
import dayjs from "dayjs"
import { keyBy, mapValues, omit } from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { CreateAssignmentBody } from "@kiosk/shared/schemas/assignments"

import { useAnswerAssignmentsMutation } from "@kiosk/front/api/surveys/assignments/answerAssignments"
import { Table } from "@kiosk/front/components/generic"
import { EditComment } from "@kiosk/front/pages/Surveys/AnswerSurvey/EditComment"
import { SurveyAnswered } from "@kiosk/front/pages/Surveys/AnswerSurvey/SurveyAnswered"
import { SurveyFileUploaded } from "@kiosk/front/pages/Surveys/AnswerSurvey/SurveyFileUploaded"
import { UploadAnswerFileButton } from "@kiosk/front/pages/Surveys/AnswerSurvey/UploadAnswerFileButton"
import { AssignmentDimensionsCell } from "@kiosk/front/pages/Surveys/EditQuestion/AssignmentDimensionsCell"

export const AnswerSurveyContent = ({
  survey,
  allDimensions,
}: {
  survey: AssignmentResponses.ContributorAssignments
  allDimensions: ConsolidatedDimension[]
}) => {
  const { t } = useTranslation("survey")
  const { mutateAsync: persistAnswers, isPending } =
    useAnswerAssignmentsMutation(survey.id)

  const dataSource = useMemo(
    () =>
      survey.surveyQuestions.flatMap((question) =>
        question.assignments.flatMap((assignment) => ({
          ...assignment,
          label: question.label,
        })),
      ),
    [survey],
  )

  const initialValues = useMemo(
    () => mapValues(keyBy(dataSource, "id"), (assignment) => assignment.value),
    [dataSource],
  )

  const validate = useMemo(() => {
    const validateValue = (value: number | null) =>
      value === null ? t("answerSurvey.missingAnswer") : null
    return Object.fromEntries(
      Object.keys(initialValues).map((k) => [k, validateValue]),
    )
  }, [t, initialValues])

  const form = useForm({
    mode: "uncontrolled",
    initialValues,
    validate,
  })

  const [comments, setComments] = useState<Record<string, string | null>>(
    mapValues(keyBy(dataSource, "id"), (assignment) => assignment.comment),
  )

  const [files, setFiles] = useState<
    Record<string, { name: string; path: string; signedUrl: string } | null>
  >(
    mapValues(keyBy(dataSource, "id"), (assignment) =>
      assignment.fileName && assignment.filePath && assignment.fileSignedUrl
        ? {
            name: assignment.fileName,
            path: assignment.filePath,
            signedUrl: assignment.fileSignedUrl,
          }
        : null,
    ),
  )

  const isSurveyAnswered = useMemo(
    () =>
      survey.surveyQuestions.every((surveyQuestion) =>
        surveyQuestion.assignments.every(
          (assignment) => assignment.value !== null,
        ),
      ),
    [survey],
  )

  const columns = useMemo(
    () => [
      { key: "label", colId: "label", title: t("answerSurvey.table.question") },
      {
        key: "breakdowns",
        colId: "assignmentDimensions",
        title: t("answerSurvey.table.dimensionBreakdowns"),
        render: (
          assignmentDimensions: CreateAssignmentBody["assignmentDimensions"],
        ) => (
          <AssignmentDimensionsCell
            assignmentDimensions={assignmentDimensions}
            allDimensions={allDimensions}
          />
        ),
      },
      {
        key: "answer",
        colId: "id",
        title: t("answerSurvey.table.answer"),
        render: (id: string) => (
          <Group>
            <NumberInput
              variant="unstyled"
              allowedDecimalSeparators={[",", "."]}
              hideControls
              placeholder={t("answerSurvey.answerPlaceholder")}
              {...omit(form.getInputProps(id), "error")}
            />
            {form.getInputProps(id).error && (
              <Tooltip label={t("answerSurvey.missingAnswer")}>
                <ThemeIcon c="red.8" size="sm">
                  <IconExclamationCircle />
                </ThemeIcon>
              </Tooltip>
            )}
          </Group>
        ),
      },
      {
        key: "comment",
        colId: "id",
        title: t("answerSurvey.table.comment"),
        render: (id: string) => (
          <EditComment
            comment={comments[id]}
            setComment={(comment: string | null) =>
              setComments((comments) => ({ ...comments, [id]: comment }))
            }
          />
        ),
      },
      {
        key: "file",
        colId: "id",
        title: t("answerSurvey.table.file"),
        width: 200,
        render: (id: string) => {
          const file = files[id]
          if (file)
            return (
              <SurveyFileUploaded
                file={file}
                onReset={() => setFiles((files) => ({ ...files, [id]: null }))}
              />
            )
          else
            return (
              <UploadAnswerFileButton
                onSuccess={(name, path, signedUrl) =>
                  setFiles((files) => ({
                    ...files,
                    [id]: { name, path, signedUrl },
                  }))
                }
              />
            )
        },
      },
    ],
    [t, allDimensions, form, comments, files],
  )

  if (isSurveyAnswered) return <SurveyAnswered />

  return (
    <form
      onSubmit={form.onSubmit(
        async (values: Record<string, number | "" | null>) =>
          persistAnswers(
            Object.entries(values).map(([id, value]) => {
              const file = files[id]
              return {
                id,
                value: value === "" ? null : value,
                comment: comments[id],
                fileName: file && file.name,
                filePath: file && file.path,
              }
            }),
          ),
      )}
    >
      <Stack gap={16}>
        <Group justify="space-between">
          <Text size="xl" fw={500}>
            {survey.title}
          </Text>
          <Group>
            <IconFlag color="#FE8F28" />
            <Text c="gray">{t("dueDate")}</Text>
            <Text>{dayjs(survey.dueDate).format("ll")}</Text>
          </Group>
        </Group>
        <Table
          dataSource={dataSource}
          columns={columns}
          withColumnBorders
          getRowKey={({ id }) => id}
          footer={<></>}
        />
        <Group>
          <Button leftSection={<IconCheck />} loading={isPending} type="submit">
            {t("answerSurvey.submit")}
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
