import { useQuery } from "@tanstack/react-query"

import { SurveyResponses } from "@kiosk/types/endpoints/surveys"

import { listSurveysSchema } from "@kiosk/shared/schemas/surveys"

import { apiClient } from "@kiosk/front/lib/apiClient"

import { surveysKeys } from "./surveysKeys"

export const useListSurveysQuery = () => {
  return useQuery({
    queryKey: surveysKeys.list(),
    queryFn: async (): Promise<SurveyResponses.Survey[]> => {
      const response = await apiClient.get<SurveyResponses.Survey[]>("/surveys")
      return listSurveysSchema.parseAsync(response.data)
    },
  })
}
