import { useUser } from "@clerk/clerk-react"
import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { accountKeys } from "@kiosk/front/api/account/accountKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const resetPassword = async (data: {
  password: string
}): Promise<{ message: string }> => {
  const response = await apiClient.put<{ message: string }>(
    "/users/reset-password",
    data,
  )
  return response.data
}

export const useResetPassword = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  return useMutation({
    mutationFn: (data: { password: string }) => resetPassword(data),
    onSuccess: (data: { message: string }) => {
      queryClient.invalidateQueries({
        queryKey: accountKeys.me(user?.id),
      })
      notifications.show({
        title: t("messages.success"),
        message: data.message,
        color: "green",
      })
    },
    onError: (error: Error) => {
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
