import { Modal, ModalBaseProps, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { useUploadDsnMutation } from "@kiosk/front/api/files/dsn"
import CustomSegmentedControl from "@kiosk/front/components/CustomSegmentedControl"
import { DashedDropzone } from "@kiosk/front/components/DashedDropzone"

interface DropFileModalProps extends ModalBaseProps {}

type SupportedFileType = "dsn" | "fec" | "custom"

interface Translations {
  tabLabel: string
  title: string
  description: string
}

export default function NewFileModal(props: DropFileModalProps) {
  const { t } = useTranslation("sources")

  const errorMessages: Record<number, string> = {
    409: t("error.dataPointConflict"),
    500: t("error.processingError"),
  }

  // this is very silly, but it all breaks down if we don’t statically
  // reference the translations
  const translations: Record<SupportedFileType, Translations> = {
    dsn: {
      tabLabel: t("newFile.dsn.tabLabel"),
      title: t("newFile.dsn.title"),
      description: t("newFile.dsn.description"),
    },
    fec: {
      tabLabel: t("newFile.fec.tabLabel"),
      title: t("newFile.fec.title"),
      description: t("newFile.fec.description"),
    },
    custom: {
      tabLabel: t("newFile.custom.tabLabel"),
      title: t("newFile.custom.title"),
      description: t("newFile.custom.description"),
    },
  }

  const [activeSegment, setActiveSegment] = useState<SupportedFileType>("dsn")
  const { mutate, error, isError, isPending } = useUploadDsnMutation()

  const uploadError = error !== null ? new Error(error.error) : null

  return (
    <Modal {...props} title={t("importFile.title")} size="lg">
      <Stack>
        <Text size="md">{t("importFile.description")}</Text>
        <CustomSegmentedControl
          onChange={(s) => setActiveSegment(s as SupportedFileType)}
          data={[
            {
              label: translations.dsn.tabLabel,
              value: "dsn",
            },
            {
              label: translations.fec.tabLabel,
              value: "fec",
            },
            {
              label: translations.custom.tabLabel,
              value: "custom",
            },
          ]}
        />
        <DashedDropzone
          title={translations[activeSegment].title}
          description={translations[activeSegment].description}
          uploadFile={(f) => (activeSegment === "dsn" ? mutate(f) : _.noop())}
          uploadError={uploadError}
          isUploading={isPending}
          hasUploadError={isError}
          clearFileUrl={_.noop}
          errorTitle={isError ? t("dsnImport.failTitle") : undefined}
          errorMessage={errorMessages[error?.status ?? 500]}
        />
      </Stack>
    </Modal>
  )
}
