import { Box, SimpleGrid } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  DashboardTopic,
  DimensionedDataPoint,
  SingleValueDataPoint,
} from "@kiosk/front/api/dashboard/types"
import { DashboardBigNumber } from "@kiosk/front/pages/Dashboard/DashboardBigNumber"
import { DashboardDimensionedDataPoint } from "@kiosk/front/pages/Dashboard/DashboardDimensionedDataPoint"
import { TopicTitle } from "@kiosk/front/pages/Dashboard/TopicTitle"

type DataPointBlock = DimensionedDataPoint | SingleValueDataPoint[]

const checkIsMultiBlock = (
  dataPointBlock: DataPointBlock,
): dataPointBlock is DimensionedDataPoint => {
  if (_.isArray(dataPointBlock)) return false
  return dataPointBlock.type === "dimensioned"
}

type Props = {
  topic: DashboardTopic
}

export const TopicDashboard = ({ topic }: Props) => {
  const { t } = useTranslation("dashboard")

  const dataPointsBlocks = useMemo<DataPointBlock[]>(() => {
    let tmpSingleDataPointsStack: SingleValueDataPoint[] = []
    const blocks = Object.values(topic.dataPoints).reduce<DataPointBlock[]>(
      (acc, dataPoint) => {
        if (dataPoint.type === "single-value") {
          tmpSingleDataPointsStack.push(dataPoint)
        } else if (dataPoint.values.length > 1) {
          acc.push(dataPoint)
        } else {
          // Specific case where only one breakdown has been filled.
          // In that case we consider the dimensioned value as a single value
          const singleDimensionValueDataPoint: SingleValueDataPoint = {
            type: "single-value",
            id: dataPoint.id,
            label: t("dimensionedDataPoint.label", {
              questionLabel: dataPoint.label,
              dimensionsLabel: dataPoint.values[0].dimensions
                .map((d) => `${d.optionLabel} (${d.dimensionLabel})`)
                .join(","),
            }),
            unit: dataPoint.unit,
            numberType: dataPoint.numberType,
            isMandatoryInDashboard: dataPoint.isMandatoryInDashboard,
            value: dataPoint.values[0].value,
          }
          tmpSingleDataPointsStack.push(singleDimensionValueDataPoint)
        }

        if (tmpSingleDataPointsStack.length === 6) {
          acc.push(tmpSingleDataPointsStack)
          tmpSingleDataPointsStack = []
        }

        return acc
      },
      [],
    )

    if (tmpSingleDataPointsStack.length > 0) {
      blocks.push(tmpSingleDataPointsStack)
    }

    return blocks
  }, [t, topic.dataPoints])

  return (
    <Box
      p={24}
      style={(theme) => ({
        borderRadius: 6,
        border: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <TopicTitle name={topic.name} />
      <SimpleGrid
        cols={{ base: 6, md: 12, lg: 24 }}
        spacing={48}
        verticalSpacing={24}
      >
        {dataPointsBlocks.map((dataPointBlock) => {
          if (checkIsMultiBlock(dataPointBlock)) {
            return (
              <Box
                key={dataPointBlock.label}
                style={{ gridColumnEnd: "span 8", gridRowEnd: "span 2" }}
              >
                <DashboardDimensionedDataPoint dataPoint={dataPointBlock} />
              </Box>
            )
          } else {
            return dataPointBlock.map((dataPoint) => (
              <Box key={dataPoint.label} style={{ gridColumnEnd: "span 8" }}>
                <DashboardBigNumber dataPoint={dataPoint} />
              </Box>
            ))
          }
        })}
      </SimpleGrid>
    </Box>
  )
}
