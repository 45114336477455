import {
  Button,
  Checkbox,
  FileInput,
  Group,
  NumberInput,
  Select,
  Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconDownload, IconPlus } from "@tabler/icons-react"
import { difference, max, noop, sortBy, union } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { Question } from "@kiosk/types/data/dato"

import { increment } from "@kiosk/shared/utils/helpers"

import { colors } from "@kiosk/front/assets/theme/colors"
import { Table } from "@kiosk/front/components/generic"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { AddQuestionsModal } from "@kiosk/front/components/questions/AddQuestionsModal"
import { usePosition } from "@kiosk/front/utils/usePosition"

import { RowAction } from "./RowAction"
import { TablePageDescription } from "./TablePageDescription"
import { TablePageNavigation } from "./TablePageNavigation"

interface TableRecord {
  id: number
  indicator?: string
  geoZone?: string
  organization?: string
  timeFrame?: string
  response?: number
  file?: string
}

const TIME_FRAMES = ["2024", "2025", "2026"].flatMap((y) =>
  ["Q1", "Q2"].map((q) => [q, y].join(" ")),
)

const GEO_ZONES = ["France", "Germany", "Italy", "Spain"]

const ORGANIZATIONS = ["Marketing", "HR", "Tech"]

const selectColors = {
  geoZone: colors.purple[0],
  organization: colors.orange[0],
  timeFrame: colors.gray[0],
}

const BASE_DATA: TableRecord[] = [
  {
    id: 1,
    indicator: "Number of formations",
    geoZone: "Montreal",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: undefined,
  },
  {
    id: 2,
    indicator: "Number of formations",
    geoZone: "France",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: "foo.csv",
  },
  {
    id: 3,
    indicator: "Number of formations",
    geoZone: "Germany",
    organization: "Marketing",
    timeFrame: "Q1 2024",
    response: 42,
    file: undefined,
  },
]

export const TablePage = () => {
  const { t } = useTranslation("tables")
  const location = useLocation()
  const baseData = location.state as TableRecord[] | undefined

  const [data, setData] = useState(baseData ?? BASE_DATA)
  const [tableRef, tablePosition] = usePosition<HTMLTableElement>()
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  useEffect(
    () => () => {
      setSelectedRows([])
    },
    [data],
  )

  const lastIndex = useMemo(() => max(data.map((d) => d.id)) ?? 0, [data])

  const addRecords = (rows: Array<Omit<TableRecord, "id">>) => {
    setData(
      sortBy(
        [
          ...data,
          ...rows.map((r, idx) => ({ ...r, id: increment(lastIndex + idx) })),
        ],
        (r) => r.id,
      ),
    )
  }

  const updateRecord = (record: TableRecord) =>
    setData(
      sortBy([...data.filter((r) => r.id !== record.id), record], (r) => r.id),
    )

  const columns = [
    {
      key: "indicator",
      colId: "indicator",
      title: t("columns.indicator"),
      width: 250,
      render: (v: string, record: TableRecord) => (
        <Group wrap="nowrap">
          <Checkbox
            checked={selectedRows.includes(record.id)}
            onChange={() =>
              setSelectedRows(
                selectedRows.includes(record.id)
                  ? difference(selectedRows, [record.id])
                  : union(selectedRows, [record.id]),
              )
            }
          />
          <Text lineClamp={2}>{v}</Text>
        </Group>
      ),
    },
    {
      key: "geoZone",
      colId: "geoZone",
      title: t("columns.geoZone"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          variant="unstyled"
          style={{ borderRadius: 6 }}
          bg={selectColors.geoZone}
          pl={8}
          placeholder={t("placeholder.select")}
          data={GEO_ZONES}
          value={v}
          onChange={(v) => updateRecord({ ...record, geoZone: v ?? undefined })}
        />
      ),
    },
    {
      key: "organization",
      colId: "organization",
      title: t("columns.organization"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          variant="unstyled"
          style={{ borderRadius: 6 }}
          bg={selectColors.organization}
          pl={8}
          placeholder={t("placeholder.select")}
          data={ORGANIZATIONS}
          value={v}
          onChange={(v) =>
            updateRecord({ ...record, organization: v ?? undefined })
          }
        />
      ),
    },
    {
      key: "timeFrame",
      colId: "timeFrame",
      title: t("columns.timeFrame"),
      width: 200,
      render: (v: string, record: TableRecord) => (
        <Select
          variant="unstyled"
          style={{ borderRadius: 6 }}
          bg={selectColors.timeFrame}
          pl={8}
          placeholder={t("placeholder.select")}
          data={TIME_FRAMES}
          value={v}
          onChange={(v) =>
            updateRecord({ ...record, timeFrame: v ?? undefined })
          }
        />
      ),
    },
    {
      key: "response",
      colId: "response",
      title: t("columns.response"),
      width: 200,
      render: (v: string) => (
        <NumberInput
          variant="unstyled"
          hideControls={true}
          value={v}
          placeholder={t("placeholder.addResponse")}
        />
      ),
    },
    {
      key: "file",
      colId: "file",
      title: t("columns.file"),
      width: 200,
      render: (v: string, record: TableRecord) =>
        v ? (
          <Group gap={8} wrap="nowrap">
            <IconDownload color={colors.gray[6]} size={20} />
            <Text c={colors.gray[8]}>{v}</Text>
          </Group>
        ) : (
          <FileInput
            maw={150}
            placeholder={t("placeholder.chooseFile")}
            multiple={false}
            onChange={(v) => updateRecord({ ...record, file: v!.name })}
          />
        ),
    },
  ]

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <PageLayout>
      <TablePageNavigation />
      <TablePageDescription />
      <Table
        dataSource={data}
        getRowKey={(r) => r.id}
        columns={columns}
        withColumnBorders={true}
        verticalSpacing={12}
        ref={tableRef}
      />
      <RowAction
        selectedIds={selectedRows}
        deleteRecords={(ids: number[]) => {
          setData(data.filter((d) => !ids.includes(d.id)))
        }}
        duplicateRecords={(ids: number[]) => {
          const newRecords = data.filter((d) => ids.includes(d.id))
          addRecords(newRecords)
        }}
        // FIXME
        isPending={false}
        position={{
          left: tablePosition.x,
          top: tablePosition.y,
        }}
      />

      <AddQuestionsModal
        opened={opened}
        onClose={close}
        onSave={(questions: Question[]) => {
          const records = questions.map((q) => ({
            indicator: q.label,
          }))
          addRecords(records)
        }}
      />

      <Button variant="transparent" c="black" onClick={open}>
        <IconPlus color={colors.emerald[5]} />
        <Text ml={6} c={colors.gray[5]}>
          {t("actions.newRow")}
        </Text>
      </Button>
      <Group justify="right">
        <Button mt={20} type="submit" pl={40} pr={40} onClick={noop}>
          {t("placeholder.save")}
        </Button>
      </Group>
    </PageLayout>
  )
}
