import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { OnboardingRequests } from "@kiosk/types/endpoints/onboarding"

import { apiClient } from "@kiosk/front/lib/apiClient"

export const useCreateGeoDimensionMutation = (onSuccess: () => void) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (data: OnboardingRequests.GeoDimension[]) => {
      const response = await apiClient.post("/onboarding/geo-dimensions", data)
      return response.data
    },
    onSuccess,
    onError: (error: Error) => {
      //TODO: Handle errors
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
