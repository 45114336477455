import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { DsnResponses } from "@kiosk/types/endpoints/dsn"

import { apiClient } from "@kiosk/front/lib/apiClient"

export function useUploadDsnMutation() {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (file: File) => {
      const form = new FormData()
      form.append("file", file)

      const response = await apiClient.post<DsnResponses.ImportDsnOk>(
        "/dsn/import",
        form,
      )
      return response.data.dataPoints
    },
    onError: (err: { status: number; error: string }) => {
      let message: string
      if (err.status === 409) {
        message = t("upload.errors.data_points_conflict")
      } else {
        message = t("upload.error")
      }
      showNotification({ color: "red", message })
      return { message }
    },
  })
}
