import { Dialog, Group, Button, Text } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconTrash } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

export const DeleteQuestionsDialog = ({
  selectedIds,
  deleteSurveyQuestion,
  isPending,
  position,
}: {
  selectedIds: string[]
  deleteSurveyQuestion: (id: string) => void
  isPending: boolean
  position: { left: number; top: number }
}) => {
  const { t } = useTranslation("survey")
  return (
    <Dialog position={position} opened={selectedIds.length > 0}>
      <Group gap={0}>
        <Group px={12} py={8}>
          <Text c="green" fw={600}>
            {t("nbSelected", { count: selectedIds.length })}
          </Text>
        </Group>
        <Group
          px={12}
          py={8}
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button
            onClick={async () => {
              await Promise.all(selectedIds.map(deleteSurveyQuestion))
              showNotification({
                color: "green",
                message: t("surveyQuestionsDeleted", {
                  count: selectedIds.length,
                }),
              })
            }}
            leftSection={<IconTrash />}
            variant="transparent"
            c="gray"
            size="compact-md"
            loading={isPending}
          >
            Delete
          </Button>
        </Group>
      </Group>
    </Dialog>
  )
}
