import { Badge, Group, rem, Stack, Text, ThemeIcon } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { BackButton } from "@kiosk/front/components/BackButton"
import { routes } from "@kiosk/front/utils/constants/index"

import { Completion } from "../../components/Completion"

type Props = {
  topic: ReportsResponses.GetCategories[number]["topics"][number]
  activeSubTopicId: string
}

export interface DisclosureRequirementInSidebarProps {
  id: string
  activeSubTopicId: string
  name: string
  esrsCode: string
  progress: number
  onClick: () => void
}

const DisclosureRequirementInSidebar = (
  props: DisclosureRequirementInSidebarProps,
) => {
  return (
    <Group
      p={16}
      gap={8}
      style={{
        cursor: "pointer",
        borderBottomStyle: "solid",
        borderBottomWidth: rem(1),
        borderBottomColor: "var(--mantine-color-gray-2)",
      }}
      bg={props.activeSubTopicId === props.id ? "green.1" : undefined}
      onClick={props.onClick}
    >
      <Completion progress={props.progress} />
      <Badge
        h={26}
        fz="xxs"
        px={11}
        style={{
          borderColor: "var(--mantine-color-gray-2)",
          borderWidth: rem(1),
        }}
        color="white"
        c="gray.5"
        fw={400}
        fs="italic"
      >
        {props.esrsCode}
      </Badge>
      <Text flex={1} fz="sm" fw={500} c="gray.7">
        {props.name}
      </Text>
      <ThemeIcon c="gray.7" w={22} h={22}>
        <IconChevronRight />
      </ThemeIcon>
    </Group>
  )
}

export const DisclosureRequirementSideBar = ({
  topic,
  activeSubTopicId,
}: Props) => {
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(routes.CSRD.path.replace(":datoTopicId", topic.id))
  }

  const handleChangeSubTopic = (subTopicId: string) => {
    navigate(
      routes.CSRD_DISCLOSURE_REQUIREMENT.path
        .replace(":datoTopicId", topic.id)
        .replace(":datoSubTopicId", subTopicId),
    )
  }

  return (
    <>
      <Group
        p={8}
        gap={8}
        top={0}
        bg="gray.0"
        pos="sticky"
        style={{ zIndex: 1 }}
        mr={10}
      >
        <BackButton onClick={() => handleBack()} />
        <Badge
          h={26}
          fz="xxs"
          px={11}
          style={{
            borderColor: "var(--mantine-color-gray-2)",
            borderWidth: rem(1),
          }}
          color="white"
          c="gray.5"
          fw={400}
          fs="italic"
        >
          {topic.code}
        </Badge>
        <Text fz="md" c="gray.9" fw={600}>
          {topic.name}
        </Text>
      </Group>
      <Stack gap={0}>
        {topic.disclosureRequirements.map(
          ({ id, name, esrsCode, progress }) => {
            return (
              <DisclosureRequirementInSidebar
                key={id}
                id={id}
                activeSubTopicId={activeSubTopicId}
                progress={progress}
                name={name}
                esrsCode={esrsCode}
                onClick={() => handleChangeSubTopic(id)}
              />
            )
          },
        )}
      </Stack>
    </>
  )
}
