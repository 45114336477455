import { Button, Group, Modal, rem, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronLeft } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { useImpersonateUser } from "@kiosk/front/api/account/impersonateUser"
import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { useCompanyUsersQuery } from "@kiosk/front/api/companies/queryCompanyUsers"
import { useDeleteUserMutation } from "@kiosk/front/api/users/deleteUser"
import { Table } from "@kiosk/front/components/generic"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { queryClient } from "@kiosk/front/lib/queryClient"
import { useUsersColumns } from "@kiosk/front/pages/Users/columns"
import { routes } from "@kiosk/front/utils/constants"

export const Users = () => {
  const { t } = useTranslation("common")
  const params = useParams()
  const navigate = useNavigate()
  const { id = "" } = params
  const [opened, { open, close }] = useDisclosure(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const companyUsersQuery = useCompanyUsersQuery(id)

  const handleBack = () => navigate(routes.COMPANIES.path)

  const onSuccess = async () =>
    await queryClient.invalidateQueries({
      queryKey: companyKeys.companyUsers(id),
    })

  const { mutateAsync: deleteAccount } = useDeleteUserMutation(onSuccess)

  const handleDeleteAccount = (id: string | null) => () => {
    if (id) {
      void deleteAccount(id)
    }
    setSelectedUserId(null)
    close()
  }

  const showDeleteModal = (id: string) => {
    setSelectedUserId(id)
    open()
  }

  const { mutate: impersonateUserMutation } = useImpersonateUser()

  const columns = useUsersColumns(showDeleteModal, impersonateUserMutation)

  return (
    <PageLayout>
      <Stack gap={30} align="flex-start">
        <Button p={0} variant="transparent" c="black" onClick={handleBack}>
          <Group gap={12}>
            <IconChevronLeft />
            {t("buttons.back")}
          </Group>
        </Button>
        <QueryWrapper query={companyUsersQuery}>
          {({ data: companyUsers }) => (
            <Table columns={columns} dataSource={companyUsers.data} />
          )}
        </QueryWrapper>
        <Modal.Root centered opened={opened} onClose={close}>
          <Modal.Overlay />
          <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
            <Modal.Header>
              <Modal.Title c="gray.9" fz="xxl" fw={600}>
                {t("settings:account.deleteAccount")}
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <Text fz="md" c="gray.7" mb={34}>
                {t("settings:account.deleteAccountDescription")}
              </Text>
              <Group justify="flex-end">
                <Button color="gray.2" c="gray.9" onClick={close}>
                  {t("common:buttons.cancel")}
                </Button>
                <Button
                  color="red.8"
                  onClick={handleDeleteAccount(selectedUserId)}
                >
                  {t("settings:account.deleteAccount")}
                </Button>
              </Group>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </Stack>
    </PageLayout>
  )
}
