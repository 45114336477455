import { Prisma } from "@kiosk/types/prisma-client/index"

export type User = Prisma.UserGetPayload<{ include: { company: true } }>

export const USER_ROLES = {
  KIOSK_ADMIN: "KIOSK_ADMIN",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  COMPANY_CONTRIBUTOR: "COMPANY_CONTRIBUTOR",
} as const

export type UserRole = keyof typeof USER_ROLES
