import { useQuery } from "@tanstack/react-query"

import { User } from "@kiosk/types/prisma-client"

import { apiClient } from "@kiosk/front/lib/apiClient"

import { usersKeys } from "./usersKeys"

export const getUser = async (userId?: string): Promise<User | null> => {
  const response = await apiClient.get<User | null>(`/users/${userId}`)
  return response.data
}

export const useUserQuery = (userId?: string) => {
  return useQuery({
    queryKey: usersKeys.get(userId as string),
    queryFn: () => getUser(userId),
    enabled: !!userId,
  })
}
