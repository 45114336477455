import { z } from "zod"
import { TypedDimensionOption } from "@kiosk/types/prisma-client/index"

const newDimensionOptionSchema = z.object({
  label: z.string().min(1),
  isDeleted: z.literal(false),
  isNew: z.literal(true),
})

const updatedDimensionOptionSchema = z.object({
  id: z.string(),
  label: z.string().min(1),
  isDeleted: z.boolean(),
  isNew: z.literal(false),
})

export type NewDimensionOption = z.infer<typeof newDimensionOptionSchema>
export type UpdatedDimensionOption = z.infer<
  typeof updatedDimensionOptionSchema
>

export const dimensionOptionsSchema = z.object({
  dimensions: z.array(
    z.discriminatedUnion("isNew", [
      newDimensionOptionSchema,
      updatedDimensionOptionSchema,
    ]),
  ),
})

export type EditDimensionForm = z.infer<typeof dimensionOptionsSchema>

export const editDimensionSchema = dimensionOptionsSchema.extend({
  datoDimensionId: z.string(),
})

export namespace DimensionRequests {
  export type EditDimension = z.infer<typeof editDimensionSchema>
}

export namespace DimensionResponses {
  export type TypedDimensions = Record<string, TypedDimensionOption[]>
}
