import { NumberInput } from "@mantine/core"

import { NumberRecord } from "@kiosk/types/data/dato"

import { InputProps } from "./types"

export const NumberQuestion = ({
  content,
  formProps,
}: InputProps<NumberRecord>) => {
  const suffix = content.numberType === "currency" ? "€" : " " + content.unit

  return (
    <NumberInput
      maw="250"
      hideControls={true}
      min={0}
      suffix={suffix}
      {...formProps}
      onChange={(value) => formProps.onChange(+value)}
    />
  )
}
