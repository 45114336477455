import { Button, Dialog, Group, Text } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconCopy, IconTrash } from "@tabler/icons-react"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

interface RowActionProps {
  selectedIds: number[]
  deleteRecords: (id: number[]) => Promise<void> | void
  duplicateRecords: (id: number[]) => Promise<void> | void
  isPending: boolean
  position: {
    left: number
    top: number
  }
}

export const RowAction = ({
  selectedIds,
  deleteRecords,
  duplicateRecords,
  isPending,
  position,
}: RowActionProps) => {
  const { t } = useTranslation("tables")

  return (
    <Dialog position={position} opened={!isEmpty(selectedIds)}>
      <Group gap={0}>
        <Group px={12} py={8}>
          <Text c="green" fw={600}>
            {t("nbSelected", { count: selectedIds.length })}
          </Text>
        </Group>
        <Group
          px={12}
          py={8}
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button
            onClick={async () => {
              await deleteRecords(selectedIds)
              showNotification({
                color: "green",
                message: t("recordsDeleted", {
                  count: selectedIds.length,
                }),
              })
            }}
            leftSection={<IconTrash />}
            variant="transparent"
            c="gray"
            size="compact-md"
            loading={isPending}
          >
            {t("actions.delete")}
          </Button>
        </Group>
        <Group
          px={12}
          py={8}
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
        >
          <Button
            onClick={async () => {
              await duplicateRecords(selectedIds)
              showNotification({
                color: "green",
                message: t("recordsDuplicated", {
                  count: selectedIds.length,
                }),
              })
            }}
            leftSection={<IconCopy />}
            variant="transparent"
            c="gray"
            size="compact-md"
            loading={isPending}
          >
            {t("actions.duplicate")}
          </Button>
        </Group>
      </Group>
    </Dialog>
  )
}
