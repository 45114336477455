import { enUS, frFR } from "@clerk/localizations"
import type { LocalizationResource } from "@clerk/types"

import { i18n, Locale } from "@kiosk/i18n"

import { config } from "@kiosk/front/config"

const company: Record<"kiosk" | "greenster", string> = {
  kiosk: "Kiosk",
  greenster: "Greenster",
}

export const CLERK_LANGUAGES: Record<Locale, LocalizationResource> = {
  fr: {
    ...frFR,
    footerPageLink__privacy: "Politique de confidentialité",
    footerPageLink__terms: "Mentions légales",
    signIn: {
      start: {
        subtitle: undefined,
        title: i18n.t("clerk.signInTitle", {
          company: company[config.logo],
        }),
      },
    },
  },
  en: {
    ...enUS,
    signIn: {
      start: {
        subtitle: undefined,
        title: i18n.t("clerk.signInTitle", {
          company: company[config.logo],
        }),
      },
    },
  },
}

export const roles = {
  KIOSK_ADMIN: i18n.t("roles.kioskAdmin"),
  COMPANY_ADMIN: i18n.t("roles.companyAdmin"),
  COMPANY_CONTRIBUTOR: i18n.t("roles.companyContributor"),
  COMPANY_VIEWER: i18n.t("roles.companyViewer"),
  COMPANY_AUDITOR: i18n.t("roles.companyAuditor"),
  COMPANY_VERIFIER: i18n.t("roles.companyVerifier"),
}

export const companyStep = {
  ACCOUNT_SETUP: 0,
  GEO_STRUCTURE: 1,
  FIRST_UPLOAD: 2,
  ONBOARDED: 3,
}
