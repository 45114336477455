import { Group, Stack } from "@mantine/core"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { DimensionCategory } from "@kiosk/front/api/datocms/types"
import { useListTypedDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { TypedDimension } from "@kiosk/front/api/dimensions/types"
import { Callout } from "@kiosk/front/components/Callout"
import { FilterButton } from "@kiosk/front/components/FilterButton"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { DimensionsTable } from "@kiosk/front/pages/Dimensions/DimensionsTable"

type Props = {
  dimensions: TypedDimension[]
}

const DimensionsList = ({ dimensions }: Props) => {
  const { t } = useTranslation("dimensions")

  const [selectedCategory, setSelectedCategory] = useState<DimensionCategory>()

  const dimensionsByCategory = useMemo(
    () => _.groupBy(dimensions, (d) => d.category),
    [dimensions],
  )

  const categoryTranslations: Record<DimensionCategory, string> = {
    csrd: t("categories.csrd"),
    company: t("categories.company"),
  }

  return (
    <Stack gap={32}>
      <Callout message={t("callout")} />
      <Group gap={8}>
        <FilterButton
          label={t("filters.all")}
          isSelected={!selectedCategory}
          onClick={() => setSelectedCategory(undefined)}
        />
        {Object.keys(dimensionsByCategory).map((category) => (
          <FilterButton
            key={category}
            label={categoryTranslations[category as DimensionCategory]}
            isSelected={selectedCategory === category}
            onClick={() => setSelectedCategory(category as DimensionCategory)}
          />
        ))}
      </Group>
      {selectedCategory ? (
        <DimensionsTable
          category={selectedCategory}
          dimensions={dimensionsByCategory[selectedCategory]}
        />
      ) : (
        _.map(dimensionsByCategory, (categoryDimensions, category) => (
          <DimensionsTable
            key={category}
            category={category as DimensionCategory}
            dimensions={categoryDimensions}
          />
        ))
      )}
    </Stack>
  )
}

export const Dimensions = () => {
  const typedDimensionsQuery = useListTypedDimensionsQuery()
  return (
    <PageLayout contained>
      <QueryWrapper fluid query={typedDimensionsQuery}>
        {({ data }) => <DimensionsList dimensions={data} />}
      </QueryWrapper>
    </PageLayout>
  )
}
