import {
  AppShell,
  Divider,
  Group,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { IconSettings } from "@tabler/icons-react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import {
  composeSettingsTabRoute,
  routes,
  routesMap,
  SettingsTab,
} from "@kiosk/front/utils/constants"

import { UserPopover } from "./UserPopover"

export const AppHeader = () => {
  const location = useLocation()
  const { tab } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const component = useMemo(() => {
    const currentRoute = Object.keys(routesMap).find((path) =>
      matchPath(path, location.pathname),
    )
    return currentRoute ? routesMap[currentRoute] : ""
  }, [location.pathname])

  const isSettingsActive = useMemo(
    () => !!matchPath(routes.SETTINGS.path, location.pathname),
    [location.pathname],
  )

  const isActive = (value: SettingsTab) => value === tab

  return (
    <AppShell.Header>
      <Stack w="100%" px={24} gap={0}>
        <Group justify="space-between" w="100%" h={74} py={16}>
          <Text fw={600} c="gray.9" fz="xl">
            {component}
          </Text>
          <Group gap={16}>
            <Link
              style={{ height: 28, width: 28 }}
              to={composeSettingsTabRoute("account")}
            >
              <ThemeIcon
                color={isSettingsActive ? "gray.7" : "gray.5"}
                style={{ background: "transparent" }}
                variant="white"
              >
                <IconSettings />
              </ThemeIcon>
            </Link>
            <Divider
              style={{ alignSelf: "unset", height: 24 }}
              c="gray.5"
              orientation="vertical"
            />
            <UserPopover />
          </Group>
        </Group>
        {isSettingsActive ? (
          <Tabs
            value={tab || "account"}
            style={{ "--tab-border-color": "transparent" }}
            onChange={(value) =>
              navigate(composeSettingsTabRoute(value as SettingsTab))
            }
          >
            <Tabs.List defaultValue="account" bottom="transparent">
              <Tabs.Tab
                c={isActive("account") ? "green.6" : "gray.7"}
                h={46}
                bg="transparent"
                py={10}
                px={12}
                value="account"
                fw={isActive("account") ? 600 : 400}
              >
                {t("routes.account")}
              </Tabs.Tab>
              <Tabs.Tab
                c={isActive("users") ? "green.6" : "gray.7"}
                fw={isActive("users") ? 600 : 400}
                bg="transparent"
                h={46}
                py={10}
                px={12}
                value="users"
              >
                {t("routes.users")}
              </Tabs.Tab>
              {/*TODO: when user roles are implemented, add appropriate condition for rendering below tabs*/}
              <Tabs.Tab
                c={isActive("language") ? "green.6" : "gray.7"}
                fw={isActive("language") ? 600 : 400}
                bg="transparent"
                h={46}
                py={10}
                px={12}
                value="language"
              >
                {t("routes.language")}
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
        ) : null}
      </Stack>
    </AppShell.Header>
  )
}
