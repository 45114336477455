import { Stack } from "@mantine/core"

import { DashboardCategory } from "@kiosk/front/api/dashboard/types"
import { CategoryTitle } from "@kiosk/front/pages/Dashboard/CategoryTitle"
import { TopicDashboard } from "@kiosk/front/pages/Dashboard/TopicDashboard"

type Props = {
  category: DashboardCategory
}

export const CategoryDashboard = ({ category }: Props) => {
  return (
    <Stack gap={16}>
      <CategoryTitle category={category.name} color={category.baseColor} />
      <Stack gap={40}>
        {category.topics.map((topic) => (
          <TopicDashboard key={topic.id} topic={topic} />
        ))}
      </Stack>
    </Stack>
  )
}
