import { IconCalendar } from "@tabler/icons-react"
import dayjs from "dayjs"

import { DateRecord } from "@kiosk/types/data/dato"

import { DateInput } from "@kiosk/front/components/DateInput"

import { InputProps } from "./types"

export const DateQuestion = ({ formProps }: InputProps<DateRecord>) => {
  const { value, ...restProps } = formProps
  const minimumDate = dayjs("2020-01-01").toDate()
  const firstDayOfPreviousYear = dayjs()
    .subtract(1, "year")
    .startOf("year")
    .toDate()
  const lastDayOfPreviousYear = dayjs()
    .subtract(1, "year")
    .endOf("year")
    .toDate()

  return (
    <DateInput
      icon={IconCalendar}
      minDate={minimumDate}
      maxDate={lastDayOfPreviousYear}
      defaultDate={firstDayOfPreviousYear}
      value={value ? new Date(value) : undefined}
      {...restProps}
    />
  )
}
