import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { CategoryResponses } from "@kiosk/types/endpoints/categories"
import { TaskResponses } from "@kiosk/types/endpoints/tasks"

import { apiClient } from "@kiosk/front/lib/apiClient"

import { taskKeys } from "./taskKeys"

export const getTasksByCategory = async (
  params?: TaskResponses.TaskParams,
): Promise<CategoryResponses.GetCategoriesAndTasks> => {
  const response = await apiClient.get<CategoryResponses.GetCategoriesAndTasks>(
    "/categories",
    { params },
  )
  return response.data
}

export const useTasksQuery = (params?: TaskResponses.TaskParams) => {
  const queryKey = params?.ownerId ? taskKeys.all(params) : taskKeys.all()
  return useQuery({
    queryKey,
    queryFn: async () => {
      const categories = await getTasksByCategory(params)
      return {
        ...categories,
        data: categories.data.map((c) => ({
          ...c,
          tasks: _.sortBy(c.tasks, ["status", "dueDate"]),
        })),
      }
    },
  })
}
