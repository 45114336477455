import { TextInput, Flex, Button, Space } from "@mantine/core"
import { useDebouncedState } from "@mantine/hooks"
import { IconSearch, IconPlus } from "@tabler/icons-react"
import dayjs from "dayjs"
import { SetStateAction, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { i18n } from "@kiosk/i18n"

import { CreateSurveyBody } from "@kiosk/shared/schemas/surveys"

import { useCreateSurveyMutation } from "@kiosk/front/api/surveys/createSurvey"
import { useListSurveysQuery } from "@kiosk/front/api/surveys/listSurveys"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { EmptySurvey } from "@kiosk/front/pages/Surveys/EmptySurvey"
import { routes } from "@kiosk/front/utils/constants"

import { SurveysList } from "./SurveysList"

const getDefaultSurvey = (): CreateSurveyBody => {
  const tomorrow = dayjs(new Date()).add(1, "day").toDate()
  tomorrow.setUTCHours(0, 0, 0, 0)

  return {
    title: i18n.t("survey:placeholders.surveyTitle"),
    launchDate: tomorrow,
    followUpDate: dayjs(tomorrow).add(1, "day").toDate(),
    dueDate: dayjs(tomorrow).add(2, "days").toDate(),
  }
}

const ActionsRow = ({
  setSearch,
}: {
  setSearch: (newValue: SetStateAction<string>) => void
}) => {
  const { t } = useTranslation("survey")

  const navigate = useNavigate()

  const { mutateAsync: createSurvey, isPending } = useCreateSurveyMutation()

  const onNewSurvey = useCallback(async () => {
    const survey = await createSurvey(getDefaultSurvey())
    navigate(routes.EDIT_SURVEY.path.replace(":id", survey.id))
  }, [navigate, createSurvey])

  return (
    <Flex justify="space-between">
      <TextInput
        placeholder={t("placeholders.findSurvey")}
        leftSection={<IconSearch size={20} />}
        disabled={isPending}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button
        loading={isPending}
        leftSection={<IconPlus size={20} />}
        onClick={onNewSurvey}
      >
        {t("actions.newSurvey")}
      </Button>
    </Flex>
  )
}

export const Surveys = () => {
  const [search, setSearch] = useDebouncedState("", 500)
  const listSurveysQuery = useListSurveysQuery()

  return (
    <PageLayout contained>
      <QueryWrapper
        fluid
        emptyPlaceholder={EmptySurvey}
        query={listSurveysQuery}
      >
        {({ data: surveys }) => (
          <>
            <ActionsRow setSearch={setSearch} />
            <Space h="xl" />
            <SurveysList surveys={surveys} search={search} />
          </>
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
