import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { TaskResponses } from "@kiosk/types/endpoints/tasks"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { taskKeys } from "./taskKeys"

export const updateTask = async ({
  id,
  data,
}: TaskResponses.UpdateTaskPayload) => {
  const response = await apiClient.put(`/tasks/${id}`, data)
  return response.data
}

export const useUpdateTaskMutation = () => {
  const { t } = useTranslation(["task", "common"])

  return useMutation({
    mutationFn: updateTask,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: taskKeys.all() })
      await queryClient.invalidateQueries({ queryKey: taskKeys.id(data.id) })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      notifications.show({
        title: t("common:messages.success"),
        message: t("taskUpdateSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
