import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"

import { SourcesResponses } from "@kiosk/types/endpoints/sources"
import { SOURCE_TYPES } from "@kiosk/types/sources"

import { sourcesKeys } from "@kiosk/front/api/sources/keys"
import { config } from "@kiosk/front/config"
import { apiClient } from "@kiosk/front/lib/apiClient"

const FAKE_DATA = [
  {
    type: SOURCE_TYPES.TABLE,
    name: "Liste d’employés",
    lastUpdate: dayjs("2024-04-20"),
    link: "/sources",
  },
  {
    type: SOURCE_TYPES.SURVEY,
    name: "Trajets domicile - travail",
    lastUpdate: dayjs("2024-04-12"),
    link: "/sources",
  },
  {
    type: SOURCE_TYPES.FILE,
    name: "Heures de formation suivies",
    lastUpdate: dayjs("2024-04-10"),
    link: "/sources",
  },
  {
    type: SOURCE_TYPES.TABLE,
    name: "Scope 3 Bilan Carbone",
    lastUpdate: dayjs("2023-12-20"),
    link: "/sources",
  },
  {
    type: SOURCE_TYPES.INTEGRATION,
    name: "Consommation d’eau",
    lastUpdate: dayjs("2023-11-11"),
    link: "/sources",
  },
]

export const useListSourcesQuery = () => {
  return useQuery<SourcesResponses.Source[]>({
    queryKey: sourcesKeys.list(),
    queryFn: async () => {
      if (config.isDemo) return FAKE_DATA
      const response = await apiClient.get("/sources")
      return response.data
    },
  })
}
