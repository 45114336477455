import { Stack, Transition } from "@mantine/core"

interface FadeProps {
  children: React.ReactNode
  loading: boolean
  onExit?: () => void
  position?: "absolute" | "relative" | "fixed"
}

export const Fade = ({
  children,
  loading,
  onExit,
  position = "relative",
}: FadeProps) => (
  <Transition
    mounted={loading}
    transition="fade"
    duration={300}
    timingFunction="ease"
    onExited={onExit}
  >
    {(styles) => {
      return <Stack style={{ ...styles, position }}>{children}</Stack>
    }}
  </Transition>
)
