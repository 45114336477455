import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { TaskCategory } from "@kiosk/types/prisma-client"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { taskKeys } from "./taskKeys"

export const createCategory = async (): Promise<TaskCategory> => {
  const response = await apiClient.post<TaskCategory>("/categories")
  return response.data
}

export const useCreateCategoryMutation = () => {
  const { t } = useTranslation(["common", "taskCategory"])

  return useMutation({
    mutationFn: createCategory,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      notifications.show({
        title: t("common:messages.success"),
        message: t("taskCategory:success.createCategorySuccess"),
        color: "green",
      })
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      notifications.show({
        title: t("common:messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
