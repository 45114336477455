import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"

import { assignmentsKeys } from "@kiosk/front/api/surveys/assignments/assignmentsKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const useAnswerAssignmentsMutation = (surveyId: string) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (
      answers: {
        id: string
        value: number | null
        comment: string | null
        fileName: string | null
        filePath: string | null
      }[],
    ): Promise<AssignmentResponses.Assignment[]> => {
      const responses = await Promise.all(
        answers.map(({ id, value, comment, fileName, filePath }) =>
          apiClient.put(`/assignments/${id}/answer`, {
            value,
            comment,
            fileName,
            filePath,
          }),
        ),
      )
      return responses.map((response) => response.data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: assignmentsKeys.getContributorAssignments(surveyId),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
