import { SignIn } from "@clerk/clerk-react"
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import { AuthGuard } from "@kiosk/front/components/auth/AuthGuard"
import { KioskGuard } from "@kiosk/front/components/auth/KioskGuard"
import { OnboardingGuard } from "@kiosk/front/components/auth/OnboardingGuard"
import { AppShell } from "@kiosk/front/components/layout/AppShell"
import { AuthLayout } from "@kiosk/front/components/layout/AuthLayout"
import { DisclosureRequirement } from "@kiosk/front/pages/CSRD/DisclosureRequirement"
import { Dimensions } from "@kiosk/front/pages/Dimensions/index"
import { routes } from "@kiosk/front/utils/constants"

import { CSRD } from "./CSRD"
import { Companies } from "./Companies"
import { Dashboard } from "./Dashboard"
import { Onboarding } from "./Onboarding"
import { Congratulations } from "./Onboarding/Congratulations"
import { Settings } from "./Settings"
import { Sources } from "./Sources"
import { TablePage } from "./Sources/Tables/TablePage"
import { Surveys } from "./Surveys"
import { AnswerSurvey } from "./Surveys/AnswerSurvey"
import { EditQuestion } from "./Surveys/EditQuestion"
import { EditSurvey } from "./Surveys/EditSurvey"
import { SurveyAnswers } from "./Surveys/SurveyAnswers"
import { Tasks } from "./Tasks"
import { Users } from "./Users"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthLayout />}>
        <Route
          path="/sign-in/*"
          element={
            <SignIn
              routing="path"
              path="/sign-in"
              // Hide the sign-up button on login form
              appearance={{ elements: { footerAction: { display: "none" } } }}
            />
          }
        />
      </Route>
      <Route element={<AuthGuard />}>
        <Route
          element={<Congratulations />}
          path={routes.CONGRATULATIONS.path}
        />
        <Route element={<AppShell />}>
          <Route index element={<Dashboard />} />
          <Route path={routes.TASKS.path} element={<Tasks />} />
          <Route path={routes.DIMENSIONS.path} element={<Dimensions />} />
          <Route path={routes.SOURCES.path} element={<Sources />} />
          <Route path={routes.TABLES.path} element={<TablePage />} />
          <Route path={routes.SETTINGS.path} element={<Settings />} />
          <Route path={routes.SURVEYS.path} element={<Surveys />} />
          <Route path={routes.EDIT_SURVEY.path} element={<EditSurvey />} />
          <Route path={routes.EDIT_QUESTION.path} element={<EditQuestion />} />
          <Route
            path={routes.SURVEY_ANSWERS.path}
            element={<SurveyAnswers />}
          />
          <Route path={routes.ANSWER_SURVEY.path} element={<AnswerSurvey />} />
          <Route element={<KioskGuard />}>
            <Route path={routes.COMPANIES.path} element={<Companies />} />
          </Route>
          <Route path={routes.USERS.path} element={<Users />} />
          <Route path={routes.CSRD.path} element={<CSRD />} />
          <Route
            path={routes.CSRD_DISCLOSURE_REQUIREMENT.path}
            element={<DisclosureRequirement />}
          />
        </Route>
      </Route>
      <Route element={<OnboardingGuard />}>
        <Route element={<Onboarding />} path={routes.ONBOARDING.path} />
      </Route>
    </>,
  ),
)
