import {
  Button,
  Drawer,
  Group,
  PasswordInput,
  Stack,
  Text,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"

import {
  resetPasswordSchema,
  updatePasswordBody,
} from "@kiosk/shared/schemas/users"

import { useResetPassword } from "@kiosk/front/api/users/resetPassword"

export const ResetPasswordModal = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const { t } = useTranslation(["common"])

  const { getInputProps, errors, values, isValid, onSubmit, reset } =
    useForm<updatePasswordBody>({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validate: zodResolver(resetPasswordSchema),
      validateInputOnChange: true,
    })

  const { password, confirmPassword } = values
  const handleCancel = () => {
    reset()
    close()
  }

  const { mutate: resetPassword, isPending } = useResetPassword()

  const handleResetPassword = onSubmit(() => {
    resetPassword({
      password,
    })
    handleCancel()
  })

  return (
    <>
      <Button onClick={open}>{t("settings:account.resetPassword")}</Button>
      <Drawer.Root
        position="right"
        size="lg"
        opened={opened}
        onClose={handleCancel}
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Stack h="100%" gap={32}>
            <Drawer.Header
              p={24}
              style={{
                borderBottom: "1px solid var(--mantine-color-gray-3)",
              }}
            >
              <Drawer.Title fz="lg" fw={600} c="black">
                {t("settings:account.resetPassword")}
              </Drawer.Title>
              <Drawer.CloseButton />
            </Drawer.Header>
            <Stack h="100%" justify="space-between">
              <Drawer.Body px={24}>
                <form id="reset-password" onSubmit={handleResetPassword}>
                  <Stack gap={24} w="100%">
                    <Stack gap={8}>
                      {/* TODO: Should we use the custom one instead? */}
                      <PasswordInput
                        label={t("fields.passwordResetLabel")}
                        placeholder={t("fields.passwordResetPlaceholder")}
                        {...getInputProps("password")}
                        error={
                          errors.password ||
                          (!isValid() &&
                            confirmPassword &&
                            confirmPassword !== password &&
                            true)
                        }
                      />
                      <Text c="gray.5" fz="sm">
                        {t("fields.passwordNote")}
                      </Text>
                    </Stack>
                    <Stack gap={24} w="100%">
                      <Stack gap={8}>
                        <PasswordInput
                          label={t("fields.confirmPasswordLabel")}
                          placeholder={t("fields.confirmPasswordLabel")}
                          {...getInputProps("confirmPassword")}
                          error={
                            (errors.password && confirmPassword && true) ||
                            (confirmPassword !== password &&
                              t("schemas.passwordConfirmationError"))
                          }
                        />
                        <Text c="gray.5" fz="sm">
                          {t("fields.passwordNote")}
                        </Text>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              </Drawer.Body>
              <Group
                style={{
                  borderTop: "1px solid var(--mantine-color-gray-3)",
                }}
                gap={10}
                p={24}
                justify="flex-end"
              >
                <Button variant="outline" color="gray" onClick={handleCancel}>
                  {t("buttons.cancel")}
                </Button>
                <Button
                  form="reset-password"
                  type="submit"
                  loading={isPending}
                  disabled={!isValid()}
                >
                  {t("settings:account.resetPassword")}
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
