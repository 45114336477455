import { Container, Modal, Tabs, TextInput } from "@mantine/core"
import { useDebouncedState } from "@mantine/hooks"
import { IconSearch } from "@tabler/icons-react"
import { minBy, orderBy } from "lodash"
import { ChangeEventHandler, useEffect, useState } from "react"

import { CsrdCategory, Question } from "@kiosk/types/data/dato"

import { i18n } from "@kiosk/i18n"

import { useSurveyQuestionsDataModel } from "@kiosk/front/api/surveys/questions/model/surveyQuestionsDataModel"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { QuestionTable } from "@kiosk/front/components/questions/AddQuestionsModal/QuestionTable"
import { QuestionTableFooter } from "@kiosk/front/components/questions/AddQuestionsModal/QuestionTableFooter"
import { QuestionsPlaceholder } from "@kiosk/front/components/questions/AddQuestionsModal/QuestionsPlaceholder"
import { SelectTopicPanel } from "@kiosk/front/components/questions/AddQuestionsModal/SelectTopicPanel"
import { useCurrentQuestions } from "@kiosk/front/components/questions/AddQuestionsModal/useCurrentQuestions"

type SearchInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>
}

const SearchInput = ({ onChange }: SearchInputProps) => (
  <Container
    fluid
    py={16}
    px={24}
    style={{
      borderTop: "1px solid var(--mantine-color-gray-3)",
      borderBottom: "1px solid var(--mantine-color-gray-3)",
    }}
  >
    <TextInput
      placeholder={i18n.t("survey:placeholders.findQuestion")}
      leftSection={<IconSearch size={18} />}
      onChange={onChange}
    />
  </Container>
)

type AddQuestionModalContentProps = {
  csrdCategories: CsrdCategory[]
  onSave: (questions: Question[]) => void
  onClose: () => void
}

const AddQuestionModalContent = ({
  csrdCategories,
  onSave,
  onClose,
}: AddQuestionModalContentProps) => {
  const [search, setSearch] = useDebouncedState("", 500)
  const [categoryId, setCategoryId] = useState<string | null>(
    minBy(csrdCategories, "order")?.id ?? null,
  )
  const [topicId, setTopicId] = useState<string>()
  const [selectedQuestions, setSelectedQuestions] = useState<Question[]>([])

  const currentQuestions = useCurrentQuestions(
    csrdCategories,
    categoryId,
    topicId,
    search,
  )

  useEffect(() => {
    const topics = csrdCategories.find(({ id }) => id === categoryId)?.topics
    setTopicId(minBy(topics, "name")?.id)
  }, [categoryId, csrdCategories])

  if (!csrdCategories) return
  return (
    <Tabs value={categoryId} onChange={setCategoryId}>
      <Tabs.List>
        {orderBy(csrdCategories, ({ order }) => order).map(({ id, name }) => (
          <Tabs.Tab value={id} key={id} fz="md">
            {name}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {csrdCategories.map((csrdCategory) => (
        <SelectTopicPanel
          key={csrdCategory.id}
          topicId={topicId}
          setTopicId={setTopicId}
          csrdCategory={csrdCategory}
        />
      ))}
      <SearchInput onChange={(e) => setSearch(e.target.value)} />
      {currentQuestions.length > 0 ? (
        <QuestionTable
          currentQuestions={currentQuestions}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : (
        <QuestionsPlaceholder />
      )}
      <QuestionTableFooter
        onSave={() => onSave(selectedQuestions)}
        onClose={onClose}
      />
    </Tabs>
  )
}

type RootProps = {
  opened: boolean
  onClose: () => void
  onSave: (questions: Question[]) => void
}

export const AddQuestionsModal = ({ opened, onClose, onSave }: RootProps) => {
  const surveyQuestionsDataModelQuery = useSurveyQuestionsDataModel()

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={i18n.t("survey:questions.questionSelect")}
      size="70%"
      styles={{
        body: surveyQuestionsDataModelQuery.isLoading
          ? {
              paddingTop: 100,
              paddingBottom: 200,
            }
          : {
              padding: 0,
            },
      }}
    >
      <QueryWrapper query={surveyQuestionsDataModelQuery}>
        {({ data }) => (
          <AddQuestionModalContent
            csrdCategories={data}
            onSave={onSave}
            onClose={onClose}
          />
        )}
      </QueryWrapper>
    </Modal>
  )
}

/*
// TODO:
  const { mutateAsync: createSurveyQuestion, isPending } =
    useCreateSurveyQuestionMutation(surveyId)

selectedQuestions.forEach((question) =>
            createSurveyQuestion({
              ...omit(question, "id"),
              datoId: question.id,
            }),
          )
*/
