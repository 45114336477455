import { Button, Drawer, Group, Stack, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { useTranslation } from "react-i18next"

import {
  InviteUserBody,
  inviteUserSchema,
} from "@kiosk/shared/schemas/companies"

import { useInviteCompanyUser } from "@kiosk/front/api/companies/inviteUser"
import { useUser } from "@kiosk/front/components/auth/UserContext"

export const AddUser = () => {
  const user = useUser()
  const [opened, { open, close }] = useDisclosure(false)
  const { t } = useTranslation(["common", "settings"])

  const form = useForm<InviteUserBody>({
    validate: zodResolver(inviteUserSchema),
  })

  const handleCancel = () => {
    form.reset()
    close()
  }

  const { mutateAsync: inviteUserToOrganization, isPending } =
    useInviteCompanyUser(user.companyId, handleCancel)

  const handleAddUser = form.onSubmit(async (data, event) => {
    event?.preventDefault()
    const { email, firstName, lastName } = data
    await inviteUserToOrganization({
      email,
      firstName,
      lastName,
    })
  })

  return (
    <>
      <Button onClick={open}>{t("settings:users.newUser")}</Button>
      <Drawer.Root position="right" size="md" opened={opened} onClose={close}>
        <Drawer.Overlay />
        <Drawer.Content>
          <Stack h="100%" gap={32}>
            <Drawer.Header
              p={24}
              style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
            >
              <Drawer.Title fz="lg" fw={600} c="black">
                {t("settings:users.createUser")}
              </Drawer.Title>
              <Drawer.CloseButton />
            </Drawer.Header>
            <Stack h="100%" justify="space-between">
              <Drawer.Body px={24}>
                <form id="add-user" onSubmit={handleAddUser}>
                  <Stack>
                    <TextInput
                      label={t("fields.firstNameLabel")}
                      placeholder={t("fields.firstNamePlaceholder")}
                      {...form.getInputProps("firstName")}
                    />
                    <TextInput
                      label={t("fields.lastNameLabel")}
                      placeholder={t("fields.lastNamePlaceholder")}
                      {...form.getInputProps("lastName")}
                    />
                    <TextInput
                      label={t("fields.emailLabel")}
                      placeholder={t("fields.emailPlaceholder")}
                      {...form.getInputProps("email")}
                    />
                  </Stack>
                </form>
              </Drawer.Body>
              <Group
                style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
                gap={10}
                p={24}
                justify="flex-end"
              >
                <Button variant="outline" color="gray" onClick={handleCancel}>
                  {t("buttons.cancel")}
                </Button>
                <Button
                  form="add-user"
                  type="submit"
                  loading={isPending}
                  disabled={!form.isValid()}
                >
                  {t("settings:users.createUser")}
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
