import { Box, Image, Group, Stack } from "@mantine/core"
import { Outlet } from "react-router-dom"

import authBackground from "@kiosk/front/assets/img/auth-background.png"
import { Logo } from "@kiosk/front/components/layout/Logo"

export const AuthLayout = () => {
  return (
    <Stack align="flex-start" h="100vh">
      <Group w="100vw" h="100%" p={24} gap={0}>
        <Stack w="50%" h="100%" align="flex-start" justify="center">
          <Box h={28}>
            <Logo height={28} />
          </Box>
          <Stack w="100%" h="100%" justify="center" align="center">
            <Outlet />
          </Stack>
        </Stack>
        <Stack w="50%" h="100%">
          <Image
            flex={1}
            radius="md"
            w="100%"
            h="100%"
            fit="cover"
            src={authBackground}
            alt="auth image"
          />
        </Stack>
      </Group>
    </Stack>
  )
}
