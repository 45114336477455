import { Stack, Text, ThemeIcon } from "@mantine/core"
import { IconCircleX } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

export const QuestionsPlaceholder = () => {
  const { t } = useTranslation("survey")
  return (
    <Stack align="center" py={32} gap="sm">
      <ThemeIcon c="gray.5" size="xl">
        <IconCircleX width={40} height={40} />
      </ThemeIcon>
      <Text c="gray.5">{t("questionSearchNoResults")}</Text>
    </Stack>
  )
}
