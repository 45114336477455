import { useQuery } from "@tanstack/react-query"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"

import { listAssignmentsSchema } from "@kiosk/shared/schemas/assignments"

import { apiClient } from "@kiosk/front/lib/apiClient"

import { assignmentsKeys } from "./assignmentsKeys"

export const useListAssignmentsQuery = (questionId: string) =>
  useQuery({
    queryKey: assignmentsKeys.list(questionId),
    queryFn: async (): Promise<AssignmentResponses.Assignment[]> => {
      const response = await apiClient.get<AssignmentResponses.Assignment[]>(
        `/survey_questions/${questionId}/assignments`,
      )
      return listAssignmentsSchema.parseAsync(response.data)
    },
  })
