import { Center, Overlay, Stack, useMantineTheme } from "@mantine/core"
import React from "react"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
// We export colors directly because in StaticSplash we don't have access to the Mantine context during app loading
import { colors } from "@kiosk/front/assets/theme/colors"
import { Fade } from "@kiosk/front/components/generic/Fade"
import { Logo } from "@kiosk/front/components/layout/Logo"

export const DynamicSplash = () => {
  const theme = useMantineTheme()
  const authState = useAuthState()
  const [displayOverlay, setDisplayOverLay] = React.useState(true)
  const [canShow, setCanShow] = React.useState(authState.isLoading)

  React.useEffect(() => {
    if (!authState.isLoading) {
      if (!authState.isSignedIn) {
        setDisplayOverLay(false)
      }
      setInterval(() => setCanShow(false), 1000)
    }
  }, [authState.isLoading, authState.isSignedIn])

  return displayOverlay ? (
    <Overlay backgroundOpacity={0}>
      <Fade
        onExit={() => setDisplayOverLay(false)}
        loading={canShow}
        position="fixed"
      >
        <Center w="100vw" h="100vh" pos="fixed" bg={theme.colors.gray[0]}>
          <Stack w="10%" h="%10">
            <Logo />
          </Stack>
        </Center>
      </Fade>
    </Overlay>
  ) : null
}

export const StaticSplash = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        position: "fixed",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.gray[0],
      }}
    >
      <div style={{ width: "10%", height: "10%" }}>
        <Logo />
      </div>
    </div>
  )
}
