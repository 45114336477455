import { Avatar } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Company, CompanyStatus } from "@kiosk/types/prisma-client"

import { Column } from "@kiosk/front/components/generic/types"

export const useCompaniesColumns = (): Array<Column<Company>> => {
  const { t } = useTranslation("company")

  const statusMap = useMemo(
    () => ({
      ONBOARDED: t("status.onboarded"),
      ACCOUNT_SETUP: t("status.accountSetup"),
      FIRST_UPLOAD: t("status.firstUpload"),
      GEO_STRUCTURE: t("status.geoStructure"),
      ORG_STRUCTURE: t("status.orgStructure"),
    }),
    [t],
  )

  return [
    {
      key: "name",
      colId: "name",
      title: t("columns.name"),
      render: (name, { logoUrl }) => (
        <>
          <Avatar color="green" src={logoUrl}>
            {name.slice(0, 2)}
          </Avatar>
          {name}
        </>
      ),
    },
    {
      key: "status",
      colId: "status",
      title: t("columns.status"),
      render: (status: CompanyStatus) => statusMap[status],
    },
    {
      key: "numOfUsers",
      colId: "_count",
      title: t("columns.numOfUsers"),
      render: ({ users }) => users.toString(),
    },
    {
      key: "arrow",
      colId: "arrow",
      title: "",
      render: () => <IconChevronRight />,
    },
  ]
}
