export const getInitials = (first?: string, last?: string) =>
  `${first?.charAt(0)}${last?.charAt(0)}`

/**
 * Formats the size of a blob (in bytes) as a string.
 * @param blobSize The size of the blob in bytes.
 * @returns The formatted string representation of the blob size.
 */
export const formatBlobSize = (blobSize: number): string => {
  const oneKB = 1024
  const oneMB = oneKB * 1024
  const oneGB = oneMB * 1024

  if (blobSize < oneMB) {
    return `(${(blobSize / oneKB).toFixed(2)}KB)`
  } else if (blobSize < oneGB) {
    return `(${(blobSize / oneMB).toFixed(2)}MB)`
  } else {
    return `(${(blobSize / oneGB).toFixed(2)}GB)`
  }
}

export const formatEnumArray = <T extends string>(
  enumObject: Record<T, string>,
): { value: T; label: string }[] => {
  return Object.entries(enumObject).map(([value, label]) => ({
    value: value as T,
    label: (label as string)
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w|\s\w/g, (char) => char.toUpperCase()),
  }))
}

export const pprint = (o: object): string => JSON.stringify(o, undefined, 2)
