import { Button, Group, Modal, rem, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { useCompanyUsersQuery } from "@kiosk/front/api/companies/queryCompanyUsers"
import { useDeleteUserMutation } from "@kiosk/front/api/users/deleteUser"
import { useUserQuery } from "@kiosk/front/api/users/getUser"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { Table } from "@kiosk/front/components/generic"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { queryClient } from "@kiosk/front/lib/queryClient"
import { useSettingsColumns } from "@kiosk/front/pages/Settings/columns"

import { AddUser } from "./AddUser"
import { UpdateUser } from "./UpdateUser"

export const Users = () => {
  const { companyId } = useUser()
  const companyUsersQuery = useCompanyUsersQuery(companyId)

  const [
    deleteTaskModalOpened,
    { close: closeTaskDeleteModal, open: openTaskDeleteModal },
  ] = useDisclosure(false)
  const [
    updateModalOpened,
    { close: closeUpdateModal, open: openUpdateModal },
  ] = useDisclosure(false)
  const { t } = useTranslation(["settings", "common"])
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const { data: selectedUserData } = useUserQuery(selectedUserId || "")

  const onSuccess = async () =>
    await queryClient.invalidateQueries({
      queryKey: companyKeys.companyUsers(companyId),
    })

  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    useDeleteUserMutation(onSuccess)

  const handleDeleteAccount = (id: string | null) => async () => {
    if (id) {
      await deleteAccount(id)
    }
    setSelectedUserId(null)
    closeTaskDeleteModal()
  }

  const showDeleteModal = (id: string) => {
    setSelectedUserId(id)
    openTaskDeleteModal()
  }

  const showUpdateModal = (id: string) => {
    setSelectedUserId(id)
    openUpdateModal()
  }

  const columns = useSettingsColumns(
    showDeleteModal,
    showUpdateModal,
    isDeletingAccount,
    selectedUserId,
  )

  return (
    <PageLayout>
      <Stack gap={30}>
        <Group w="100%" justify="flex-end" gap={16}>
          <AddUser />
        </Group>
        <QueryWrapper query={companyUsersQuery}>
          {({ data: companyUsers }) => (
            <Table columns={columns} dataSource={companyUsers.data} />
          )}
        </QueryWrapper>
        <Modal.Root
          centered
          opened={deleteTaskModalOpened}
          onClose={closeTaskDeleteModal}
        >
          <Modal.Overlay />
          <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
            <Modal.Header>
              <Modal.Title c="gray.9" fz="xxl" fw={600}>
                {t("settings:account.deleteAccount")}
              </Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
              <Text fz="md" c="gray.7" mb={34}>
                {t("settings:account.deleteAccountDescription")}
              </Text>
              <Group justify="flex-end">
                <Button
                  color="gray"
                  variant="outline"
                  onClick={closeTaskDeleteModal}
                >
                  {t("common:buttons.cancel")}
                </Button>
                <Button
                  color="red.8"
                  onClick={handleDeleteAccount(selectedUserId)}
                >
                  {t("settings:account.deleteAccount")}
                </Button>
              </Group>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
        {selectedUserData && (
          <UpdateUser
            opened={updateModalOpened}
            close={closeUpdateModal}
            selectedUserId={selectedUserId!}
            selectedUserData={selectedUserData}
          />
        )}
      </Stack>
    </PageLayout>
  )
}
