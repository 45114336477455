import {
  Button,
  Divider,
  Drawer,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconTrash } from "@tabler/icons-react"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { useTranslation } from "react-i18next"

import { Task } from "@kiosk/types/prisma-client"

import {
  CreateTaskBody,
  TASK_STATUS,
  createTaskSchema,
} from "@kiosk/shared/schemas/tasks"

import { useCompanyUsersQuery } from "@kiosk/front/api/companies/queryCompanyUsers"
import { useGetTaskQuery } from "@kiosk/front/api/tasks/taskByIdQuery"
import { useTasksQuery } from "@kiosk/front/api/tasks/tasksQuery"
import { useUpdateTaskMutation } from "@kiosk/front/api/tasks/updateTask"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { DeleteTaskModal } from "@kiosk/front/pages/Tasks/DeleteTaskModal"
import { formatEnumArray } from "@kiosk/front/utils/helpers"

dayjs.extend(relativeTime)

interface Props {
  taskId: string
  ownerId: string | undefined
  opened: boolean
  close: () => void
  open: () => void
}

interface EditTaskInnerModalProps {
  task: Task
  opened: boolean
  close: () => void
}

const EditTaskInnerModal = ({
  task,
  opened,
  close,
}: EditTaskInnerModalProps) => {
  const { t } = useTranslation(["task"])

  const { companyId } = useUser()

  const [
    deleteTaskModalOpened,
    { close: closeTaskDeleteModal, open: openTaskDeleteModal },
  ] = useDisclosure(false)

  const tasksQuery = useTasksQuery({ ownerId: undefined })
  const companyUsersQuery = useCompanyUsersQuery(companyId)
  const { mutate: updateTask, isPending } = useUpdateTaskMutation()

  const handleCancel = () => {
    reset()
    close()
  }

  const { onSubmit, reset, getInputProps, isValid } = useForm<CreateTaskBody>({
    initialValues: {
      name: task.name,
      categoryId: task.categoryId,
      status: task.status,
      dueDate: task.dueDate ? new Date(task.dueDate) : undefined,
      deliverables: task.deliverables ?? undefined,
      description: task.description ?? undefined,
      ownerId: task.ownerId ?? undefined,
      stakeholders: task.stakeholders ?? undefined,
    },
    validateInputOnChange: true,
    validate: zodResolver(createTaskSchema),
  })

  const handleOnSave = onSubmit((data, event) => {
    event?.preventDefault()
    updateTask({ id: task.id, data })
    handleCancel()
  })

  return (
    <Drawer.Root
      position="right"
      size="lg"
      opened={opened}
      onClose={handleCancel}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <Stack h="100%" gap={32}>
          <Drawer.Header
            p={24}
            style={{
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
          >
            <Drawer.Title fz="lg" fw={600} c="black">
              {t("taskOverview")}
            </Drawer.Title>
            <Group gap={18} align="center">
              {task.id && (
                <>
                  <Button
                    onClick={openTaskDeleteModal}
                    variant="transparent"
                    color="red"
                    p={8}
                  >
                    <IconTrash width={20} height={20} />
                  </Button>
                  <Divider my="auto" orientation="vertical" c="gray" h={24} />
                </>
              )}
              <Drawer.CloseButton size="lg" c="gray.6" />
            </Group>
          </Drawer.Header>
          <Stack h="100%" justify="space-between">
            <Drawer.Body px={24}>
              <form id="create-task" onSubmit={handleOnSave}>
                <Stack>
                  <TextInput
                    label={t("fields.name")}
                    placeholder={t("fields.name")}
                    {...getInputProps("name")}
                  />
                  <Textarea
                    label={t("fields.description")}
                    placeholder={t("fields.description")}
                    {...getInputProps("description")}
                  />
                  <TextInput
                    label={t("fields.stakeholders")}
                    placeholder={t("fields.stakeholders")}
                    {...getInputProps("stakeholders")}
                  />
                  <TextInput
                    label={t("fields.deliverables")}
                    placeholder={t("fields.deliverables")}
                    {...getInputProps("deliverables")}
                  />
                  <Group
                    pt={16}
                    p={4}
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.category")}</Text>
                    <Select
                      allowDeselect={false}
                      w="30%"
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      placeholder={t("placeholders.category")}
                      data={tasksQuery.data?.data.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      {...getInputProps("categoryId")}
                    />
                  </Group>
                  <Group
                    p={4}
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.owner")}</Text>
                    <Select
                      w="30%"
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      placeholder={t("placeholders.owner")}
                      data={companyUsersQuery.data?.data.map((option) => ({
                        value: option.id,
                        label: option.firstName + " " + option.lastName,
                      }))}
                      {...getInputProps("ownerId")}
                    />
                  </Group>
                  <Group
                    p={4}
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.dueDate")}</Text>
                    <DatePickerInput
                      w="30%"
                      placeholder={t("placeholders.dueDate")}
                      variant="unstyled"
                      rightSection={<IconChevronDown size={20} />}
                      {...getInputProps("dueDate")}
                    />
                  </Group>
                  <Group
                    p={4}
                    justify="space-between"
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <Text>{t("fields.status")}</Text>
                    <Select
                      w="30%"
                      rightSection={<IconChevronDown size={20} />}
                      searchable
                      variant="unstyled"
                      placeholder={formatEnumArray(TASK_STATUS)[0].label}
                      data={formatEnumArray(TASK_STATUS)}
                      {...getInputProps("status")}
                    />
                  </Group>
                </Stack>
              </form>
            </Drawer.Body>
            <Group
              style={{
                borderTop: "1px solid var(--mantine-color-gray-3)",
              }}
              gap={10}
              p={24}
              justify="flex-end"
            >
              <Button variant="outline" color="gray" onClick={handleCancel}>
                {t("buttons.cancel")}
              </Button>
              <Button
                form="create-task"
                type="submit"
                loading={isPending}
                disabled={!isValid() || isPending}
              >
                {t("buttons.save")}
              </Button>
            </Group>
          </Stack>
        </Stack>
        <DeleteTaskModal
          resetForm={reset}
          taskId={task.id}
          closeEditTaskModal={handleCancel}
          close={closeTaskDeleteModal}
          opened={deleteTaskModalOpened}
          ownerId={task.ownerId!}
        />
      </Drawer.Content>
    </Drawer.Root>
  )
}

export const EditTaskModal = ({ taskId, close, opened }: Props) => {
  const taskQuery = useGetTaskQuery(taskId)

  return (
    <QueryWrapper query={taskQuery}>
      {({ data: task }) => (
        <EditTaskInnerModal task={task} opened={opened} close={close} />
      )}
    </QueryWrapper>
  )
}
