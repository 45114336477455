import { Group, Progress, rem, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { round } from "@kiosk/shared/utils/helpers"

type Props = {
  totalTasksCount: number
  tasksByStatus: { status: string; _count: number }[]
}

export const ProgressBarAndInfo = ({
  totalTasksCount,
  tasksByStatus,
}: Props) => {
  const { t } = useTranslation("task")

  const findTaskCountByStatus = (taskStatus: string) =>
    tasksByStatus.find(({ status }) => status === taskStatus)?._count ?? 0

  const doneTasksCount = findTaskCountByStatus("DONE") ?? 0

  const progressPercentage = round(
    totalTasksCount > 0 ? (100 * doneTasksCount) / totalTasksCount : 0,
    2,
  )

  return (
    <Group grow gap={32}>
      <Stack
        style={{
          borderStyle: "solid",
          borderRadius: rem(6),
          borderColor: "var(--mantine-color-gray-2)",
          borderWidth: rem(1),
        }}
        py={16}
        px={24}
        gap={8}
      >
        <Group justify="space-between">
          <Text c="gray.5" fz="sm" fw={500}>
            {t("completion")}
          </Text>
          <Text c="gray.9" fz="md" fw={600}>
            {`${progressPercentage}%`}
          </Text>
        </Group>
        <Progress value={progressPercentage} c="green" />
      </Stack>
      <Group
        style={{
          borderStyle: "solid",
          borderRadius: rem(6),
          borderColor: "var(--mantine-color-gray-2)",
          borderWidth: rem(1),
        }}
        gap={8}
        py={16}
        px={24}
        justify="space-between"
      >
        <Stack gap={0}>
          <Text c="gray.5" fz="sm" fw={500}>
            {t("status.done")}
          </Text>
          <Text c="gray.9" fz="md" fw={600}>
            {`${findTaskCountByStatus("DONE")} / ${totalTasksCount}`}
          </Text>
        </Stack>
        <Stack gap={0}>
          <Text c="gray.5" fz="sm" fw={500}>
            {t("status.inProgress")}
          </Text>
          <Text c="gray.9" fz="md" fw={600}>
            {`${findTaskCountByStatus("IN_PROGRESS")} / ${totalTasksCount}`}
          </Text>
        </Stack>
        <Stack gap={0}>
          <Text c="gray.5" fz="sm" fw={500}>
            {t("status.todo")}
          </Text>
          <Text c="gray.9" fz="md" fw={600}>
            {`${findTaskCountByStatus("TODO")} / ${totalTasksCount}`}
          </Text>
        </Stack>
      </Group>
    </Group>
  )
}
