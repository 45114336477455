import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { USER_ROLES } from "@kiosk/types/user"

import { UserContext, useUser } from "@kiosk/front/components/auth/UserContext"

export const KioskGuard = () => {
  const navigate = useNavigate()
  const user = useUser()

  const isKioskSuperadmin = user.role === USER_ROLES.KIOSK_ADMIN

  useEffect(() => {
    // Redirect to home if non kiosk admin is accessing the route
    if (!isKioskSuperadmin) {
      navigate("/")
    }
  }, [isKioskSuperadmin, navigate])

  return (
    user && (
      <UserContext.Provider value={{ user, updateUser: () => {} }}>
        <Outlet />
      </UserContext.Provider>
    )
  )
}
