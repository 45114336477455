import { RedirectToSignIn } from "@clerk/clerk-react"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { UserContext } from "@kiosk/front/components/auth/UserContext"

export const AuthGuard = () => {
  const navigate = useNavigate()
  const { user, isLoading, isSignedIn, isOnboarded } = useAuthState()
  const companyOnboarded = user?.company.status === "ONBOARDED"

  useEffect(() => {
    // Redirect to onboarding if the user is signed in but not onboarded or company is not onboarded
    if (!isLoading && isSignedIn && (!isOnboarded || !companyOnboarded)) {
      navigate("/onboarding")
    }
  }, [isLoading, isOnboarded, isSignedIn, navigate, companyOnboarded])

  if (isLoading) return null
  if (!isSignedIn) return <RedirectToSignIn />
  if (!isOnboarded) return null
  return (
    // TODO: we might need to update the user context when the user is updated
    <UserContext.Provider value={{ user, updateUser: () => {} }}>
      <Outlet />
    </UserContext.Provider>
  )
}
