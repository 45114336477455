import { RedirectToSignIn, useUser } from "@clerk/clerk-react"
import { Center, Loader } from "@mantine/core"
import { useEffect, useMemo } from "react"
import { Outlet, useNavigate } from "react-router-dom"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { routes } from "@kiosk/front/utils/constants/routes"

export const OnboardingGuard = () => {
  const navigate = useNavigate()
  const { isLoading, isSignedIn, isOnboarded, user } = useAuthState()
  const { user: clerkUser } = useUser()
  const companyOnboarded = user?.company?.status === "ONBOARDED"

  // TODO: should it be in auth state
  const clerkOrganizationId = useMemo(
    () => clerkUser?.organizationMemberships[0]?.organization.id,
    [clerkUser?.organizationMemberships],
  )

  useEffect(() => {
    // Redirect to onboarding if the user is signed in but not onboarded or company is not onboarded
    if (!isLoading && isSignedIn && (!isOnboarded || !companyOnboarded)) {
      navigate("/onboarding")
    }
  }, [isLoading, isOnboarded, isSignedIn, navigate, companyOnboarded])

  if (isLoading)
    return (
      <Center h="100vh">
        <Loader />
      </Center>
    )
  if (!isSignedIn) return <RedirectToSignIn />
  if (!clerkOrganizationId)
    // TODO: design this page
    return (
      <div>
        Invalid Account. Please ask your administrator for an invitation
      </div>
    )
  if (isOnboarded && companyOnboarded) {
    navigate(routes.CONGRATULATIONS.path)
  }
  return <Outlet />
}
