import { Button, Group, Stack, Text, rem } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Logo } from "@kiosk/front/components/layout/Logo"

import "./Onboarding.css"

export const Congratulations = () => {
  const { t } = useTranslation(["common"])
  const navigate = useNavigate()
  return (
    <Stack mih="100vh" align="center" gap={40}>
      <Group
        mih={74}
        w="100%"
        py={16}
        px={24}
        style={{ borderBottom: "solid 1px var(--mantine-color-gray-3)" }}
        justify="center"
      >
        <Logo height={28} />
      </Group>
      <Stack align="center" gap={rem(24)}>
        <Text fz={64}>🎉</Text>
        <Stack align="center" gap={rem(0)}>
          <Text fw={600} style={{ fontSize: "32px" }}>
            {t("congratulations")} !
          </Text>
          <Text fz="xxl" c="gray.5">
            {t("setupYourWorkspace")} !
          </Text>
        </Stack>
        <Button onClick={() => navigate("/")}>{t("goToDashboard")}</Button>
      </Stack>
    </Stack>
  )
}
