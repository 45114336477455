import { notifications, showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { CompanyRequests } from "@kiosk/types/endpoints/companies"

import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const inviteCompanyUser = async (
  companyId: string,
  data: CompanyRequests.InviteUser,
): Promise<{ message: string }> => {
  const response = await apiClient.post<{ message: string }>(
    `/companies/${companyId}/invite-user`,
    data,
  )
  return response.data
}

export const useInviteCompanyUser = (
  companyId: string,
  onSuccess: () => void,
) => {
  const { t } = useTranslation(["company", "common"])
  return useMutation({
    mutationFn: (data: CompanyRequests.InviteUser) =>
      inviteCompanyUser(companyId, data),
    onSuccess: async (data: { message: string }) => {
      await queryClient.invalidateQueries({
        queryKey: companyKeys.companyUsers(companyId),
      })
      showNotification({
        message: `${t("success.created")}: ` + data.message,
      })
      onSuccess()
    },
    onError: (error: Error) => {
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
