import { Image, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import emptyDashboard from "@kiosk/front/assets/img/empty-dashboard.png"

export const EmptyDashboard = () => {
  const { t } = useTranslation("dashboard")

  return (
    <Stack align="center">
      <Stack gap={16} w={500}>
        <Image w="100%" src={emptyDashboard} alt="Empty Dashboard" />
        <Text ta="center" fz="xl" fw={500}>
          {t("empty.title")}
        </Text>
        <Text ta="center" fz="lg" c="gray.6">
          {t("empty.subtitle")}
        </Text>
      </Stack>
    </Stack>
  )
}
