import { Flex, Stack, Image, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import emptySources from "@kiosk/front/assets/img/empty-survey.png"

export const EmptySurvey = () => {
  const { t } = useTranslation("survey")

  return (
    <Flex
      h="60vh"
      w="calc(80vw - 270px)"
      justify="center"
      align="center"
      direction="row"
    >
      <Stack gap={16} w={300}>
        <Image w="100%" src={emptySources} alt={t("alt.noSurveysAvailable")} />
        <Text ta="center" fz="xl" fw={500}>
          {t("placeholders.noSurveysAddedYet")}
        </Text>
        <Text ta="center" fz="lg" c="gray.6">
          {t("placeholders.yourSurveysWillAppearHere")}
        </Text>
      </Stack>
    </Flex>
  )
}
