import { useParams } from "react-router-dom"

import { Account } from "./Account"
import { Language } from "./Language"
import { Users } from "./Users"

export const Settings = () => {
  const { tab } = useParams()

  switch (tab) {
    case "users":
      return <Users />
    case "language":
      return <Language />
    case "account":
    default:
      return <Account />
  }
}
