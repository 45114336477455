import { Group, ActionIcon, Text } from "@mantine/core"
import { IconDownload, IconX } from "@tabler/icons-react"

export const SurveyFileUploaded = ({
  file,
  onReset,
}: {
  file: { name: string; signedUrl: string }
  onReset: () => void
}) => (
  <Group flex={1} justify="space-between" wrap="nowrap" maw={200}>
    <Group wrap="nowrap" style={{ overflow: "hidden" }}>
      <ActionIcon
        variant="subtle"
        color="gray.4"
        onClick={() => window.open(file.signedUrl, "_blank")}
      >
        <IconDownload size={24} />
      </ActionIcon>
      <Text truncate="end">{file.name}</Text>
    </Group>
    <ActionIcon variant="subtle" color="gray.4" onClick={onReset}>
      <IconX size={24} />
    </ActionIcon>
  </Group>
)
