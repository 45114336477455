import { Checkbox } from "@mantine/core"
import _ from "lodash"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { CompanyResponses } from "@kiosk/types/endpoints/companies"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { CreateAssignmentBody } from "@kiosk/shared/schemas/assignments"

import { useCompanyUsersQuery } from "@kiosk/front/api/companies/queryCompanyUsers"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { Table } from "@kiosk/front/components/generic"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { Assignee } from "@kiosk/front/pages/Surveys/EditQuestion"
import { AssigneeDialog } from "@kiosk/front/pages/Surveys/EditQuestion/AssigneeDialog"
import { AssigneeSelect } from "@kiosk/front/pages/Surveys/EditQuestion/AssigneeSelect"
import { AssignmentDimensionsCell } from "@kiosk/front/pages/Surveys/EditQuestion/AssignmentDimensionsCell"
import { usePosition } from "@kiosk/front/utils/usePosition"

type Props = {
  currentAssignments: CreateAssignmentBody[]
  setCurrentAssignments: Dispatch<SetStateAction<CreateAssignmentBody[]>>
  companyUsers: CompanyResponses.GetCompanyUsers
  allDimensions: ConsolidatedDimension[]
}

const AssignmentsContent = ({
  currentAssignments,
  setCurrentAssignments,
  companyUsers,
  allDimensions,
}: Props) => {
  const { t } = useTranslation("survey")
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [tableRef, tablePosition] = usePosition<HTMLTableElement>()

  const onSelectCallback = useCallback(
    (index: number) => (assignee: Assignee | null) =>
      setCurrentAssignments((state) => {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            assigneeId: assignee?.id ?? null,
          },
          ...state.slice(index + 1),
        ]
      }),
    [setCurrentAssignments],
  )

  const companyContributors = useMemo(
    () =>
      _(companyUsers.data)
        .map(({ id, email, firstName, lastName }) => ({
          id,
          email,
          fullName: `${firstName} ${lastName}`,
        }))
        .uniqBy((u) => u.email)
        .sortBy((u) => u.fullName)
        .value(),
    [companyUsers],
  )

  const columns = useMemo(
    () => [
      {
        key: "label",
        colId: "assignmentDimensions",
        title: t("breakdownsHeader"),
        render: (
          assignmentDimensions: CreateAssignmentBody["assignmentDimensions"],
          _: CreateAssignmentBody,
          index: number,
        ) =>
          assignmentDimensions.length > 0 ? (
            <>
              <Checkbox
                checked={selectedIndices.includes(index)}
                onChange={() =>
                  selectedIndices.includes(index)
                    ? setSelectedIndices((l) => l.filter((i) => i !== index))
                    : setSelectedIndices((l) => [
                        ...l.filter((i) => i !== index),
                        index,
                      ])
                }
              />
              <AssignmentDimensionsCell
                assignmentDimensions={assignmentDimensions}
                allDimensions={allDimensions}
              />
            </>
          ) : (
            t("noBreakdownPlaceholder")
          ),
      },
      {
        key: "assignee",
        colId: "assigneeId",
        title: t("assigneeHeader"),
        width: 259,
        render: (
          assigneeId: string,
          _: CreateAssignmentBody,
          index: number,
        ) => (
          <AssigneeSelect
            companyContributors={companyContributors}
            assignee={
              companyContributors.find(({ id }) => id === assigneeId) ?? null
            }
            onSelect={onSelectCallback(index)}
            label={t("select")}
          />
        ),
      },
    ],
    [t, companyContributors, onSelectCallback, allDimensions, selectedIndices],
  )

  return (
    <>
      <AssigneeDialog
        selectedIndices={selectedIndices}
        companyContributors={companyContributors}
        position={{ left: tablePosition.x, top: tablePosition.y }}
        onSelect={(user) => {
          selectedIndices.forEach((index) => onSelectCallback(index)(user))
          setSelectedIndices([])
        }}
      />
      <Table
        ref={tableRef}
        dataSource={currentAssignments}
        getRowKey={(assignment: CreateAssignmentBody) =>
          assignment.assignmentDimensions
            .map(({ optionId }) => optionId)
            .join("-")
        }
        columns={columns}
        withColumnBorders
        flex={1}
      />
    </>
  )
}

export const Assignments = ({
  currentAssignments,
  setCurrentAssignments,
  allDimensions,
}: {
  currentAssignments: CreateAssignmentBody[]
  setCurrentAssignments: Dispatch<SetStateAction<CreateAssignmentBody[]>>
  allDimensions: ConsolidatedDimension[] // TODO: do we really need this?
}) => {
  const user = useUser()
  const companyUsersQuery = useCompanyUsersQuery(user.companyId)

  return (
    !!user && (
      <QueryWrapper query={companyUsersQuery}>
        {({ data: companyUsers }) => (
          <AssignmentsContent
            currentAssignments={currentAssignments}
            setCurrentAssignments={setCurrentAssignments}
            companyUsers={companyUsers}
            allDimensions={allDimensions}
          />
        )}
      </QueryWrapper>
    )
  )
}
