import { config } from "@kiosk/front/config"

import { colors } from "./colors"

const companyThemes = {
  kiosk: {
    fontFamily: "Roboto",
    colorPrimary: colors.green[6],
    colorSuccess: colors.green[5],
  },
  greenster: {
    fontFamily: "Poppins",
    colorPrimary: "#00CA91",
    colorSuccess: "#00CA91",
  },
}

const companyTheme = companyThemes[config.logo]

export const CLERK_THEME = {
  signIn: { variables: {} },
  layout: {
    logoPlacement: "none",
    socialButtonsPlacement: "bottom",
    // TODO: update it when the page exists
    termsPageUrl: "https://www.meetkiosk.com/mentions-legales",
    privacyPageUrl: "https://www.meetkiosk.com/politique-de-confidentialite",
  },
  variables: {
    colorPrimary: companyTheme.colorPrimary,
    colorSuccess: companyTheme.colorSuccess,
    // TODO: add colors
    // colorDanger: colors.red[400],
    // colorWarning: colors.orange[400],
    colorText: colors.gray[8],
    colorTextSecondary: colors.gray[6],
    colorInputText: colors.gray[8],
    fontFamily: companyTheme.fontFamily,
  },
} as const
