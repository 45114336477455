import { Accordion, Badge, Group, rem, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { Completion } from "@kiosk/front/components/Completion"
import { routes } from "@kiosk/front/utils/constants/index"

type Props = {
  categories: ReportsResponses.GetCategories
  activeTopicId: string
}

export const CSRDTopicsSideBar = ({ categories, activeTopicId }: Props) => {
  const navigate = useNavigate()

  const handleChangeTopic = (topicId: string) => {
    navigate(routes.CSRD.path.replace(":datoTopicId", topicId))
  }

  const activeCategoryId = useMemo(() => {
    return categories.find(
      (category) =>
        !!category.topics.find((topic) => topic.id === activeTopicId),
    )!.id
  }, [activeTopicId, categories])

  return (
    <Stack miw={400}>
      <Accordion
        py={8}
        chevronPosition="right"
        defaultValue={[activeCategoryId]}
        multiple
        styles={{
          item: { border: "none" },
          content: { padding: 0 },
          label: { paddingTop: rem(8), paddingBottom: rem(8) },
        }}
      >
        {_.sortBy(categories, (category) => category.order).map(
          ({ id, name, progress, topics }) => {
            return (
              <Accordion.Item value={id} key={id}>
                <Accordion.Control>
                  <Group gap={16}>
                    <Completion progress={progress} />
                    <Text fw={500} fz="lg">
                      {name}
                    </Text>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  {_.sortBy(topics, (t) => t.code).map(
                    ({ id: topicId, code, name: topicName, progress }) => {
                      return (
                        <Group
                          h={44}
                          style={{ cursor: "pointer" }}
                          bg={topicId === activeTopicId ? "green.1" : "inherit"}
                          py={8}
                          pl={39}
                          pr={8}
                          gap={8}
                          key={topicId}
                          onClick={() => handleChangeTopic(topicId)}
                        >
                          <Completion progress={progress} />
                          <Badge
                            h={26}
                            fz="xxs"
                            w={63}
                            px={4}
                            color="gray.1"
                            c="gray.7"
                            fw={400}
                            fs="italic"
                          >
                            {code}
                          </Badge>
                          <Text fz="sm" c="gray.9">
                            {topicName}
                          </Text>
                        </Group>
                      )
                    },
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            )
          },
        )}
      </Accordion>
    </Stack>
  )
}
