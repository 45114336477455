import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { taskKeys } from "./taskKeys"

export const deleteCategory = async (
  categoryId: string,
): Promise<{ message: string }> => {
  const response = await apiClient.delete(`/categories/${categoryId}`)
  return response.data
}

export const useDeleteCategoryMutation = (close: () => void) => {
  const { t } = useTranslation(["task", "common"])

  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: taskKeys.all(),
      })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      close()
      notifications.show({
        title: t("common:messages.success"),
        message: `${t("actions.deleteCategorySuccess")}`,
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
