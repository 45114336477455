// Breakdown represents the options selected by the user for a give dimension

import _ from "lodash"

import { DimensionBreakdownValue } from "@kiosk/types/dimensions"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

export type EnrichedDimensionBreakdown = (DimensionBreakdownValue & {
  dimensionLabel: string
  dimensionType: "explicit" | "typed"
  optionLabel: string
})[]

export const generateCartesianProduct = (
  input: Record<string, string[]>,
): DimensionBreakdownValue[][] => {
  const keys = Object.keys(input)
  const values = Object.values(input)

  // Base case: if there's no key, return an empty array
  if (keys.length === 0) {
    return []
  }

  // Helper function to generate Cartesian product
  function generateProduct(
    index: number,
    current: DimensionBreakdownValue[],
  ): DimensionBreakdownValue[][] {
    if (index === keys.length) {
      return [current]
    }

    const key = keys[index]
    const vals = values[index]

    return vals.flatMap((val) =>
      generateProduct(index + 1, [
        ...current,
        { dimensionId: key, optionId: val },
      ]),
    )
  }

  return generateProduct(0, [])
}

export const enrichDimensionBreakdowns = (
  dimensions: ConsolidatedDimension[],
  breakdowns: DimensionBreakdownValue[],
) => {
  const dimensionsById = _.keyBy(dimensions, (dimension) => dimension.id)

  return breakdowns.map((breakdown) => {
    const dimension = dimensionsById[breakdown.dimensionId]
    if (!dimension) {
      throw new Error(`Dimension ${breakdown.dimensionId} not found`)
    }

    const option = dimension.options.find(
      (option) => option.id === breakdown.optionId,
    )
    if (!option) {
      throw new Error(`Option ${breakdown.optionId} not found`)
    }

    return {
      ...breakdown,
      dimensionType: dimension.dimensionType,
      dimensionLabel: dimension.label,
      optionLabel: option.label,
    }
  })
}
