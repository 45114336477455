import "./reset.css"
import "@mantine/core/styles.css"
import "@mantine/dates/styles.css"
import "@mantine/notifications/styles.css"
import "@mantine/charts/styles.css"

import "dayjs/locale/fr"
import "dayjs/locale/en"

import { ClerkProvider, ClerkLoaded, ClerkLoading } from "@clerk/clerk-react"
import { MantineProvider } from "@mantine/core"
import { DatesProvider } from "@mantine/dates"
import { Notifications } from "@mantine/notifications"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { useTranslation } from "react-i18next"
import { RouterProvider } from "react-router-dom"

import { Locale } from "@kiosk/i18n"

import { theme } from "@kiosk/front/assets/theme"
import { CLERK_THEME } from "@kiosk/front/assets/theme/clerk"
import { DynamicSplash, StaticSplash } from "@kiosk/front/components/Splash"
import { queryClient } from "@kiosk/front/lib/queryClient"
import { router } from "@kiosk/front/pages/router"
import { CLERK_LANGUAGES } from "@kiosk/front/utils/constants"

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

function App() {
  const { i18n } = useTranslation()

  if (!PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key")
  }

  return (
    <ClerkProvider
      appearance={CLERK_THEME}
      publishableKey={PUBLISHABLE_KEY}
      localization={CLERK_LANGUAGES[i18n.language as Locale]}
    >
      <ClerkLoading>
        <ClerkLoading>
          <StaticSplash />
        </ClerkLoading>
      </ClerkLoading>
      <ClerkLoaded>
        <MantineProvider theme={theme}>
          <Notifications />
          <DatesProvider settings={{ locale: i18n.language }}>
            <QueryClientProvider client={queryClient}>
              <DynamicSplash />
              <RouterProvider router={router} />
              <ReactQueryDevtools />
            </QueryClientProvider>
          </DatesProvider>
        </MantineProvider>
      </ClerkLoaded>
    </ClerkProvider>
  )
}

export default App
