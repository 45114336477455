import { Box, Group, Text, ThemeIcon } from "@mantine/core"
import { DateInput as MantineDateInput, DateInputProps } from "@mantine/dates"

import { TablerIconComponent } from "@kiosk/front/utils/icon"

type Props = DateInputProps & {
  icon: TablerIconComponent
}

export const DateInput = ({ label, icon: Icon, ...dateInputProps }: Props) => {
  return (
    <Box
      p={10}
      style={(theme) => ({
        border: `1px solid ${theme.colors.gray[2]}`,
        borderRadius: 6,
      })}
    >
      <Group gap={12}>
        <ThemeIcon
          size="lg"
          color="gray.5"
          bg="gray.0"
          variant="light"
          radius={6}
        >
          <Icon />
        </ThemeIcon>
        <Text c="gray.6" mr={4}>
          {label}:
        </Text>
        <MantineDateInput {...dateInputProps} variant="unstyled" w={120} />
      </Group>
    </Box>
  )
}
