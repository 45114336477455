import { useQuery } from "@tanstack/react-query"

import { TaskResponses } from "@kiosk/types/endpoints/tasks"

import { apiClient } from "@kiosk/front/lib/apiClient"

import { taskKeys } from "./taskKeys"

export const getTaskOwners = async (): Promise<TaskResponses.GetOwners> => {
  const response = await apiClient.get<TaskResponses.GetOwners>("/tasks/owners")
  return response.data
}

export const useTaskOwnersQuery = () => {
  return useQuery({
    queryKey: taskKeys.owners(),
    queryFn: getTaskOwners,
  })
}
