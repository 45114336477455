import {
  ActionIcon,
  Box,
  Button,
  Drawer,
  Group,
  Stack,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconPlus, IconTrash } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import {
  dimensionOptionsSchema,
  EditDimensionForm,
} from "@kiosk/types/endpoints/dimensions"

import { useEditTypedDimensionMutation } from "@kiosk/front/api/dimensions/queries"
import { TypedDimension } from "@kiosk/front/api/dimensions/types"

type DrawerProps = {
  dimension?: TypedDimension
  onClose: () => void
}

type FormProps = DrawerProps & {
  dimension: TypedDimension
}

const _EditDimensionDrawer = ({ dimension, onClose }: FormProps) => {
  const { t } = useTranslation("dimensions")

  const form = useForm<EditDimensionForm>({
    initialValues: {
      dimensions: dimension.options.length
        ? dimension.options.map((option) => ({
            id: option.id,
            label: option.label,
            isNew: false,
            isDeleted: false,
          }))
        : [{ isNew: true, label: "", isDeleted: false }],
    },
    validate: zodResolver(dimensionOptionsSchema),
  })

  const { mutateAsync: editDimension, isPending } =
    useEditTypedDimensionMutation(dimension.id, onClose)

  return (
    <Stack h="100%" gap={32}>
      <Drawer.Header
        p={24}
        style={{ borderBottom: "1px solid var(--mantine-color-gray-3)" }}
      >
        <Drawer.Title fz="lg" fw={600} c="black">
          {dimension.label}
        </Drawer.Title>
        <Drawer.CloseButton />
      </Drawer.Header>
      <Stack h="100%" justify="space-between">
        <Drawer.Body px={24}>
          <form
            id="add-user"
            onSubmit={form.onSubmit((values) => editDimension(values))}
          >
            <Stack>
              {form.values.dimensions.map((dimension, index) => {
                if (dimension.isDeleted) return null
                return (
                  <Group
                    w="100%"
                    wrap="nowrap"
                    justify="space-between"
                    key={`dimension-${index}`}
                    style={{
                      borderBottom: "1px solid var(--mantine-color-gray-3)",
                    }}
                  >
                    <TextInput
                      w="90%"
                      variant="unstyled"
                      placeholder={t("drawer.fields.labelPlaceholder")}
                      {...form.getInputProps(`dimensions.${index}.label`)}
                    />
                    <ActionIcon
                      size="lg"
                      color="gray.5"
                      variant="transparent"
                      onClick={() => {
                        if (!dimension.isNew) {
                          form.setFieldValue(
                            `dimensions.${index}.isDeleted`,
                            true,
                          )
                        } else {
                          form.removeListItem("dimensions", index)
                        }
                      }}
                    >
                      <IconTrash size={20} />
                    </ActionIcon>
                  </Group>
                )
              })}
              <Box
                style={{
                  borderBottom: "1px solid var(--mantine-color-gray-3)",
                }}
              >
                <Button
                  miw={100}
                  ml={-20}
                  variant="transparent"
                  color="gray.6"
                  leftSection={<IconPlus color="green" />}
                  onClick={() =>
                    form.insertListItem("dimensions", {
                      label: "",
                      isNew: true,
                      isDeleted: false,
                    })
                  }
                >
                  {t("drawer.actions.newMember")}
                </Button>
              </Box>
            </Stack>
          </form>
        </Drawer.Body>
        <Group
          style={{ borderTop: "1px solid var(--mantine-color-gray-3)" }}
          gap={10}
          p={24}
          justify="flex-end"
        >
          <Button variant="outline" color="gray" onClick={onClose}>
            {t("drawer.actions.cancel")}
          </Button>
          <Button
            form="add-user"
            type="submit"
            loading={isPending}
            disabled={!form.isValid()}
          >
            {t("drawer.actions.save")}
          </Button>
        </Group>
      </Stack>
    </Stack>
  )
}

export const EditDimensionDrawer = ({ dimension, onClose }: DrawerProps) => {
  return (
    <Drawer.Root
      position="right"
      size="md"
      opened={!!dimension}
      onClose={onClose}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        {dimension && (
          <_EditDimensionDrawer dimension={dimension} onClose={onClose} />
        )}
      </Drawer.Content>
    </Drawer.Root>
  )
}
