import {
  Stack,
  Text,
  PasswordInput as MantinePasswordInput,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { ChangeEventHandler, FocusEventHandler, ReactNode } from "react"
import { useTranslation } from "react-i18next"

interface Props {
  onChange?: ChangeEventHandler
  value?: string
  checked?: boolean
  error?: ReactNode
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
  label: string
}

export const PasswordInput = ({ label, ...rest }: Props) => {
  const [visible, { toggle }] = useDisclosure(false)
  const { t } = useTranslation(["common"])

  return (
    <Stack gap={8}>
      <MantinePasswordInput
        label={label}
        placeholder={t("fields.passwordPlaceholder")}
        visible={visible}
        onVisibilityChange={toggle}
        {...rest}
      />
      <Text c="gray.5" fz="sm">
        {t("fields.passwordNote")}
      </Text>
    </Stack>
  )
}
