import { Flex, Space } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import {
  IconCalendar,
  IconFlagExclamation,
  IconMail,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import { pick } from "lodash"
import { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/types/endpoints/surveys"

import {
  UpdateSurveyBody,
  updateSurveySchema,
} from "@kiosk/shared/schemas/surveys"

import { useGetSurveyQuery } from "@kiosk/front/api/surveys/getSurvey"
import { useUpdateSurveyMutation } from "@kiosk/front/api/surveys/updateSurvey"
import { BackButton } from "@kiosk/front/components/BackButton"
import { DateInput } from "@kiosk/front/components/DateInput"
import { EditableTitle } from "@kiosk/front/components/EditableTitle"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { QuestionsList } from "@kiosk/front/pages/Surveys/EditSurvey/QuestionsList"
import { isSurveyStarted } from "@kiosk/front/pages/Surveys/utils"
import { routes } from "@kiosk/front/utils/constants"

type Props = {
  survey: SurveyResponses.Survey
  surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
}

const EditSurveyForm = ({ survey, surveyQuestions }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")

  const form = useForm<UpdateSurveyBody>({
    mode: "controlled",
    initialValues: pick(survey, [
      "title",
      "launchDate",
      "followUpDate",
      "dueDate",
    ]),
    validate: zodResolver(updateSurveySchema),
  })

  const { mutateAsync: updateSurvey } = useUpdateSurveyMutation(survey.id)

  const nextDays = useMemo(() => {
    const today = new Date()
    return [
      dayjs(today).add(1, "day").toDate(),
      dayjs(today).add(2, "days").toDate(),
      dayjs(today).add(3, "days").toDate(),
    ]
  }, [])

  const handleSubmit = useCallback(
    (url: string) => {
      return (values: UpdateSurveyBody) => {
        updateSurvey(values)
        navigate(url)
      }
    },
    [updateSurvey, navigate],
  )

  const getOnNavigate = useCallback(
    (url: string) => form.onSubmit(handleSubmit(url)),
    [form, handleSubmit],
  )

  return (
    <form>
      <Flex direction="column" gap={30}>
        <Flex align="center" gap={16}>
          <BackButton onClick={getOnNavigate(routes.SURVEYS.path)} />
          <EditableTitle
            {...form.getInputProps("title")}
            readOnly={isSurveyStarted(survey)}
          />
        </Flex>
        <Flex gap={24}>
          <DateInput
            icon={IconCalendar}
            label={t("labels.launchDate")}
            placeholder={t("labels.launchDate")}
            minDate={nextDays[0]}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("launchDate")}
          />
          <DateInput
            icon={IconMail}
            label={t("labels.followUpDate")}
            placeholder={t("labels.followUpDate")}
            minDate={nextDays[1]}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("followUpDate")}
          />
          <DateInput
            icon={IconFlagExclamation}
            label={t("labels.dueDate")}
            placeholder={t("labels.dueDate")}
            minDate={nextDays[2]}
            readOnly={isSurveyStarted(survey)}
            {...form.getInputProps("dueDate")}
          />
        </Flex>
      </Flex>
      <Space h="xl" />
      <QuestionsList
        surveyQuestions={surveyQuestions}
        survey={survey}
        getOnNavigate={getOnNavigate}
      />
    </form>
  )
}

export const EditSurvey = () => {
  const { id } = useParams() as { id: string }
  const getSurveyQuery = useGetSurveyQuery(id)

  return (
    <PageLayout>
      <QueryWrapper query={getSurveyQuery}>
        {({ data }) => <EditSurveyForm {...data} />}
      </QueryWrapper>
    </PageLayout>
  )
}
