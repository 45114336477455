import _ from "lodash"
import { ZodSchema } from "zod"

interface PasswordConfirmation {
  password: string
  confirmPassword: string
}

/**
 * A function that adds a refinement to a Zod schema.
 *
 * @param schema The schema to add the refinement to.
 * @returns The updated schema with the added refinement.
 */
// TODO: let's create a standalone passwordSchema and extend schemas where it's used with it
export const passwordConfirmationRefinement = <T extends PasswordConfirmation>(
  schema: ZodSchema<T>,
): ZodSchema<T> => {
  return schema.refine(
    ({ confirmPassword, password }) => confirmPassword === password,
    {
      path: ["confirmPassword"],
      message: "Passwords do not match.",
    },
  )
}

export const round = (x: number, n: number): number => Number(x.toFixed(n))

export type Option<T> = T | undefined

export const isDefined = <T>(x: Option<T>): x is T => x !== undefined

export const isNotNull = <T>(x: T | null): x is T => x !== null

export const isNotNil = <T>(x: Option<T> | null): x is T =>
  isDefined(x) && isNotNull(x)

export const mapOption = <I, O>(o: Option<I>, fn: (x: I) => O) =>
  isDefined(o) ? fn(o) : undefined

export const increment = (n: number): number => n + 1

type Nilable<T> = T extends object
  ? { [K in keyof T]: Nilable<T[K]> }
  : T | null | undefined

type Pruned<T> =
  T extends Array<infer U>
    ? Array<Exclude<Pruned<U>, undefined>>
    : T extends object
      ? { [K in keyof T]: Exclude<Pruned<T[K]>, undefined> }
      : T

export function pruneNilValues<T>(it: Nilable<T>): Pruned<T> | undefined {
  if (_.isArray(it)) {
    return it.map(pruneNilValues).filter((v) => v !== undefined) as Pruned<T>
  }

  if (_.isObject(it)) {
    const pruned = _.chain(it as Record<string, unknown>)
      .mapValues(pruneNilValues)
      .omitBy(_.isUndefined)
      .value()
    return _.isEmpty(pruned) ? undefined : (pruned as Pruned<T>)
  }

  if (_.isNull(it)) return undefined
  if (_.isUndefined(it)) return undefined
  return it as Pruned<T>
}
