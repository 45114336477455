import { z } from "zod"

import { i18n } from "@kiosk/i18n"

export const TASK_STATUS = {
  TODO: "TODO",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
} as const

export const createTaskSchema = z.object({
  name: z.string().min(1, { message: i18n.t("task:validation.required") }),
  categoryId: z.string(),
  description: z.string().optional(),
  stakeholders: z.string().optional(),
  deliverables: z.string().optional(),
  ownerId: z.string().optional(),
  status: z.nativeEnum(TASK_STATUS).optional(),
  dueDate: z.date().optional(),
})

export type CreateTaskBody = z.infer<typeof createTaskSchema>

export const updateTaskSchema = createTaskSchema.partial().extend({
  deletedAt: z.date().optional(),
})

export type UpdateTaskBody = z.infer<typeof updateTaskSchema>
