import { ActionIcon, Group, Menu } from "@mantine/core"
import {
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconTrash,
} from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { BackButton } from "@kiosk/front/components/BackButton"
import { EditableTitle } from "@kiosk/front/components/EditableTitle"
import { routes } from "@kiosk/front/utils/constants"

export const TablePageNavigation = () => {
  const { t } = useTranslation("tables")
  const navigate = useNavigate()

  const [tableName, setTableName] = useState(t("newTableName"))

  return (
    <Group justify="space-between">
      <Group>
        <BackButton onClick={() => navigate(routes.SOURCES.path)} />
        <EditableTitle value={tableName} onChange={setTableName} />
      </Group>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon variant="light" color="black" h={42} w={42}>
            <IconDotsVertical height={20} width={20} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<IconDownload height={18} />}>
            {t("actions.export")}
          </Menu.Item>
          <Menu.Item leftSection={<IconCopy height={18} />}>
            {t("actions.duplicate")}
          </Menu.Item>
          <Menu.Item leftSection={<IconTrash height={18} />}>
            {t("actions.delete")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}
