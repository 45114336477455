import {
  ActionIcon,
  Avatar,
  Group,
  Menu,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core"
import {
  IconDotsVertical,
  IconCircleCheckFilled,
  IconCircleXFilled,
} from "@tabler/icons-react"
import { UseMutateFunction } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { AccountResponses } from "@kiosk/types/endpoints/account"
import { UserRole, User } from "@kiosk/types/prisma-client"
import { USER_ROLES } from "@kiosk/types/user"

import { Column } from "@kiosk/front/components/generic/types"
import { getInitials } from "@kiosk/front/utils/helpers"

export const useUsersColumns = (
  showDeleteModal: (value: string) => void,
  impersonateUserMutation: UseMutateFunction<
    AccountResponses.Impersonation,
    Error,
    string,
    unknown
  >,
): Array<Column<User>> => {
  const { t } = useTranslation()
  return [
    {
      colId: "firstName",
      key: "name",
      title: t("columns.name"),
      render: (firstName, { lastName, email, avatarUrl }) => (
        <Group gap={14}>
          <Avatar color="green" src={avatarUrl}>
            {getInitials(firstName, lastName)}
          </Avatar>
          <Stack gap={6}>
            <Text c="gray.7" fw={500} m={0}>{`${firstName} ${lastName}`}</Text>
            <Text c="gray.7" m={0}>
              {email}
            </Text>
          </Stack>
        </Group>
      ),
    },
    {
      colId: "role",
      title: t("columns.role"),
      key: "role",
      render: (role: UserRole) =>
        role === USER_ROLES.COMPANY_CONTRIBUTOR
          ? t("roles.companyContributor")
          : t("roles.companyAdmin"),
    },
    // {
    //   colId: "isSignedUp",
    //   title: i18n.t("columns.verified"),
    //   key: "isSignedUp",

    //   render: (isSignedUp) => (
    //     <Group gap={14}>
    //       {isSignedUp ? (
    //         <ThemeIcon variant="white" color="green.5">
    //           <IconCircleCheckFilled />
    //         </ThemeIcon>
    //       ) : (
    //         <ThemeIcon variant="white" color="red.8">
    //           <IconCircleXFilled />
    //         </ThemeIcon>
    //       )}
    //     </Group>
    //   ),
    // },
    {
      colId: "isSignedUp",
      title: t("columns.verified"),
      key: "isSignedUp",

      render: (isSignedUp) => (
        <Group gap={14}>
          {isSignedUp ? (
            <ThemeIcon variant="white" color="green.5">
              <IconCircleCheckFilled />
            </ThemeIcon>
          ) : (
            <ThemeIcon variant="white" color="red.8">
              <IconCircleXFilled />
            </ThemeIcon>
          )}
        </Group>
      ),
    },
    {
      colId: "id",
      title: "",
      key: "actions",
      render: (id, { clerkId }) => (
        <Menu offset={6} position="right">
          <Menu.Target>
            <ActionIcon variant="white" color="black">
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item onClick={() => impersonateUserMutation(clerkId)}>
              {t("impersonateUser")}
            </Menu.Item>
            <Menu.Item onClick={() => showDeleteModal(id)}>
              {t("delete")}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      ),
    },
  ]
}
