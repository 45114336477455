import { Button, Group, Select, Stack, Title } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import {
  useExportDashboardToCSVMutation,
  useListDashboardDataPointsQuery,
} from "@kiosk/front/api/dashboard/queries"
import { DashboardCategory } from "@kiosk/front/api/dashboard/types"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { config } from "@kiosk/front/config"

import { CategoryDashboard } from "./CategoryDashboard"
import { EmptyDashboard } from "./EmptyDashboard"

interface DashboardContentProps {
  dashboardCategories: DashboardCategory[]
}
const DashboardContent = (props: DashboardContentProps) => {
  const user = useUser()
  const { t } = useTranslation(["dashboard"])

  const { mutateAsync: exportToCSV, isPending } =
    useExportDashboardToCSVMutation()

  return (
    <PageLayout contained>
      <Stack gap={32} mb={32} h="100%">
        <Group justify="space-between">
          <Title m={0} mb={12}>
            {t("title", { firstName: user.firstName })}
          </Title>
          <Group>
            {config.isDemo ? (
              <Group>
                <Select
                  w={150}
                  data={["Toute zone", "France", "Espagne", "Italie"]}
                  defaultValue="Toute zone"
                />
                <Select
                  w={150}
                  data={["Toute filiale", "RH", "Tech", "Opérations"]}
                  defaultValue="Toute filiale"
                />
                <Select
                  w={150}
                  data={["Tout temps", "2024", "2023", "2022"]}
                  defaultValue="Tout temps"
                />
              </Group>
            ) : null}
            <Button
              leftSection={<IconDownload />}
              onClick={() => exportToCSV()}
              loading={isPending}
            >
              {t("actions.export")}
            </Button>
          </Group>
        </Group>
        {props.dashboardCategories ? (
          <Stack gap={48}>
            {_.sortBy(props.dashboardCategories, (c) => c.order).map(
              (category) => (
                <CategoryDashboard key={category.id} category={category} />
              ),
            )}
          </Stack>
        ) : (
          <EmptyDashboard />
        )}
      </Stack>
    </PageLayout>
  )
}

export const Dashboard = () => {
  const dataPointsQuery = useListDashboardDataPointsQuery()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={dataPointsQuery}>
        {({ data: dashboardCategories }) => (
          <DashboardContent dashboardCategories={dashboardCategories} />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
