import { DashboardCategory } from "@kiosk/front/api/dashboard/types"

export const FAKE_DASHBOARD_DATA: DashboardCategory[] = [
  {
    id: "c1",
    name: "🌱 Environment",
    order: 1,
    baseColor: "emerald.0",
    topics: [
      {
        id: "t11",
        name: "Emissions",
        dataPoints: [
          {
            type: "single-value",
            id: "totalGhg",
            label: "Total GHG",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 45,
          },
          {
            type: "single-value",
            id: "totalGhgByNetRevenue",
            label: "Total GHG by net revenue",
            unit: "tCO2e/€",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 482519,
          },
          {
            type: "dimensioned",
            id: "totalGhgBySector",
            label: "Total GHG by activity sector",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            values: [
              {
                value: 12000,
                dimensions: [
                  {
                    dimensionType: "explicit",
                    dimensionLabel: "Activity Sector",
                    optionLabel: "Agriculture",
                    dimensionId: "d11",
                    optionId: "o11",
                  },
                ],
              },
              {
                value: 17450,
                dimensions: [
                  {
                    dimensionType: "explicit",
                    dimensionLabel: "Activity Sector",
                    optionLabel: "Industry",
                    dimensionId: "d11",
                    optionId: "o12",
                  },
                ],
              },
              {
                value: 8900,
                dimensions: [
                  {
                    dimensionType: "explicit",
                    dimensionLabel: "Activity Sector",
                    optionLabel: "Services",
                    dimensionId: "d11",
                    optionId: "o12",
                  },
                ],
              },
            ],
          },
          {
            type: "single-value",
            id: "scope1Emissions",
            label: "Scope 1 Emissions",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 3456,
          },
          {
            type: "single-value",
            id: "scope2Emissions",
            label: "Scope 2 Emissions",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: null,
          },
          {
            type: "single-value",
            id: "scope3Emissions",
            label: "Scope 3 Emissions",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 10008,
          },
        ],
      },
      {
        id: "t12",
        name: "Energy consumption",
        dataPoints: [
          {
            type: "single-value",
            id: "totalEnergyConsumption",
            label: "Total energy consumption",
            unit: "tCO2e",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 2.3,
          },
          {
            type: "single-value",
            id: "totalEnergyConsumptionByNetRevenue",
            label: "Total energy consumption by net revenue",
            unit: "tCO2e/€",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 0.888,
          },
        ],
      },
    ],
  },
  {
    id: "c2",
    name: "🤝 Social",
    order: 2,
    baseColor: "yellow.0",
    topics: [
      {
        id: "t21",
        name: "Workforce",
        dataPoints: [
          {
            type: "single-value",
            id: "fatalitiesFromWorkRelatedInjuries",
            label: "Fatalities from work-related injuries",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 14,
          },
          {
            type: "single-value",
            id: "numberOfWorkRelatedAccidents",
            label: "Number of work-related accidents",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 24,
          },
          {
            type: "single-value",
            id: "daysLostToWorkRelatedInjuries/fatalities",
            label: "Days lost to work-related injuries/fatalities",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 67,
          },
          {
            type: "single-value",
            id: "maleFemalePayGap",
            label: "Male-female pay gap",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 23,
          },
          {
            type: "single-value",
            id: "ratioOfTheTotalAnnualCompensationRatioOfHighestPaidIndividual",
            label:
              "Ratio of the total annual compensation ratio of highest paid individual",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 10000,
          },
          {
            type: "single-value",
            id: "costOfAnEmployeeContractTermination",
            label: "Cost of an employee contract termination",
            unit: "€",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 2337,
          },
        ],
      },
    ],
  },
  {
    id: "c3",
    name: "🏢 Governance",
    order: 3,
    baseColor: "blue.0",
    topics: [
      {
        id: "t31",
        name: "Employees",
        dataPoints: [
          {
            type: "single-value",
            id: "totalNumberOfEmployees",
            label: "Total number of employees",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 857,
          },
          {
            type: "single-value",
            id: "totalRevenue",
            label: "Total revenue",
            unit: "€",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 1777300000,
          },
          {
            type: "single-value",
            id: "revenueFromFossilFuel",
            label: "Revenue from fossil fuel",
            unit: "€",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 1450000,
          },
          {
            type: "single-value",
            id: "nonExecAtBoard",
            label: "Non exec at board",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 12,
          },
          {
            type: "single-value",
            id: "womenAtBoard",
            label: "Women at board",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 4,
          },
          {
            type: "single-value",
            id: "independants",
            label: "Independants",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: null,
          },
          {
            type: "single-value",
            id: "variableCommission",
            label: "Variable commission",
            unit: "%",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: null,
          },
          {
            type: "single-value",
            id: "representantsNumber",
            label: "Representants number",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 124,
          },
          {
            type: "single-value",
            id: "convictionsForAntiCorruptionLaws",
            label: "Convictions for anti-corruption laws",
            unit: "",
            numberType: "decimal",
            isMandatoryInDashboard: true,
            value: 0,
          },
        ],
      },
    ],
  },
]
