import { Stack } from "@mantine/core"
import { useState } from "react"

import { useTasksQuery } from "@kiosk/front/api/tasks/tasksQuery"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { ActionsBar } from "@kiosk/front/pages/Tasks/ActionsBar"

import { ProgressBarAndInfo } from "./ProgressBarAndInfo"
import { TaskTable } from "./TaskTable"

export const Tasks = () => {
  const [ownerId, setOwnerId] = useState<undefined | string>(undefined)
  const tasksQuery = useTasksQuery({ ownerId })

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={tasksQuery}>
        {({
          data: { data: categories, totalTasksCount, taskCountPerStatus },
        }) => (
          <Stack gap={32}>
            <ProgressBarAndInfo
              totalTasksCount={totalTasksCount ?? 0}
              tasksByStatus={taskCountPerStatus ?? []}
            />
            <ActionsBar ownerId={ownerId} setOwnerId={setOwnerId} />
            {categories.map(({ tasks, name, id }) =>
              typeof ownerId === "undefined" || tasks.length > 0 ? (
                <TaskTable
                  ownerId={ownerId}
                  key={id}
                  categoryId={id}
                  title={name}
                  data={tasks}
                />
              ) : null,
            )}
          </Stack>
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
