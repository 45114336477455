import {
  Button,
  Group,
  Input,
  Menu,
  Select,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronRight, IconPlus, IconSearch } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Question } from "@kiosk/types/data/dato"
import { SourcesResponses } from "@kiosk/types/endpoints/sources"
import { SourceType } from "@kiosk/types/sources"

import { useListSourcesQuery } from "@kiosk/front/api/sources/index"
import { Table } from "@kiosk/front/components/generic/index"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { AddQuestionsModal } from "@kiosk/front/components/questions/AddQuestionsModal"
import { config } from "@kiosk/front/config"
import { SOURCE_TYPES_INFO } from "@kiosk/front/pages/Sources/utils"

import { EmptySources } from "./EmptySources"
import NewFileModal from "./Files/NewFileModal"
import { BackToYouModal } from "./Integrations/IntegrationRequestSentModal"
import { NewIntegrationModal } from "./Integrations/NewIntegrationModal"
import { SourceTypeItem } from "./SourceTypeItem"

type Props = {
  sources: SourcesResponses.Source[]
}

const SourcesList = ({ sources }: Props) => {
  const { t } = useTranslation("sources")
  const navigate = useNavigate()

  const [search, setSearch] = useState("")
  const [typeFilter, setTypeFilter] = useState<SourceType>()

  const filteredSources = useMemo(() => {
    let filteredData = sources

    if (search) {
      filteredData = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(search.toLowerCase())
      })
    }

    if (typeFilter) {
      filteredData = filteredData.filter((item) => {
        return item.type === typeFilter
      })
    }

    return filteredData
  }, [search, sources, typeFilter])

  const columns = useMemo(
    () => [
      {
        colId: "name",
        key: "name",
        title: t("columns.name"),
      },
      {
        colId: "type",
        key: "type",
        title: t("columns.sourceType"),
        render: (sourceType: SourceType) => (
          <SourceTypeItem sourceType={sourceType} />
        ),
      },
      {
        colId: "lastUpdate",
        key: "lastUpdate",
        title: t("columns.lastUpdate"),
        render: (lastUpdate: Date) => dayjs(lastUpdate).format("LLL"),
      },
      {
        key: "arrow",
        colId: "arrow",
        title: "",
        width: 20,
        render: () => (
          <ThemeIcon c="black">
            <IconChevronRight />
          </ThemeIcon>
        ),
      },
    ],
    [t],
  )

  const handleRowClick = (source: SourcesResponses.Source) => {
    if (source.link.includes("http")) {
      // External link
      window.open(source.link, "_blank")
    } else {
      navigate(source.link)
    }
  }

  // When clicking on «add integration»:
  // `NewIntegrationModal` is opened, where users can select their integration
  // `BackToYouModal` is opened when the user clicks on «Request»
  const [
    newIntegrationOpened,
    { open: openNewIntegration, close: closeNewIntegration },
  ] = useDisclosure(false)
  const [backToYouOpened, { open: openBackToYou, close: closeBackToYou }] =
    useDisclosure(false)

  const [
    newTableQuestionsSelectOpened,
    { open: openNewTableQuestionsSelect, close: closeNewTableQuestionsSelect },
  ] = useDisclosure(false)

  const [newFileOpened, { open: openNewFile, close: closeNewFile }] =
    useDisclosure(false)

  return (
    <Stack gap={32} mb={32}>
      <Group justify="space-between">
        <Group gap={24}>
          <Input
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            placeholder={t("placeholders.search")}
            leftSection={<IconSearch />}
            w={400}
          />
          <Select
            clearable
            value={typeFilter}
            onChange={(value) => setTypeFilter(value as SourceType)}
            placeholder={t("placeholders.filter")}
            data={SOURCE_TYPES_INFO.map((sourceType) => ({
              value: sourceType.key,
              label: sourceType.name,
            }))}
            renderOption={({ option }) => (
              <SourceTypeItem sourceType={option.value as SourceType} />
            )}
          />
        </Group>
        <NewFileModal opened={newFileOpened} onClose={closeNewFile} />
        <BackToYouModal opened={backToYouOpened} onClose={closeBackToYou} />
        <NewIntegrationModal
          opened={newIntegrationOpened}
          onClose={closeNewIntegration}
          onRequest={() => {
            closeNewIntegration()
            openBackToYou()
          }}
        />
        <Menu>
          <Menu.Target>
            <Button leftSection={<IconPlus />}>{t("actions.new")}</Button>
          </Menu.Target>
          <Menu.Dropdown>
            {SOURCE_TYPES_INFO.map((sourceType) => (
              <Menu.Item
                key={sourceType.key}
                disabled={config.isDemo ? false : !sourceType.isActive}
                onClick={() => {
                  if (sourceType.ctaLink) {
                    navigate(sourceType.ctaLink)
                    return
                  }
                  switch (sourceType.key) {
                    case "TABLE":
                      openNewTableQuestionsSelect()
                      return
                    case "INTEGRATION":
                      openNewIntegration()
                      return
                    case "FILE":
                      openNewFile()
                      return
                    default:
                      return
                  }
                }}
              >
                <Group gap={6}>
                  <sourceType.icon height={18} />
                  <Text>{`${sourceType.cta} ${!config.isDemo && !sourceType.isActive ? `(${t("comingSoon")})` : ""}`}</Text>
                </Group>
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>

      {sources.length ? (
        <>
          <Table
            columns={columns}
            dataSource={filteredSources}
            onRowClick={handleRowClick}
            withColumnBorders
          />
          <AddQuestionsModal
            opened={newTableQuestionsSelectOpened}
            onClose={closeNewTableQuestionsSelect}
            onSave={(questions: Question[]) => {
              navigate("/sources/tables", {
                state: questions.map((question, id) => ({
                  id,
                  indicator: question.label,
                })),
              })
            }}
          />
        </>
      ) : (
        <EmptySources />
      )}
    </Stack>
  )
}

export const Sources = () => {
  const sourcesQuery = useListSourcesQuery()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={sourcesQuery} allowEmptyArray={true}>
        {({ data }) => <SourcesList sources={data} />}
      </QueryWrapper>
    </PageLayout>
  )
}
