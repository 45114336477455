import { rem, Stepper, StepperProps } from "@mantine/core"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { GeoDimensionStep } from "@kiosk/front/pages/Onboarding/GeoDimensionStep"
import { UploadFileStep } from "@kiosk/front/pages/Onboarding/UploadFileStep"
import { UserCreationStep } from "@kiosk/front/pages/Onboarding/UserCreationStep"

interface Props extends Omit<StepperProps, "children" | "onStepClick"> {
  setActive: Dispatch<SetStateAction<number>>
}

export const OnboardingStepper = ({ setActive, active, ...rest }: Props) => {
  const { t } = useTranslation("common")

  return (
    <Stepper
      active={active}
      w="100%"
      styles={{
        root: {
          height: "100%",
          display: "flex",
          gap: rem(72),
          flex: "1 1 0%",
          flexDirection: "column",
          alignItems: "center",
        },
        stepBody: {
          position: "absolute",
          top: rem(52),
          width: 150,
          right: "-125%",
          textAlign: "center",
          marginInlineStart: 0,
        },
        stepLabel: {
          fontSize: 14,
          color: "black",
        },
        step: {
          position: "relative",
          padding: 0,
        },
        stepIcon: {
          borderWidth: rem(2),
        },
        separator: {
          marginLeft: rem(-2),
          marginRight: rem(-2),
          height: rem(10),
          background: "var(--mantine-color-gray-1)",
        },
        content: {
          paddingTop: 0,
          display: "flex",
          flex: "1 1 0%",
          width: "100%",
        },
        steps: {
          width: rem(506),
          paddingLeft: rem(48),
          paddingRight: rem(48),
        },
      }}
      {...rest}
    >
      <Stepper.Step step={1} label={t("onboarding.password")}>
        <UserCreationStep setActive={setActive} />
      </Stepper.Step>
      <Stepper.Step step={2} label={t("onboarding.geoStructure")}>
        <GeoDimensionStep setActive={setActive} />
      </Stepper.Step>
      <Stepper.Step step={3} label={t("onboarding.firstUpload")}>
        <UploadFileStep />
      </Stepper.Step>
    </Stepper>
  )
}
