interface FollowUpQuestion {
  datoId: string
}

interface Esrs {
  datoId: string
  category: string
  code: string
  name: string
  followUpQuestion: FollowUpQuestion
  iro2EnumLabel: string
}

interface DisclosureRequirement {
  datoId: string
  nonMaterialTopicsQuestionId: string
}

interface Config {
  esrs2: string
  disclosureRequirement: DisclosureRequirement
  esrs: Esrs[]
}

const categoryIds = {
  general: "NL0QsS8FRRmIfh8Hg0dy1w",
  environment: "WrBHVgFBROSggUP6u-EkFg",
  social: "BZV-zYCNRnqMeNu8sNo2yQ",
  governance: "a9omkOsaR6mGdgzpXzhTIQ",
}

const config: Config = {
  esrs2: "aJIHBJ90RcWvwbOzJE44Dg",
  disclosureRequirement: {
    datoId: "bHZEVI1JTCeVQwSawlAJxw",
    nonMaterialTopicsQuestionId: "HOJ-9hkESlaSbCn5qS_ikA",
  },
  esrs: [
    {
      datoId: "Pt_STwJ1Rk6rOF79A0Od5Q",
      category: categoryIds.environment,
      code: "ESRS-E1",
      name: "Climate change",
      iro2EnumLabel: "ESRS E1 Climate Change",
      followUpQuestion: {
        datoId: "InF2ccxATuu5uwYas6WRGg",
      },
    },
    {
      datoId: "TkNxhZpTQuiL2GhzoUJRkg",
      category: categoryIds.environment,
      code: "ESRS-E2",
      name: "Pollution",
      iro2EnumLabel: "ESRS E2 Pollution",
      followUpQuestion: {
        datoId: "JTg696ITTHW5qcEG6B4-CA",
      },
    },
    {
      datoId: "c9HgBl7TTumBTn8jlVyVcg",
      category: categoryIds.environment,
      code: "ESRS-E3",
      name: "Water",
      iro2EnumLabel: "ESRS E3 Water and marine resources",
      followUpQuestion: {
        datoId: "bTJAdxwdTzqecC7w1otrgw",
      },
    },
    {
      datoId: "eI9YKAqkT4SAbY_t4yya2g",
      category: categoryIds.environment,
      code: "ESRS-E4",
      name: "Biodiversity",
      iro2EnumLabel: "ESRS E4 Biodiversity and ecosystems",
      followUpQuestion: {
        datoId: "Sj_dY5WERUCVLg77Rzimew",
      },
    },
    {
      datoId: "brXWEohOTIWTafBXckLyag",
      category: categoryIds.environment,
      code: "ESRS-E5",
      name: "Circular Economy",
      iro2EnumLabel: "ESRS E5 Resource use and circular economy",
      followUpQuestion: {
        datoId: "Ic8QFnmPToaROogM6p8Crw",
      },
    },
    {
      datoId: "deKYZHoaT-2IUwMmb_LW2Q",
      category: categoryIds.social,
      code: "ESRS-S1",
      name: "Own Workers",
      iro2EnumLabel: "ESRS S1 Own Workforce",
      followUpQuestion: {
        datoId: "H67SHim_TzaxtuYhE4YnkQ",
      },
    },
    {
      datoId: "Y_qmflMnSkaasQzcTSDZGg",
      category: categoryIds.social,
      code: "ESRS-S2",
      name: "Chain Workers",
      iro2EnumLabel: "ESRS S2 Workers in value chain",
      followUpQuestion: {
        datoId: "INgmgOftSQmcu6F4gKup-A",
      },
    },
    {
      datoId: "TmZjY-QpTrSs1a1aFPxyEg",
      category: categoryIds.social,
      code: "ESRS-S3",
      name: "Affected communities",
      iro2EnumLabel: "ESRS S3 Affected communities",
      followUpQuestion: {
        datoId: "LpuxfmrURfOkytoXxDAS4A",
      },
    },
    {
      datoId: "YWWjI4VmS8WQCGQULgYg0w",
      category: categoryIds.social,
      code: "ESRS-S4",
      name: "Consumers and end-users",
      iro2EnumLabel: "ESRS S4 Consumers and end-users",
      followUpQuestion: {
        datoId: "fChbC8T4QoKiT2L77sEcnA",
      },
    },
    {
      datoId: "PZhqLTZnTNeorrF-R4lv3A",
      category: categoryIds.governance,
      code: "ESRS-G1",
      name: "Business conduct",
      iro2EnumLabel: "ESRS G1 Business conduct",
      followUpQuestion: {
        datoId: "XcLNlXAnS6WVX-3joNxX8Q",
      },
    },
  ],
}

export function findEsrsById(id: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.datoId === id)
}

export function findEsrsByCode(code: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.code.match(code))
}

export function findEsrsByIro2Label(label: string): Esrs | undefined {
  return config.esrs.find((esrs) => esrs.iro2EnumLabel === label)
}

export function getEsrsToLabelMap(): Record<string, string> {
  return Object.fromEntries(
    config.esrs.map((esrs) => [esrs.datoId, esrs.iro2EnumLabel]),
  )
}

export default config
