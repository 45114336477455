import { useSignIn, useUser as useClerkUser } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import { AuthResponses } from "@kiosk/types/endpoints/auth"
import { User } from "@kiosk/types/user"

import { accountKeys } from "@kiosk/front/api/account/accountKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

type AuthState =
  | {
      isLoading: true
      isSignedIn: undefined
      isOnboarded: undefined
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: false
      isOnboarded: undefined
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: false
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: true
      user: User
    }
  | {
      isLoading: false
      isSignedIn: true
      isOnboarded: undefined
      user: User
    }

export const useAuthState = (): AuthState => {
  const { isLoaded, isSignedIn, user: clerkUser } = useClerkUser()
  const { setActive: setActiveOrganization } = useSignIn()

  const [isActivatingOrganization, setIsActivatingOrganization] =
    useState(false)

  const {
    data: user,
    isLoading: isMeLoading,
    isError,
    // TODO: type error
  } = useQuery<User | undefined, { error: string }>({
    queryKey: accountKeys.me(clerkUser?.id),
    queryFn: async () => {
      if (!isLoaded || !isSignedIn) return undefined
      const response = await apiClient.get<AuthResponses.Me>("/account/me")
      return response.data
    },
    enabled: isLoaded && isSignedIn,
    retry: 1,
  })

  const clerkOrganizationId = useMemo(
    () =>
      user?.company.clerkId ??
      clerkUser?.organizationMemberships[0]?.organization.id,
    [clerkUser?.organizationMemberships, user],
  )

  useEffect(() => {
    if (setActiveOrganization && clerkOrganizationId) {
      setIsActivatingOrganization(true)
      setActiveOrganization({ organization: clerkOrganizationId }).finally(() =>
        setIsActivatingOrganization(false),
      )
    }
  }, [clerkOrganizationId, setActiveOrganization])

  const isLoading = !isLoaded || isMeLoading || isActivatingOrganization
  if (isLoading)
    return {
      isLoading: true,
      isSignedIn: undefined,
      isOnboarded: undefined,
      user: undefined,
    }
  if (!isSignedIn) {
    return {
      isLoading: false,
      isSignedIn: false,
      isOnboarded: undefined,
      user: undefined,
    }
  }
  if (isError || !user) {
    // Considering for now that an existing Clerk user without a Prisma user means a non-onboarded user.
    // TODO: handle this specific case with error codes
    return {
      isLoading: false,
      isSignedIn: true,
      isOnboarded: false,
      user: undefined,
    }
  }
  if (user && !user.isSignedUp) {
    return {
      isLoading: false,
      isSignedIn: true,
      isOnboarded: undefined,
      user,
    }
  }
  return {
    isLoading,
    isSignedIn: true,
    isOnboarded: true,
    user,
  }
}
