import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { UserSetupAccountBody } from "@kiosk/shared/schemas/users"

import { accountKeys } from "@kiosk/front/api/account/accountKeys"
import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export interface SetupUserAccountPayload {
  userId: string
  data: UserSetupAccountBody
}

export const setupUserAccount = async ({
  userId,
  data,
}: SetupUserAccountPayload) => {
  const response = await apiClient.put(`/users/${userId}/setup-account`, data)
  return response.data
}

export const useSetupUserAccountMutation = (onSuccess: () => void) => {
  const { t } = useTranslation()
  const { user } = useAuthState()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: setupUserAccount,
    onSuccess: async () => {
      if (user && user.companyId) {
        await queryClient.invalidateQueries({
          queryKey: accountKeys.me(user?.clerkId),
        })
        await queryClient.invalidateQueries({
          queryKey: companyKeys.companyUsers(user?.companyId),
        })
      }
      notifications.show({
        title: t("messages.success"),
        message: t("messages.updateUserSuccess"),
        color: "green",
      })
      navigate("/")
      onSuccess()
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
