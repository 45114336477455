import { Button, Select, Stack, Text } from "@mantine/core"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Locale, i18n } from "@kiosk/i18n"

import { updateUser } from "@kiosk/front/api/users/updateUser"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"

export const Language = () => {
  const { t } = useTranslation(["common", "settings"])
  const [selectedLanguage, setSelectedLanguage] = useState<Locale>(
    (localStorage.getItem("i18nextLng") as Locale) ?? "en",
  )

  const user = useUser()

  const languageOptions = [
    { value: "en", label: t("settings:language.english") },
    { value: "fr", label: t("settings:language.french") },
  ]

  const onSave = async () => {
    await i18n.changeLanguage(selectedLanguage)
    await updateUser({ userId: user.id, data: { locale: selectedLanguage } })
    window.location.reload()
  }

  return (
    <PageLayout>
      <Stack align="center">
        <Stack gap={21} w={236}>
          <Text>{t("settings:language.chooseLanguage")}</Text>
          <Select
            allowDeselect={false}
            defaultValue="en"
            value={selectedLanguage}
            data={languageOptions}
            label={t("common:routes.language")}
            onChange={(value) => setSelectedLanguage((value as Locale) ?? "en")}
          />
          <Button onClick={onSave}>{t("common:buttons.save")}</Button>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
