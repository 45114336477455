import { Combobox, Flex, useCombobox, Text, Button } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconPlus, IconSearch } from "@tabler/icons-react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { Assignee } from "@kiosk/front/pages/Surveys/EditQuestion"
import { AddAssigneeModal } from "@kiosk/front/pages/Surveys/EditQuestion/CreateAssigneeModal"
import { searchInArray } from "@kiosk/front/utils/text"

export const AssigneeSelect = ({
  companyContributors,
  assignee,
  onSelect,
  label,
}: {
  companyContributors: Assignee[]
  assignee: Assignee | null
  onSelect: (assignee: Assignee) => void
  label: string
}) => {
  const { t } = useTranslation("survey")
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch("")
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput()
    },
  })

  const [search, setSearch] = useState("")

  const options = searchInArray(
    companyContributors,
    search,
    "fullName",
    "email",
  )
    .slice(0, 10)
    .map((user) => (
      <Combobox.Option value={user.fullName} key={user.id}>
        <Flex direction="column">
          <Text>{user.fullName}</Text>
          <Text fz="sm" c="gray.5">
            {user.email}
          </Text>
        </Flex>
      </Combobox.Option>
    ))

  const [opened, { open, close }] = useDisclosure(false)

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        const assignee = companyContributors.find(
          ({ fullName }) => fullName == val,
        )
        if (assignee) onSelect(assignee)
        combobox.closeDropdown()
      }}
    >
      <Combobox.Target>
        <Flex align="center" justify="space-between" w="100%">
          <Button
            type="button"
            // pointer
            onClick={() => combobox.toggleDropdown()}
            variant="transparent"
            fullWidth
            justify="space-between"
            color="black"
            rightSection={<IconChevronDown size={20} color="gray" />}
          >
            <Flex gap="sm" align="center" justify="space-between">
              {assignee ? (
                <Flex direction="column" align="start">
                  <Text>{assignee.fullName}</Text>
                  <Text fz="xs" c="gray.5">
                    {assignee.email}
                  </Text>
                </Flex>
              ) : (
                <Text>{label}</Text>
              )}
            </Flex>
          </Button>
        </Flex>
      </Combobox.Target>

      <AddAssigneeModal
        opened={opened}
        onClose={close}
        onCreateNewAssignee={(user) => onSelect(user)}
      />

      <Combobox.Dropdown>
        <Combobox.Search
          leftSection={<IconSearch size={20} color="gray" />}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder={t("nameOrEmail")}
        />
        <Combobox.Options>{options}</Combobox.Options>
        <Combobox.Footer>
          <Button
            variant="outline"
            c="black"
            color="gray.3"
            fullWidth
            leftSection={<IconPlus color="green" size={20} />}
            onClick={() => {
              open()
              combobox.toggleDropdown()
            }}
          >
            {t("addAssigneeModal.newAssignee")}
          </Button>
        </Combobox.Footer>
      </Combobox.Dropdown>
    </Combobox>
  )
}
