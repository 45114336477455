import { Divider, Group, ScrollArea, Stack } from "@mantine/core"
import { round } from "lodash"
import { useMemo } from "react"
import { useParams } from "react-router-dom"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { useDatoDisclosureRequirement } from "@kiosk/front/api/datocms/datocms"
import { useDisclosureRequirementCategoriesQuery } from "@kiosk/front/api/reports"
import { DisclosureRequirementFormLayout } from "@kiosk/front/components/csrd/DisclosureRequirementFormLayout"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { config } from "@kiosk/front/config"
import { CSRDHeader } from "@kiosk/front/pages/CSRD/CSRDHeader"
import { DisclosureRequirementSideBar } from "@kiosk/front/pages/CSRD/DisclosureRequirementSideBar"

import { IRO2 } from "./DisclosureRequirements/IRO-2"

type Props = {
  categories: ReportsResponses.GetCategories
}

const DisclosureRequirementLayout = ({ categories }: Props) => {
  const params = useParams()
  const activeTopicId = params.datoTopicId!
  const activeSubTopicId = params.datoSubTopicId!

  const disclosureRequirementQuery =
    useDatoDisclosureRequirement(activeSubTopicId)

  const activeTopic = useMemo(() => {
    return categories
      .flatMap((category) => category.topics)
      .find((topic) => topic.id === activeTopicId)!
  }, [activeTopicId, categories])

  const progress = useMemo(() => {
    const drs = categories.flatMap((c) =>
      c.topics.flatMap((t) => t.disclosureRequirements.flatMap((dr) => dr)),
    )
    return (100 * drs.filter((dr) => dr.progress === 100).length) / drs.length
  }, [categories])

  return (
    <Stack gap={0} h="100%">
      <CSRDHeader progress={round(progress, 0)} />
      <Group gap={0} flex={1} align="flex-start">
        <ScrollArea h="75vh" w="25%" py={16} miw={400}>
          <DisclosureRequirementSideBar
            topic={activeTopic}
            activeSubTopicId={activeSubTopicId}
          />
        </ScrollArea>
        <Divider orientation="vertical" color="gray.2" />
        <ScrollArea h="75vh" flex={1} pos="relative">
          {activeSubTopicId === config.disclosureRequirements.iro2 ? (
            <IRO2 />
          ) : (
            <QueryWrapper fluid absolute query={disclosureRequirementQuery}>
              {({ data }) => (
                <DisclosureRequirementFormLayout
                  disclosureRequirement={data.disclosureRequirement}
                />
              )}
            </QueryWrapper>
          )}
        </ScrollArea>
      </Group>
    </Stack>
  )
}

export const DisclosureRequirement = () => {
  const csrdCategoriesQuery = useDisclosureRequirementCategoriesQuery()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={csrdCategoriesQuery}>
        {({ data }) => <DisclosureRequirementLayout categories={data} />}
      </QueryWrapper>
    </PageLayout>
  )
}
