import {
  MantineThemeOverride,
  MantineThemeColorsOverride,
  rem,
  defaultVariantColorsResolver,
  parseThemeColor,
  MantineTheme,
  SelectProps,
  TextInputProps,
} from "@mantine/core"

import { Color, colors } from "@kiosk/front/assets/theme/colors"

export const theme: MantineThemeOverride = {
  // Colors
  colors: colors as MantineThemeColorsOverride,
  primaryColor: "green",
  black: colors.gray[8],

  fontFamily: "Roboto, sans-serif",
  headings: { fontFamily: "Roboto, sans-serif" },

  fontSizes: {
    xxs: rem(11),
    xxl: rem(24),
  },
  radius: { sm: rem(6) },

  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input)
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme,
    })
    const currentColor = parsedColor.color as Color

    // Adding this variant for CSRD BooleanQuestion
    if (input.variant === "outlight") {
      return {
        background: colors[currentColor][0],
        hover: colors[currentColor][1],
        color: colors.gray[8],
        border: `1px solid ${colors[currentColor][6]}`,
      }
    }
    return defaultResolvedColors
  },

  components: {
    Text: {
      defaultProps: { size: "sm" },
    },
    Button: {
      defaultProps: { size: "md" },
      styles: {
        root: { paddingInline: 16 },
        label: {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    TextInput: {
      styles: (theme: MantineTheme, props: TextInputProps) => ({
        label: { marginBottom: 4 },
        input: {
          height: 42,
          borderColor:
            props.variant === "unstyled" ? "transparent" : theme.colors.gray[2],
        },
      }),
    },
    Textarea: {
      defaultProps: {
        minRows: 2,
        autosize: true,
        resize: "vertical",
      },
      styles: {
        input: { borderColor: colors.gray[2] },
      },
    },
    Select: {
      styles: (theme: MantineTheme, props: SelectProps) => ({
        input: {
          // TODO: not ideal. Probably worth overriding variants instead
          height: props.size === "md" ? 42 : "inherit",
          borderColor:
            props.variant === "unstyled" ? "transparent" : theme.colors.gray[2],
        },
      }),
    },
    ThemeIcon: {
      styles: {
        root: { background: "transparent" },
      },
    },
    Checkbox: {
      styles: { input: { cursor: "pointer" }, label: { cursor: "pointer" } },
    },
    Table: {
      defaultProps: { borderColor: "gray.2" },
      styles: {
        tfoot: {
          borderTop: `${rem(1)} solid var(--table-border-color)`,
        },
      },
    },
    Modal: {
      styles: {
        title: {
          fontSize: 24,
          fontWeight: 600,
        },
        header: {
          padding: 24,
        },
      },
    },
    Pill: {
      styles: {
        root: {
          background: colors.purple[1],
          color: colors.purple[8],
          paddingTop: 1,
          paddingBottom: 1,
          height: 24,
        },
      },
    },
    HoverCard: {
      styles: {
        dropdown: {
          backgroundColor: colors.gray[8],
          color: colors.gray[1],
          paddingX: 16,
          paddingY: 8,
        },
      },
    },
    Dialog: {
      defaultProps: {
        radius: "md",
        shadow: "xl",
        p: 0,
        mih: 0,
        size: "auto",
        withBorder: true,
      },
    },
  },
}
