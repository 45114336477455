/**
 * Configuration for the frontend app.
 */

export const config = {
  isDemo: import.meta.env.VITE_IS_DEMO === "true",
  logo: (import.meta.env.VITE_IS_GREENSTER === "true"
    ? "greenster"
    : "kiosk") as "greenster" | "kiosk",
  disclosureRequirements: {
    iro2: "bHZEVI1JTCeVQwSawlAJxw",
  },
}
