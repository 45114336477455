import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"

import { i18n } from "@kiosk/i18n"

import { CreateAssignmentBody } from "@kiosk/shared/schemas/assignments"

import { assignmentsKeys } from "@kiosk/front/api/surveys/assignments/assignmentsKeys"
import { surveysKeys } from "@kiosk/front/api/surveys/surveysKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const useBulkCreateAssignmentsMutation = (
  questionId: string,
  surveyId: string,
) => {
  return useMutation({
    mutationFn: async (
      data: CreateAssignmentBody[],
    ): Promise<AssignmentResponses.Assignment> => {
      const response = await apiClient.post(
        `/survey_questions/${questionId}/assignments/bulk`,
        data,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: assignmentsKeys.list(questionId),
      })
      queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
