import { Checkbox, MultiSelect, Radio, Select } from "@mantine/core"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { ChoiceRecord } from "@kiosk/types/data/dato"

import { InputProps } from "@kiosk/front/components/csrd/questions/types"

export const ChoiceQuestion = ({
  content,
  formProps,
}: InputProps<ChoiceRecord>) => {
  const isLargeOptionSet = useMemo(
    () => content.enumeration.options.length > 5,
    [content.enumeration.options.length],
  )

  if (content.isMultiple) {
    if (isLargeOptionSet) {
      return <MultipleChoiceSelect content={content} formProps={formProps} />
    }
    return <MultipleChoiceCheckbox content={content} formProps={formProps} />
  }

  if (isLargeOptionSet) {
    return <SingleChoiceSelect content={content} formProps={formProps} />
  }

  return <SingleChoiceGroup content={content} formProps={formProps} />
}

const MultipleChoiceSelect = ({
  content,
  formProps,
}: InputProps<ChoiceRecord>) => {
  const { t } = useTranslation("common")

  return (
    <MultiSelect
      placeholder={t("fields.selectOptions")}
      data={content.enumeration.options.map((option) => ({
        value: option.label,
        label: option.label,
      }))}
      searchable
      nothingFoundMessage={t("fields.nothingFound")}
      {...formProps}
    />
  )
}

const MultipleChoiceCheckbox = ({
  content,
  formProps,
}: InputProps<ChoiceRecord>) => (
  <Checkbox.Group size="md" {...formProps}>
    {content.enumeration.options.map((option) => (
      <Checkbox
        mt={8}
        size="sm"
        key={option.id}
        value={option.label}
        label={option.label}
      />
    ))}
  </Checkbox.Group>
)

const SingleChoiceGroup = ({
  content,
  formProps,
}: InputProps<ChoiceRecord>) => (
  <Radio.Group size="md" {...formProps}>
    {content.enumeration.options.map((option) => (
      <Radio
        mt={8}
        size="sm"
        key={option.id}
        value={option.label}
        label={option.label}
      />
    ))}
  </Radio.Group>
)

const SingleChoiceSelect = ({
  content,
  formProps,
}: InputProps<ChoiceRecord>) => {
  const { t } = useTranslation(["common"])
  return (
    <Select
      placeholder={t("fields.selectOption")}
      data={content.enumeration.options.map((option) => ({
        value: option.label,
        label: option.label,
      }))}
      {...formProps}
    />
  )
}
