import {
  Button,
  Container,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
} from "@mantine/core"
import _ from "lodash"
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { CsrdCategory } from "@kiosk/types/data/dato"
import { DimensionBreakdownValue } from "@kiosk/types/dimensions"
import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"
import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { CreateAssignmentBody } from "@kiosk/shared/schemas/assignments"

import { useListDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { useBulkCreateAssignmentsMutation } from "@kiosk/front/api/surveys/assignments/bulkCreateAssignments"
import { useClearAssignmentsMutation } from "@kiosk/front/api/surveys/assignments/clearAssignments"
import { useListAssignmentsQuery } from "@kiosk/front/api/surveys/assignments/listAssignments"
import { useGetSurveyQuestionQuery } from "@kiosk/front/api/surveys/questions/getSurveyQuestion"
import { useSurveyQuestionsDataModel } from "@kiosk/front/api/surveys/questions/model/surveyQuestionsDataModel"
import { findQuestionById } from "@kiosk/front/api/surveys/questions/model/utils"
import { BackButton } from "@kiosk/front/components/BackButton"
import { DimensionsBreakdownSelect } from "@kiosk/front/components/dimensions/DimensionsBreakdownSelect"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { Assignments } from "@kiosk/front/pages/Surveys/EditQuestion/Assignments"
import { AssignmentsPlaceholder } from "@kiosk/front/pages/Surveys/EditQuestion/AssignmentsPlaceholder"
import { EnrichedDimensionBreakdown } from "@kiosk/front/utils/dimensions"

export type Assignee = { id: string; email: string; fullName: string }

const buildAssignment = (
  breakdown: EnrichedDimensionBreakdown,
  assignments: AssignmentResponses.Assignment[],
): CreateAssignmentBody => {
  const assignment = assignments.find((assignment) => {
    return assignment.assignmentDimensions.every((assignmentDimension) => {
      return breakdown.find(
        (breakdownItem) =>
          breakdownItem.dimensionId === assignmentDimension.dimensionDatoId &&
          breakdownItem.optionId === assignmentDimension.optionId,
      )
    })
  })

  const assigneeId = assignment?.assigneeId ?? null
  return {
    assigneeId,
    assignmentDimensions: breakdown.map((breakdownItem) => ({
      dimensionType: breakdownItem.dimensionType,
      dimensionDatoId: breakdownItem.dimensionId,
      optionId: breakdownItem.optionId,
    })),
  }
}

const extractBreakdownsFromAssignments = (
  assignments: AssignmentResponses.Assignment[],
): DimensionBreakdownValue[] => {
  return _.uniqBy(
    assignments.flatMap((assignment) => {
      return assignment.assignmentDimensions.map((assignmentDimension) => {
        return {
          dimensionId: assignmentDimension.dimensionDatoId,
          optionId: assignmentDimension.optionId,
        }
      })
    }),
    (breakdown) => `${breakdown.dimensionId}-${breakdown.optionId}`,
  )
}

const hashAssignment = (assignment: CreateAssignmentBody) =>
  _.orderBy(assignment.assignmentDimensions.map((d) => d.optionId)).join("-")

type Props = {
  surveyQuestion: SurveyQuestionResponses.SurveyQuestion
  assignments: AssignmentResponses.Assignment[]
  allDimensions: ConsolidatedDimension[]
  csrdCategories: CsrdCategory[]
}

const EditQuestionContent = ({
  surveyQuestion,
  assignments,
  allDimensions,
  csrdCategories,
}: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation("survey")

  const { mutateAsync: bulkCreateAssignments } =
    useBulkCreateAssignmentsMutation(surveyQuestion.id, surveyQuestion.surveyId)
  const { mutateAsync: clearAssignments, isPending } =
    useClearAssignmentsMutation(surveyQuestion.id, false)

  const [isDirty, setIsDirty] = useState(false)

  const initialBreakdowns = useMemo(
    () => extractBreakdownsFromAssignments(assignments),
    [assignments],
  )

  const [currentAssignments, _setCurrentAssignments] = useState<
    CreateAssignmentBody[]
  >(_.orderBy(assignments, hashAssignment))
  const setCurrentAssignments = useCallback(
    (value: SetStateAction<CreateAssignmentBody[]>) => {
      setIsDirty(true)
      _setCurrentAssignments(value)
    },
    [],
  )

  const datoQuestion = useMemo(
    () => findQuestionById(csrdCategories, surveyQuestion.datoId),
    [csrdCategories, surveyQuestion.datoId],
  )

  const commitBreakdowns = useCallback(
    (breakdowns: EnrichedDimensionBreakdown[]) => {
      const formattedAssignments = breakdowns.map((breakdown) =>
        buildAssignment(breakdown, assignments),
      )
      setCurrentAssignments(_.orderBy(formattedAssignments, hashAssignment))
    },
    [assignments, setCurrentAssignments],
  )

  useEffect(() => {
    if (datoQuestion.dimensionIds.length === 0 && assignments.length === 0)
      setCurrentAssignments([
        {
          assignmentDimensions: [],
        },
      ])
  }, [
    assignments.length,
    datoQuestion.dimensionIds.length,
    setCurrentAssignments,
  ])

  if (!datoQuestion) {
    throw new Error(
      `Could not find question id in Dato model: ${surveyQuestion.datoId}`,
    )
  }

  return (
    <Stack gap={30}>
      <Flex align="center" justify="space-between" gap={16}>
        <BackButton
          onClick={async () => {
            navigate(`/sources/edit_survey/${surveyQuestion.surveyId}`)
          }}
        />
        <Text fw={600} size="xl" variant="unstyled" truncate="end" flex={1}>
          {surveyQuestion.label}
        </Text>
        <Button
          style={{ flexShrink: 0 }}
          onClick={async () => {
            if (isDirty) {
              await clearAssignments()
              await bulkCreateAssignments(currentAssignments)
            }
            navigate(`/sources/edit_survey/${surveyQuestion.surveyId}`)
          }}
          loading={isPending}
        >
          {t("saveAndBackToList")}
        </Button>
      </Flex>
      <Group gap={16} align="start">
        {datoQuestion.dimensionIds.length > 0 && (
          <Stack w={400}>
            <DimensionsBreakdownSelect
              initialBreakdowns={initialBreakdowns}
              dimensionIds={datoQuestion.dimensionIds}
              onGenerateBreakdowns={commitBreakdowns}
            />
          </Stack>
        )}
        {currentAssignments.length ? (
          <Assignments
            currentAssignments={currentAssignments}
            setCurrentAssignments={setCurrentAssignments}
            allDimensions={allDimensions}
          />
        ) : (
          <AssignmentsPlaceholder />
        )}
      </Group>
    </Stack>
  )
}

export const EditQuestion = () => {
  const { id } = useParams() as { id: string }
  const getSurveyQuestionQuery = useGetSurveyQuestionQuery(id)
  const { data: assignments } = useListAssignmentsQuery(id)
  const { data: allDimensions } = useListDimensionsQuery()

  const { data: csrdCategories } = useSurveyQuestionsDataModel()

  // TODO: check if we need dimensions here
  // TODO: better loading
  if (!assignments || !allDimensions || !csrdCategories)
    return (
      <Container mt="5%">
        <Stack align="center">
          <Loader />
        </Stack>
      </Container>
    )

  return (
    <PageLayout>
      <QueryWrapper query={getSurveyQuestionQuery}>
        {({ data: surveyQuestion }) => (
          <EditQuestionContent
            surveyQuestion={surveyQuestion}
            assignments={assignments}
            csrdCategories={csrdCategories}
            allDimensions={allDimensions}
          />
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
