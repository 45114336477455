import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { Task } from "@kiosk/types/prisma-client"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { taskKeys } from "./taskKeys"

export const deleteTask = async (id: string): Promise<Task> => {
  const response = await apiClient.delete(`/tasks/${id}`)
  return response.data
}

export const useDeleteTaskMutation = () => {
  const { t } = useTranslation(["task", "common"])

  return useMutation({
    mutationFn: deleteTask,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: taskKeys.all() })
      await queryClient.invalidateQueries({ queryKey: taskKeys.owners() })
      notifications.show({
        title: t("common:messages.success"),
        message: t("deleteTaskSuccess"),
        color: "green",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
