import { HoverCard, RingProgress, Text } from "@mantine/core"
import { IconCircleCheckFilled } from "@tabler/icons-react"
import { round } from "lodash"

import { colors } from "../assets/theme/colors"

interface CompletionProps {
  progress: number
}

export function Completion(props: CompletionProps) {
  const componentSize = 22

  return (
    <div>
      {props.progress == 100 ? (
        <IconCircleCheckFilled
          size={componentSize}
          color="white"
          fill={colors.green[6]}
        />
      ) : (
        <HoverCard position="top" closeDelay={0} openDelay={100}>
          <HoverCard.Target>
            <RingProgress
              size={componentSize}
              thickness={2}
              sections={[{ value: props.progress, color: "green.6" }]}
            />
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text>{round(props.progress, 0)}%</Text>
          </HoverCard.Dropdown>
        </HoverCard>
      )}
    </div>
  )
}
