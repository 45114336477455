import {
  ActionIcon,
  Button,
  Checkbox,
  Flex,
  Group,
  Table as MantineTable,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconChevronRight,
  IconExclamationCircle,
  IconPlus,
} from "@tabler/icons-react"
import { difference, omit, union } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Question } from "@kiosk/types/data/dato"
import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"
import { SurveyResponses } from "@kiosk/types/endpoints/surveys"
import { Survey } from "@kiosk/types/prisma-client"

import { i18n } from "@kiosk/i18n"

import { useCreateSurveyQuestionMutation } from "@kiosk/front/api/surveys/questions/createSurveyQuestion"
import { useDeleteSurveyQuestionMutation } from "@kiosk/front/api/surveys/questions/deleteSurveyQuestion"
import { Table } from "@kiosk/front/components/generic"
import { Column } from "@kiosk/front/components/generic/types"
import { AddQuestionsModal } from "@kiosk/front/components/questions/AddQuestionsModal"
import { DeleteQuestionsDialog } from "@kiosk/front/pages/Surveys/EditSurvey/DeleteQuestionsDialog"
import { DimensionsCell } from "@kiosk/front/pages/Surveys/EditSurvey/DimensionsCell"
import { isSurveyStarted } from "@kiosk/front/pages/Surveys/utils"
import { usePosition } from "@kiosk/front/utils/usePosition"

type Props = {
  survey: SurveyResponses.Survey
  surveyQuestions: SurveyQuestionResponses.SurveyQuestion[]
  getOnNavigate: (url: string) => () => void
}

export const QuestionsList = ({
  surveyQuestions,
  survey,
  getOnNavigate,
}: Props) => {
  const [opened, { open, close: onClose }] = useDisclosure(false)
  const { t } = useTranslation("survey")
  const { mutateAsync: deleteSurveyQuestion, isPending } =
    useDeleteSurveyQuestionMutation(survey.id)
  const [tableRef, tablePosition] = usePosition<HTMLTableElement>()

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  useEffect(
    () => () => {
      setSelectedIds([])
    },
    [surveyQuestions],
  )

  const columns: Column<Survey>[] = useMemo(
    () => [
      {
        key: "label",
        colId: "label",
        title: t("questions.label"),
        render: (label, record) => (
          <Flex gap={12} align="center">
            {!isSurveyStarted(survey) && (
              <Checkbox
                onChange={() =>
                  selectedIds.includes(record.id)
                    ? setSelectedIds((ids) => difference(ids, [record.id]))
                    : setSelectedIds((ids) => union(ids, [record.id]))
                }
              />
            )}
            <Text>{label}</Text>
          </Flex>
        ),
      },
      {
        key: "dimensions",
        colId: "datoId",
        title: t("questions.dimensions"),
        render: (datoId) => <DimensionsCell questionDatoId={datoId} />,
      },
      {
        key: "assignmentsCount",
        colId: "_count",
        width: 80,
        title: t("questions.assignmentsCount"),
        render: ({ assignments, assignees }) =>
          assignments === 0 || assignees < assignments ? (
            <Group flex={1} gap="sm" justify="space-between">
              <Text size="md" c="red.8">
                {assignees}/{assignments}
              </Text>
              <Tooltip
                label={
                  assignments === 0
                    ? t("errors.missingBreakdown")
                    : t("errors.missingAssignees", {
                        count: assignments - assignees,
                      })
                }
              >
                <ThemeIcon c="red.8" size="sm">
                  <IconExclamationCircle />
                </ThemeIcon>
              </Tooltip>
            </Group>
          ) : (
            <Text size="md" c="default">
              {assignees}/{assignments}
            </Text>
          ),
      },
      {
        key: "edit",
        colId: "id",
        width: 66,
        title: "",
        render: (id) => (
          <ActionIcon
            w={46}
            h={26}
            variant="transparent"
            onClick={
              isSurveyStarted(survey)
                ? getOnNavigate(`/sources/survey_answers/${id}`)
                : getOnNavigate(`/sources/edit_question/${id}`)
            }
          >
            <ThemeIcon c="gray.5" w={22} h={22}>
              <IconChevronRight />
            </ThemeIcon>
          </ActionIcon>
        ),
      },
    ],
    [t, survey, selectedIds, getOnNavigate],
  )

  const { mutateAsync: createSurveyQuestion } = useCreateSurveyQuestionMutation(
    survey.id,
  )

  return (
    <>
      <Table
        dataSource={surveyQuestions}
        getRowKey={({ id }) => id}
        columns={columns}
        highlightOnHoverColor="emerald.0"
        footer={
          <MantineTable.Td>
            <Button
              pl={4}
              size="sm"
              variant="transparent"
              color="gray.6"
              leftSection={<IconPlus color="green" />}
              onClick={open}
              disabled={isSurveyStarted(survey)}
            >
              {i18n.t("survey:questions.new_question")}
            </Button>
          </MantineTable.Td>
        }
        withColumnBorders
        ref={tableRef}
      />
      <AddQuestionsModal
        opened={opened}
        onClose={onClose}
        onSave={(questions: Question[]) =>
          questions.forEach((question) =>
            createSurveyQuestion({
              ...omit(question, "id"),
              datoId: question.id,
            }),
          )
        }
      />
      <DeleteQuestionsDialog
        selectedIds={selectedIds}
        deleteSurveyQuestion={deleteSurveyQuestion}
        isPending={isPending}
        position={{ left: tablePosition.x, top: tablePosition.y }}
      />
    </>
  )
}
