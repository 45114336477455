import { orderBy } from "lodash"
import { useMemo } from "react"

import { CsrdCategory } from "@kiosk/types/data/dato"

import { searchInArray } from "@kiosk/front/utils/text"

export const useCurrentQuestions = (
  csrdCategories: CsrdCategory[],
  categoryId: string | null,
  topicId: string | undefined,
  search: string,
) => {
  const currentCategories = useMemo(
    () => csrdCategories?.filter(({ id }) => !categoryId || id === categoryId),
    [csrdCategories, categoryId],
  )

  const currentTopics = useMemo(
    () =>
      currentCategories
        ?.flatMap(({ topics }) => topics)
        .filter(({ id }) => !topicId || id === topicId),
    [currentCategories, topicId],
  )

  const currentQuestions = useMemo(
    () =>
      searchInArray(
        orderBy(
          currentTopics
            ?.flatMap(({ disclosureRequirements }) => disclosureRequirements)
            .flatMap(({ questions }) => questions),
          ({ label }) => label,
        ),
        search,
        "label",
      ),
    [currentTopics, search],
  )

  return currentQuestions
}
