import { z } from "zod"

export const companySchema = z.object({
  name: z.string(),
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  logoUrl: z.string().optional(),
})

export const inviteUserSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
})

export type CompanyBody = z.infer<typeof companySchema>
export type InviteUserBody = z.infer<typeof inviteUserSchema>
