import { Stack, Text } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { TypedDimensionOption } from "@kiosk/types/prisma-client"

import { DimensionCategory } from "@kiosk/front/api/datocms/types"
import { TypedDimension } from "@kiosk/front/api/dimensions/types"
import { EditDimensionDrawer } from "@kiosk/front/components/dimensions/EditDimensionDrawer"
import { Table } from "@kiosk/front/components/generic/index"

type Props = {
  category: DimensionCategory
  dimensions: TypedDimension[]
}

export const DimensionsTable = ({ category, dimensions }: Props) => {
  const { t } = useTranslation("dimensions")

  const categoryTranslations: Record<DimensionCategory, string> = {
    csrd: t("categories.csrd"),
    company: t("categories.company"),
  }

  const [editingDimension, setEditingDimension] = useState<
    TypedDimension | undefined
  >(undefined)

  const columns = useMemo(() => {
    return [
      {
        colId: "label",
        key: "label",
        title: t("columns.label"),
      },
      {
        colId: "options",
        key: "options",
        title: t("columns.count"),
        width: 100,
        render: (options: TypedDimensionOption[]) => (
          <Text>{options.length}</Text>
        ),
      },
      {
        key: "arrow",
        colId: "arrow",
        title: "",
        width: 20,
        render: () => <IconChevronRight />,
      },
    ]
  }, [t])

  return (
    <Stack>
      <Text fw={500} size="lg">
        {categoryTranslations[category]}
      </Text>
      <Table
        columns={columns}
        dataSource={dimensions}
        withColumnBorders
        onRowClick={(dimension) => setEditingDimension(dimension)}
      />
      <EditDimensionDrawer
        dimension={editingDimension}
        onClose={() => setEditingDimension(undefined)}
      />
    </Stack>
  )
}
