import {
  ActionIcon,
  Avatar,
  Group,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core"
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconEdit,
  IconTrash,
} from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { User, UserRole } from "@kiosk/types/prisma-client"

import { Column } from "@kiosk/front/components/generic/types"
import { roles } from "@kiosk/front/utils/constants"
import { getInitials } from "@kiosk/front/utils/helpers"

export const useSettingsColumns = (
  showDeleteModal: (value: string) => void,
  showUpdateModal: (value: string) => void,
  isPendingDelete: boolean,
  selectedUserId: string | null,
): Array<Column<User>> => {
  const { t } = useTranslation()

  return [
    {
      colId: "firstName",
      key: "name",
      title: t("columns.name"),
      render: (firstName, { lastName, email, avatarUrl }) => {
        return (
          <Group gap={14}>
            <Avatar color="green" src={avatarUrl}>
              {getInitials(firstName, lastName)}
            </Avatar>
            <Stack gap={6}>
              <Text fw={500} m={0}>{`${firstName} ${lastName}`}</Text>
              <Text c="gray.7" m={0}>
                {email}
              </Text>
            </Stack>
          </Group>
        )
      },
    },
    {
      colId: "role",
      title: t("columns.role"),
      key: "role",
      render: (role: UserRole) => roles[role],
    },
    {
      colId: "jobTitle",
      title: t("columns.jobTitle"),
      key: "jobTitle",
      render: (jobTitle: string) => jobTitle,
    },
    {
      colId: "isSignedUp",
      title: t("columns.verified"),
      key: "isSignedUp",

      render: (isSignedUp) => (
        <Group gap={14}>
          {isSignedUp ? (
            <ThemeIcon variant="white" color="green.5">
              <IconCircleCheckFilled />
            </ThemeIcon>
          ) : (
            <ThemeIcon variant="white" color="red.8">
              <IconCircleXFilled />
            </ThemeIcon>
          )}
        </Group>
      ),
    },
    {
      key: "actions",
      colId: "id",
      title: t("company:columns.actions"),
      render: (id) => (
        <>
          <ActionIcon
            size="lg"
            color="gray.5"
            variant="outline"
            onClick={() => showUpdateModal(id)}
          >
            <IconEdit size={20} />
          </ActionIcon>
          <ActionIcon
            onClick={() => showDeleteModal(id)}
            size="lg"
            color="red.5"
            variant="outline"
            loading={selectedUserId === id && isPendingDelete}
          >
            <IconTrash size={20} />
          </ActionIcon>
        </>
      ),
    },
  ]
}
