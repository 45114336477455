import { Stack } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { CSRDQuestions, Content } from "@kiosk/types/data/dato"

import { CSRDQuestionTree } from "@kiosk/front/components/csrd/CSRDQuestionTree"
import { FormShape } from "@kiosk/front/components/csrd/types"
import { EnrichedDimensionBreakdown } from "@kiosk/front/utils/dimensions"

interface RelatedQuestionsTreeProps {
  relatedQuestions: CSRDQuestions<Content>[]
  breakdown: EnrichedDimensionBreakdown
  form: UseFormReturnType<FormShape>
}

/**
 * Renders a tree of questions as nested questions.
 */
export const RelatedQuestionsTree = ({
  relatedQuestions,
  breakdown,
  form,
}: RelatedQuestionsTreeProps) => (
  <Stack p="md" gap="xs" bg="white">
    {relatedQuestions.map((relatedQuestion) => (
      <CSRDQuestionTree
        breakdown={breakdown}
        key={relatedQuestion.id}
        question={relatedQuestion}
        form={form}
      />
    ))}
  </Stack>
)
