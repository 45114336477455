import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { UsersResponses } from "@kiosk/types/endpoints/users"

import { CreateCompanyContributorBody } from "@kiosk/shared/schemas/users"

import { accountKeys } from "@kiosk/front/api/account/accountKeys"
import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const useCreateCompanyContributorMutation = () => {
  const { t } = useTranslation("common")
  return useMutation({
    mutationFn: async (
      data: CreateCompanyContributorBody,
    ): Promise<UsersResponses.GetUser> => {
      const response = await apiClient.post<UsersResponses.GetUser>(
        "/account/company-contributor",
        data,
      )
      return response.data
    },
    onSuccess: async (data: UsersResponses.GetUser) => {
      await queryClient.invalidateQueries({ queryKey: accountKeys.me() })
      await queryClient.invalidateQueries({
        queryKey: companyKeys.companyUsers(data.companyId),
      })
    },
    onError: (error: Error) => {
      notifications.show({
        title: t("messages.error"),
        message: error.message,
        color: "red",
      })
    },
  })
}
