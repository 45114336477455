// Service for dealing with the taxonomy tree from the frontend side.
// Some pieces might move to shared modules or be pieced together
// with the usage of a framework.
//
// You might also find lookalike interfaces lying around.
// The goal is to centralize the logic and interfaces here and
// have the frontend application depend on this service.
//
// Note that these interfaces need to account for translations.
//
// We follow a nomenclature to limit the surprises:
// - code: tag name, e.g.: `esrs:GHGRemovalsAndStorage`
// - label: the «friendly» name for the element: `Policies related to own workforce `

import _ from "lodash"
import { z } from "zod"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

// todo: complete
export const Question = z.object({
  id: z.string(),
  code: z.string(),
  label: z.string(),
})
export type Question = z.infer<typeof Question>

export const DisclosureRequirement = z.object({
  id: z.string(),
  code: z.string(),
  label: z.string(),
  // label that’s shown in the report
  reportLabel: z.string(),
  instructions: z.object({}), // todo
})
export type DisclosureRequirement = z.infer<typeof DisclosureRequirement>

export const Topic = z.object({
  id: z.string(),
  code: z.string(),
  label: z.string(),
  disclosureRequirements: z.array(DisclosureRequirement),
})
export type Topic = z.infer<typeof Topic>

export const Category = z.object({
  id: z.string(),
  label: z.string(),
  topics: z.array(Topic),
  order: z.number(),
})
export type Category = z.infer<typeof Category>

// todo: cleanup the typing
export function getTopicById(
  categories: ReportsResponses.GetCategories,
  id: string,
) {
  return _(categories)
    .flatMap((category) => category.topics)
    .find((topic) => topic.id === id)!
}

export function getOverallProgress(
  categories: ReportsResponses.GetCategories,
): number {
  const disclosureRequirements = categories.flatMap((c) =>
    c.topics.flatMap((t) => t.disclosureRequirements.flatMap((dr) => dr)),
  )
  const completeDisclosureRequirements = disclosureRequirements.filter(
    (dr) => dr.progress === 100,
  )
  return (
    (100 * completeDisclosureRequirements.length) /
    disclosureRequirements.length
  )
}
