import { Modal, Button, Flex, TextInput, ModalProps } from "@mantine/core"
import { isEmail, isNotEmpty, useForm } from "@mantine/form"
import { IconMail } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { useCreateCompanyContributorMutation } from "@kiosk/front/api/users/createCompanyContributor"
import { Assignee } from "@kiosk/front/pages/Surveys/EditQuestion"

interface AddAssigneeModalProps extends ModalProps {
  onCreateNewAssignee: (user: Assignee) => void
}

export function AddAssigneeModal(props: AddAssigneeModalProps) {
  const { t } = useTranslation("survey")

  const { mutateAsync: createCompanyContributor, isPending } =
    useCreateCompanyContributorMutation()

  const form = useForm({
    // mode: "uncontrolled",
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      jobTitle: undefined,
    },

    validate: {
      firstName: isNotEmpty(t("addAssigneeModal.validate.enterFirstName")),
      lastName: isNotEmpty(t("addAssigneeModal.validate.enterLastName")),
      email: isEmail(t("addAssigneeModal.validate.enterEmail")),
    },
  })

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={t("addAssigneeModal.newAssignee")}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          const companyContributor = await createCompanyContributor(values)
          props.onCreateNewAssignee({
            id: companyContributor.id,
            fullName: `${companyContributor.firstName} ${companyContributor.lastName}`,
            email: companyContributor.email,
          })
          props.onClose()
        })}
      >
        <Flex
          direction="column"
          gap={16}
          pt={24}
          pb={24}
          pl={16}
          pr={16}
          style={{
            borderBottom: "1px solid var(--mantine-color-gray-2)",
            borderTop: "1px solid var(--mantine-color-gray-2)",
          }}
        >
          <Flex gap={10}>
            <TextInput
              autoFocus={true}
              placeholder={t("addAssigneeModal.firstName")}
              key={form.key("firstName")}
              {...form.getInputProps("firstName")}
            />
            <TextInput
              placeholder={t("addAssigneeModal.lastName")}
              key={form.key("lastName")}
              {...form.getInputProps("lastName")}
            />
          </Flex>
          <TextInput
            leftSection={<IconMail size={20} color="gray" />}
            placeholder={t("addAssigneeModal.emailAddress")}
            key={form.key("email")}
            {...form.getInputProps("email")}
          />
          <TextInput
            placeholder={t("addAssigneeModal.jobTitle")}
            key={form.key("jobTitle")}
            {...form.getInputProps("jobTitle")}
          />
        </Flex>
        <Flex justify="end" gap={16} pt={16} pb={16} pl={24} pr={24}>
          <Button
            variant="light"
            color="gray"
            onClick={close}
            disabled={isPending}
          >
            {t("addAssigneeModal.cancel")}
          </Button>
          <Button loading={isPending} type="submit">
            {t("addAssigneeModal.addAssignee")}
          </Button>
        </Flex>
      </form>
    </Modal>
  )
}
