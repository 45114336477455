import { Button, Group } from "@mantine/core"
import { isFunction } from "lodash"
import { useTranslation } from "react-i18next"

type Props = {
  isLoading: boolean
  onContinue: () => void
  disableContinue?: boolean
  onSkipStep?: () => void
}

export const Footer = ({
  isLoading,
  onContinue,
  disableContinue,
  onSkipStep,
}: Props) => {
  const { t } = useTranslation("common")

  const hasSkipButton = isFunction(onSkipStep)

  return (
    <Group
      h={81}
      w="100%"
      justify="center"
      py={16}
      px={24}
      style={{ borderTop: "solid 1px var(--mantine-color-gray-3)" }}
    >
      <Group w="100%" maw={830} justify="flex-end">
        <Group>
          {hasSkipButton && (
            <Button onClick={onSkipStep} variant="subtle" color="green">
              {t("buttons.skip")}
            </Button>
          )}
          <Button
            h={42}
            onClick={onContinue}
            disabled={disableContinue}
            loading={isLoading}
          >
            {t("buttons.continue")}
          </Button>
        </Group>
      </Group>
    </Group>
  )
}
