import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { AccountResponses } from "@kiosk/types/endpoints/account"

import { apiClient } from "@kiosk/front/lib/apiClient"

export const impersonateUser = async (clerkUserId: string) => {
  const response = await apiClient.post<AccountResponses.Impersonation>(
    `/account/impersonate/${clerkUserId}`,
  )
  return response.data
}

export const useImpersonateUser = () => {
  return useMutation({
    mutationFn: impersonateUser,
    onSuccess: async (data: AccountResponses.Impersonation) => {
      if (data && data.url) {
        localStorage.clear()
        window.location.href = data.url
      }
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      showNotification({ message: error.message })
    },
  })
}
