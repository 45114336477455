import { Button, Modal, ModalBaseProps, Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { colors } from "@kiosk/front/assets/theme/colors"

import { IntegrationRequest } from "./IntegrationRequest"
import { NetSuiteLogo } from "./logos/NetSuiteLogo"
import { PipedriveLogo } from "./logos/PipedriveLogo"
import { SalesforceLogo } from "./logos/SalesforceLogo"
import { XeroLogo } from "./logos/XeroLogo"

interface Integration {
  name: string
  logo: React.FC
}

interface IntegrationCategory {
  category: string
  integrations: Integration[]
}

const INTEGRATIONS: IntegrationCategory[] = [
  {
    category: "ERP",
    integrations: [
      { logo: NetSuiteLogo, name: "Netsuite" },
      { logo: XeroLogo, name: "Xero" },
    ],
  },
  {
    category: "CRM",
    integrations: [
      { logo: SalesforceLogo, name: "Salesforce" },
      { logo: PipedriveLogo, name: "Pipedrive" },
    ],
  },
]

interface NewIntegrationModalProps extends ModalBaseProps {
  onRequest: () => void
}

export const NewIntegrationModal = (props: NewIntegrationModalProps) => {
  const { t } = useTranslation("sources")

  const categoryTranslation: Record<string, string> = {
    CRM: t("integrationCategories.CRM"),
    ERP: t("integrationCategories.ERP"),
  }

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={t("newIntegration")}
      size="lg"
      centered={true}
      padding={0}
    >
      <Stack px={40}>
        {INTEGRATIONS.map(({ category, integrations }) => (
          <Stack key="category" gap={12}>
            <Text c={colors.gray[5]} fw="bold">
              {categoryTranslation[category] ?? category}
            </Text>
            {integrations.map((i) => (
              <IntegrationRequest
                key={i.name}
                Logo={i.logo}
                name={i.name}
                onRequest={props.onRequest}
              />
            ))}
          </Stack>
        ))}
      </Stack>
      <Button
        color="gray.1"
        c="black"
        fullWidth={true}
        mb={1}
        mt={20}
        h={60}
        radius={0}
      >
        <Text td="underline" ta="center" py={16} c="gray.6">
          {t("seeAllIntegrations")}
        </Text>
      </Button>
    </Modal>
  )
}
