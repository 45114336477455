import { Flex, Badge } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { orderBy } from "lodash"

import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { CreateAssignmentBody } from "@kiosk/shared/schemas/assignments"

import { findDimensionOptionById } from "@kiosk/front/pages/Surveys/utils"

export const AssignmentDimensionsCell = ({
  assignmentDimensions,
  allDimensions,
}: {
  assignmentDimensions: CreateAssignmentBody["assignmentDimensions"]
  allDimensions: ConsolidatedDimension[]
}) => (
  <Flex align="center" gap={4} wrap="wrap">
    {orderBy(assignmentDimensions, ({ dimensionDatoId }) => dimensionDatoId)
      .map((assignmentDimension) => {
        const datoOption = findDimensionOptionById(
          assignmentDimension.dimensionDatoId,
          assignmentDimension.optionId,
          allDimensions,
        )
        return (
          <Badge
            tt="none"
            fw={500}
            style={(theme) => ({
              color: theme.colors.purple[8],
              backgroundColor: theme.colors.purple[1],
            })}
            key={datoOption.id}
            size="lg"
          >
            {datoOption.label}
          </Badge>
        )
      })
      .flatMap((badge, index) =>
        index === assignmentDimensions.length - 1 // check for the last item
          ? badge
          : [badge, <IconPlus key={`icon-plus-${index}`} size={16} />],
      )}
  </Flex>
)
