import {
  IconLayoutGrid,
  IconFileInvoice,
  IconClipboardCheck,
  IconBuilding,
  IconFolderOpen,
  IconTag,
} from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { USER_ROLES } from "@kiosk/types/user"

import { useUser } from "@kiosk/front/components/auth/UserContext"
import { TablerIconComponent } from "@kiosk/front/utils/icon"

import { routes } from "./routes"

interface NavBarItemBase {
  label: string
  href: string
}

export interface NavBarItemProps extends NavBarItemBase {
  icon: TablerIconComponent
  subItems?: Array<NavBarItemBase>
}

export const useNavBarItems = (): NavBarItemProps[] => {
  const user = useUser()
  const { t } = useTranslation()

  const isKioskSuperadmin = user.role === USER_ROLES.KIOSK_ADMIN
  const navbarItems: NavBarItemProps[] = [
    {
      label: routes.DASHBOARD.name,
      href: routes.DASHBOARD.path,
      icon: IconLayoutGrid,
    },
    {
      label: routes.CSRD.name,
      href: routes.CSRD.path.replace("/:datoTopicId?", ""),
      icon: IconFileInvoice,
    },
    {
      label: routes.TASKS.name,
      href: routes.TASKS.path,
      icon: IconClipboardCheck,
    },
    {
      label: routes.DIMENSIONS.name,
      href: routes.DIMENSIONS.path,
      icon: IconTag,
    },
    {
      label: routes.SOURCES.name,
      href: routes.SOURCES.path,
      icon: IconFolderOpen,
      subItems: [
        { label: t("routes.sourcesAll"), href: routes.SOURCES.path },
        { label: routes.SURVEYS.name, href: routes.SURVEYS.path },
      ],
    },
  ]

  if (isKioskSuperadmin) {
    navbarItems.splice(4, 0, {
      label: routes.COMPANIES.name,
      href: routes.COMPANIES.path,
      icon: IconBuilding,
    })
  }
  return navbarItems
}
