import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { i18n } from "@kiosk/i18n"

import { assignmentsKeys } from "@kiosk/front/api/surveys/assignments/assignmentsKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const useClearAssignmentsMutation = (
  questionId: string,
  invalidateCache: boolean = true,
) => {
  return useMutation({
    mutationFn: async (): Promise<void> => {
      const response = await apiClient.delete(
        `/survey_questions/${questionId}/assignments`,
      )
      return response.data
    },
    onSuccess: async () => {
      if (invalidateCache)
        await queryClient.invalidateQueries({
          queryKey: assignmentsKeys.list(questionId),
        })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
}
