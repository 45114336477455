import { TextInput, Text, ActionIcon, Group } from "@mantine/core"
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react"
import { useState } from "react"

export const EditableTitle = ({ ...inputProps }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [current, setCurrent] = useState(inputProps.value)

  if (!isEdit)
    return (
      <Group>
        <Text fw={600} size="xl">
          {current}
        </Text>
        <ActionIcon
          variant="transparent"
          color="gray.3"
          onClick={() => {
            setIsEdit(true)
          }}
          disabled={inputProps.readOnly}
        >
          <IconEdit />
        </ActionIcon>
      </Group>
    )
  return (
    <Group align="center">
      <TextInput
        size="xs"
        fz={16}
        styles={{
          input: {
            fontSize: 20,
          },
          wrapper: {
            borderRadius: 6,
          },
        }}
        value={current}
        onChange={(e) => setCurrent(e.target.value)}
      />

      <ActionIcon
        variant="subtle"
        onClick={() => {
          setIsEdit(false)
          inputProps.onChange(current)
        }}
      >
        <IconCheck size={20} />
      </ActionIcon>
      <ActionIcon
        variant="subtle"
        color="gray.5"
        onClick={() => {
          setIsEdit(false)
          setCurrent(inputProps.value)
        }}
      >
        <IconX size={20} />
      </ActionIcon>
    </Group>
  )
}
