import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { saveAs } from "file-saver"
import { useTranslation } from "react-i18next"

import { apiClient } from "@kiosk/front/lib/apiClient"

export const downloadCsv = async () => {
  const response = await apiClient.post("/tasks/download-csv")
  saveAs(new Blob([response.data]), "tasks.csv")
}

export const useDownloadCsv = () => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: downloadCsv,
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
