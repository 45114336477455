import { Anchor, Text } from "@mantine/core"
import { isLink, isParagraph } from "datocms-structured-text-utils"
import { CSSProperties } from "react"
import { renderNodeRule } from "react-datocms"
import {
  StructuredTextGraphQlResponseRecord,
  StructuredTextPropTypes,
} from "react-datocms/structured-text"
// FIXME: Dunno why

type RenderNodeRule<T extends StructuredTextGraphQlResponseRecord> =
  NonNullable<StructuredTextPropTypes<T>["customNodeRules"]>[number]

export const getParagraphNodeRule = <
  T extends StructuredTextGraphQlResponseRecord,
>(
  style?: CSSProperties,
): RenderNodeRule<T> =>
  renderNodeRule(isParagraph, ({ children, key }) => (
    <Text key={key} component="p" c="inherit" size="inherit" style={style}>
      {children}
    </Text>
  ))

export const getLinkNodeRule = <
  T extends StructuredTextGraphQlResponseRecord,
>(): RenderNodeRule<T> =>
  renderNodeRule(isLink, ({ node, children, key }) => {
    const targetHostName = new URL(node.url).hostname

    return (
      <Anchor
        key={key}
        href={node.url}
        target={
          targetHostName === import.meta.env.APP_URL ? undefined : "_blank"
        }
        c="green"
      >
        {children}
      </Anchor>
    )
  })
