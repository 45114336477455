/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as CoreTable, Group, TableProps } from "@mantine/core"
import { ReactElement, forwardRef, useMemo } from "react"

import { Column } from "./types"

const { Thead, Td, Th, Tr, Tbody, Tfoot } = CoreTable

/**
 * @deprecated use @kiosk/front/pages/Tasks/CategoryTaskTable
 **/

interface Props<RecordType extends object = any> extends TableProps {
  columns: Array<Column<RecordType>>
  dataSource: Array<Record<string, RecordType>>
  onRowClick?: (_record: RecordType, index?: number) => void
  getRowKey?: (record: RecordType, index?: number) => string
  footer?: ReactElement | null
}

export const Table = forwardRef<HTMLTableElement, Props>(
  (
    { columns, dataSource, onRowClick, getRowKey, footer, ...rest }: Props,
    ref,
  ) => {
    const rows = useMemo(
      () =>
        dataSource.map((record, index) => (
          <Tr
            style={{ cursor: onRowClick ? "pointer" : "initial" }}
            key={getRowKey ? getRowKey(record, index) : `row-${index}`}
            onClick={() => (onRowClick ? onRowClick(record, index) : null)}
          >
            {columns.map(({ key, colId, render }) => (
              <Td
                key={`${getRowKey ? getRowKey(record, index) : ""}-${key}-${index}`}
                c="gray.7"
              >
                <Group gap={12}>
                  {render
                    ? render(record[colId], record, index)
                    : (record[colId] as string)}
                </Group>
              </Td>
            ))}
          </Tr>
        )),
      [dataSource, columns, onRowClick, getRowKey],
    )

    const cols = columns.map(({ key, title, width }) => (
      <Th
        key={key}
        c="gray.6"
        px={10}
        py={9}
        h={44}
        fz="sm"
        lh="md"
        fw={500}
        w={width}
        style={{
          borderInlineEnd: "none",
        }}
      >
        {title}
      </Th>
    ))

    return (
      <CoreTable {...rest} ref={ref} highlightOnHover>
        <Thead>
          <Tr>{cols}</Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
        {footer ? (
          <Tfoot>
            <Tr>{footer}</Tr>
          </Tfoot>
        ) : null}
      </CoreTable>
    )
  },
)

Table.displayName = "Table"
