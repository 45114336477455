import { useQuery } from "@tanstack/react-query"

import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"

import { surveyQuestionsKeys } from "@kiosk/front/api/surveys/questions/surveyQuestionsKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const useGetSurveyQuestionQuery = (id: string) =>
  useQuery({
    queryKey: surveyQuestionsKeys.get(id),
    queryFn: async (): Promise<SurveyQuestionResponses.SurveyQuestion> => {
      const response =
        await apiClient.get<SurveyQuestionResponses.SurveyQuestion>(
          `/survey_questions/${id}`,
        )

      return response.data
    },
  })
