import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { User } from "@kiosk/types/prisma-client"

import { apiClient } from "@kiosk/front/lib/apiClient"

export const deleteUser = async (userId: string): Promise<User> => {
  const response = await apiClient.delete(`/users/${userId}`)
  return response.data
}

export const useDeleteUserMutation = (onSuccess: () => void) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: deleteUser,
    onSuccess,
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
