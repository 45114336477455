import { Dialog, Group, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { Assignee } from "@kiosk/front/pages/Surveys/EditQuestion"
import { AssigneeSelect } from "@kiosk/front/pages/Surveys/EditQuestion/AssigneeSelect"

export const AssigneeDialog = ({
  selectedIndices,
  companyContributors,
  position,
  onSelect,
}: {
  selectedIndices: number[]
  companyContributors: Assignee[]
  position: { left: number; top: number }
  onSelect: (assignee: Assignee) => void
}) => {
  const { t } = useTranslation("survey")
  return (
    <Dialog position={position} opened={selectedIndices.length > 0}>
      <Group gap={0}>
        <Group px={12} py={8}>
          <Text c="green" fw={600}>
            {t("nbSelected", { count: selectedIndices.length })}
          </Text>
        </Group>
        <Group
          style={{ borderLeft: "1px solid var(--mantine-color-gray-3)" }}
          w={392}
        >
          <AssigneeSelect
            companyContributors={companyContributors}
            assignee={null}
            onSelect={onSelect}
            label={t("assign")}
          />
        </Group>
      </Group>
    </Dialog>
  )
}
