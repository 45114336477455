import { Group, Select, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { DimensionedDataPoint } from "@kiosk/front/api/dashboard/types"
import { DashboardBarChart } from "@kiosk/front/pages/Dashboard/DashboardBarChart"
import { DashboardPieChart } from "@kiosk/front/pages/Dashboard/DashboardPieChart"

type Props = {
  dataPoint: DimensionedDataPoint
}

export const DashboardDimensionedDataPoint = ({ dataPoint }: Props) => {
  const { t } = useTranslation("dashboard")

  // All unique dimensions options
  const dimensionsOptions = useMemo(
    () =>
      _.uniqBy(
        dataPoint.values.flatMap((v) => v.dimensions),
        (d) => d.optionId,
      ),
    [dataPoint.values],
  )

  // Group dimensions options by dimensionId
  const dimensionOptionsByDimensionId = useMemo(
    () => _.groupBy(dimensionsOptions, (d) => d.dimensionId),
    [dimensionsOptions],
  )

  // Dimensions that can be projected, ie dimensions with more than 1 option
  const projectableDimensions = useMemo(
    () =>
      Object.values(dimensionOptionsByDimensionId)
        .filter((d) => d.length > 1)
        .map((d) => ({ label: d[0].dimensionLabel, value: d[0].dimensionId })),
    [dimensionOptionsByDimensionId],
  )

  const label = useMemo(() => {
    const singleOptionDimensions = Object.values(
      dimensionOptionsByDimensionId,
    ).filter((d) => d.length === 1)

    if (singleOptionDimensions.length === 0) {
      return dataPoint.label
    }
    return t("dimensionedDataPoint.label", {
      questionLabel: dataPoint.label,
      dimensionsLabel: singleOptionDimensions
        .map((d) => `${d[0].optionLabel} (${d[0].dimensionLabel})`)
        .join(","),
    })
  }, [dataPoint.label, dimensionOptionsByDimensionId, t])

  const [projectedDimensionId, setProjectedDimensionId] = useState(
    projectableDimensions[0].value,
  )

  const [fixedDimensions, setFixedDimensions] = useState<
    Record<string, string | null>
  >(
    _.mapValues(dimensionOptionsByDimensionId, (options, dimensionId) =>
      dimensionId === projectedDimensionId ? null : options[0].optionId,
    ),
  )

  return (
    <Stack p={16} gap={16} justify="space-between">
      <Text fz="md" c="gray.5">
        {label} ({dataPoint.unit})
      </Text>
      {projectableDimensions.length > 2 ? (
        <>
          <Stack mb={4}>
            <Select
              size="sm"
              label={t("projectedDimension")}
              value={projectedDimensionId}
              onChange={(value) => {
                if (!value) return
                setFixedDimensions((prev) => ({
                  ...prev,
                  [value]: null,
                  [projectedDimensionId]:
                    dimensionOptionsByDimensionId[projectedDimensionId][0]
                      .optionId,
                }))
                setProjectedDimensionId(value)
              }}
              data={projectableDimensions}
            />
            <Stack gap={2}>
              <Text fw={500}>{t("fixedDimensions")}</Text>
              <Group gap={8}>
                {_.map(
                  dimensionOptionsByDimensionId,
                  (options, dimensionId) => {
                    if (
                      dimensionId === projectedDimensionId ||
                      options.length === 1
                    )
                      return null
                    return (
                      <Select
                        key={dimensionId}
                        label={options[0].dimensionLabel}
                        data={options.map((o) => ({
                          value: o.optionId,
                          label: o.optionLabel,
                        }))}
                        value={fixedDimensions[dimensionId]}
                        onChange={(value) => {
                          setFixedDimensions((prev) => ({
                            ...prev,
                            [dimensionId]: value,
                          }))
                        }}
                        size="xs"
                      />
                    )
                  },
                )}
              </Group>
            </Stack>
          </Stack>
          <DashboardPieChart
            dataPoint={dataPoint}
            projectedDimensionId={projectedDimensionId}
            fixedDimensions={fixedDimensions}
          />
        </>
      ) : (
        <DashboardBarChart
          dataPoint={dataPoint}
          projectedDimensionId={projectedDimensionId}
          stackedDimensionId={
            projectableDimensions.find((d) => d.value !== projectedDimensionId)
              ?.value
          }
        />
      )}
    </Stack>
  )
}
