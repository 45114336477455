import { useClerk } from "@clerk/clerk-react"
import {
  Button,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { Locale } from "@kiosk/i18n"

import { UserBody, userSchema } from "@kiosk/shared/schemas/users"

import { useDeleteUserMutation } from "@kiosk/front/api/users/deleteUser"
import { useUpdateUserMutation } from "@kiosk/front/api/users/updateUser"
import { PictureUploader } from "@kiosk/front/components/PictureUploader"
import { useUser } from "@kiosk/front/components/auth/UserContext"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { ResetPasswordModal } from "@kiosk/front/pages/Settings/ResetPasswordModal"
import { getInitials } from "@kiosk/front/utils/helpers"

export const Account = () => {
  const { t } = useTranslation(["common", "settings"])
  // TODO: update when our DB model is implemented
  const user = useUser()
  const { signOut } = useClerk()
  const [opened, { open, close }] = useDisclosure(false)

  const form = useForm<UserBody>({
    validate: zodResolver(userSchema),
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarUrl: user.avatarUrl,
      locale: user.locale as Locale,
    },
  })

  const { mutateAsync: deleteAccount, isPending: isDeletingAccount } =
    useDeleteUserMutation(signOut)
  const { mutateAsync: updateAccount, isPending: isUpdatingAccount } =
    useUpdateUserMutation(user.id)

  const handleDeleteAccount = () => {
    void deleteAccount(user.id)
    close()
  }

  return (
    <PageLayout>
      <Stack w="100%" align="center">
        <Stack w={650} gap={24}>
          <Text fw={500} c="black">
            {t("settings:account.profileImage")}
          </Text>
          <Group gap={24}>
            <PictureUploader
              destination="user-avatars"
              placeholder={getInitials(user.firstName, user.lastName)}
              {...form.getInputProps("avatarUrl")}
            />
          </Group>
          <Divider />
          <Text fw={500} c="black">
            {t("settings:account.yourInformation")}
          </Text>
          <form
            id="update-user"
            onSubmit={form.onSubmit((values) =>
              updateAccount({ userId: user.id, data: values }),
            )}
          >
            <Stack gap={24}>
              <TextInput
                label={t("settings:account.firstName")}
                placeholder="John"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                label={t("settings:account.lastName")}
                placeholder="Doe"
                {...form.getInputProps("lastName")}
              />
              <TextInput
                label={t("settings:account.email")}
                placeholder="john.doe@gmail.com"
                {...form.getInputProps("email")}
                disabled
              />
              <TextInput
                label={t("settings:account.phoneNumber")}
                placeholder="+33 1 09 75 83 51"
                {...form.getInputProps("phone")}
              />
            </Stack>
          </form>
          <Divider />
          <Group justify="space-between">
            <Group gap={4}>
              {user.lastPasswordChangeAt && (
                <>
                  <Text fw={500}>{t("settings:account.password")}</Text>
                  <Text>{`(${t("settings:account.passwordLastReset")} ${dayjs(user.lastPasswordChangeAt).format("DD/MM/YYYY")})`}</Text>
                </>
              )}
            </Group>
            <ResetPasswordModal />
          </Group>
          <Divider />
          <Group justify="space-between">
            <Text c="black" fw={500}>
              {t("settings:account.permanentlyDeleteAccount")}
            </Text>
            <Button
              c="red.8"
              variant="outline"
              style={{ borderColor: "var(--mantine-color-red-8)" }}
              loading={isDeletingAccount}
              onClick={open}
            >
              {t("settings:account.deleteAccount")}
            </Button>
            <Modal.Root centered opened={opened} onClose={close}>
              <Modal.Overlay />
              <Modal.Content p={8} style={{ borderRadius: rem(14) }}>
                <Modal.Header>
                  <Modal.Title c="gray.9" fz="xxl" fw={600}>
                    {t("settings:account.deleteAccount")}
                  </Modal.Title>
                  <Modal.CloseButton />
                </Modal.Header>
                <Modal.Body>
                  <Text fz="md" c="gray.7" mb={34}>
                    {t("settings:account.deleteAccountDescription")}
                  </Text>
                  <Group justify="flex-end">
                    <Button color="gray" variant="outline" onClick={close}>
                      {t("common:buttons.cancel")}
                    </Button>
                    <Button color="red.8" onClick={handleDeleteAccount}>
                      {t("settings:account.deleteAccount")}
                    </Button>
                  </Group>
                </Modal.Body>
              </Modal.Content>
            </Modal.Root>
          </Group>
          <Button
            type="submit"
            form="update-user"
            w="fit-content"
            loading={isUpdatingAccount}
            disabled={!form.isValid()}
          >
            {t("settings:account.save")}
          </Button>
        </Stack>
      </Stack>
    </PageLayout>
  )
}
