import { Button, Menu, Stack, Table, Text } from "@mantine/core"
import {
  IconChevronDown,
  IconChevronRight,
  IconPlus,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { CategoryResponses } from "@kiosk/types/endpoints/categories"
import { TaskResponses } from "@kiosk/types/endpoints/tasks"

import { StatusPill } from "@kiosk/front/components/StatusPill"

interface TaskStatusMenuProps {
  updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  taskStatus: "TODO" | "IN_PROGRESS" | "DONE"
  taskId: string
}

const TaskStatusMenu = (props: TaskStatusMenuProps) => {
  const { t } = useTranslation("task")
  return (
    <Menu>
      <Menu.Target>
        <Button p={0} variant="transparent" color="gray.6">
          <StatusPill status={props.taskStatus} />
          <IconChevronDown width={20} height={20} />
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={0} pt={8} pb={8}>
        <Stack gap={8}>
          <Menu.Item
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "TODO" },
              })
            }
            px={14}
            py={0}
            fz="sm"
          >
            {t("status.todo")}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "IN_PROGRESS" },
              })
            }
            px={14}
            py={0}
            fz="sm"
          >
            {t("status.inProgress")}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() =>
              props.updateTaskStatus({
                id: props.taskId,
                data: { status: "DONE" },
              })
            }
            px={14}
            py={0}
            fz="sm"
          >
            {t("status.done")}
          </Menu.Item>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  )
}

interface TaskRowProps {
  updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  openEditTaskModal: (value: string) => void
  taskStatus: "TODO" | "IN_PROGRESS" | "DONE"
  taskId: string
  taskName: string
  taskOwner: string
  taskDueDate: string
}

export const CategoryTaskRow = (props: TaskRowProps) => {
  return (
    <Table.Tr>
      <Table.Td>
        <TaskStatusMenu
          updateTaskStatus={props.updateTaskStatus}
          taskId={props.taskId}
          taskStatus={props.taskStatus}
        />
      </Table.Td>
      <Table.Td>{props.taskName}</Table.Td>
      <Table.Td>{props.taskOwner}</Table.Td>
      <Table.Td>{props.taskDueDate}</Table.Td>
      <Table.Td>
        <Button
          variant="transparent"
          c="gray.5"
          onClick={() => props.openEditTaskModal(props.taskId)}
        >
          <IconChevronRight />
        </Button>
      </Table.Td>
    </Table.Tr>
  )
}

interface CategoryTaskTableColumnProps {
  width?: number
  children?: React.ReactNode | string
}

const CategoryTaskTableColumn = (props: CategoryTaskTableColumnProps) => {
  return (
    <Table.Th
      w={props.width}
      px={10}
      py={9}
      h={44}
      fz="sm"
      lh="md"
      fw={500}
      style={{
        borderInlineEnd: "none",
      }}
    >
      {props.children}
    </Table.Th>
  )
}

interface CategoryTaskTableProps {
  updateTaskStatus: (payload: TaskResponses.UpdateTaskPayload) => void
  openEditTaskModal: (value: string) => void
  openCreateTask: () => void
  tasks: CategoryResponses.GetCategoriesAndTasks["data"][number]["tasks"]
}
export const CategoryTaskTable = (props: CategoryTaskTableProps) => {
  const { t } = useTranslation("task")
  return (
    <Table highlightOnHover withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          <CategoryTaskTableColumn width={100}>
            {t("fields.status")}
          </CategoryTaskTableColumn>
          <CategoryTaskTableColumn>{t("fields.task")}</CategoryTaskTableColumn>
          <CategoryTaskTableColumn width={200}>
            {t("fields.owner")}
          </CategoryTaskTableColumn>
          <CategoryTaskTableColumn width={200}>
            {t("fields.dueDate")}
          </CategoryTaskTableColumn>
          <CategoryTaskTableColumn width={100} />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {props.tasks.map((task, index) => (
          <CategoryTaskRow
            key={index}
            taskDueDate={
              task.dueDate ? dayjs(task.dueDate).format("MMM, YYYY") : ""
            }
            taskName={task.name}
            taskOwner={
              task.owner
                ? `${task.owner.firstName} ${task.owner.lastName}`
                : t("noAssignee")
            }
            taskStatus={task.status}
            taskId={task.id}
            updateTaskStatus={props.updateTaskStatus}
            openEditTaskModal={() => props.openEditTaskModal(task.id)}
          />
        ))}
      </Table.Tbody>
      <Table.Tfoot>
        <Table.Tr>
          <Table.Td>
            <Button
              fw={400}
              variant="transparent"
              color="green"
              onClick={props.openCreateTask}
            >
              <IconPlus />
              &nbsp;
              <Text fz="sm" c="gray.6">
                {t("newTask")}
              </Text>
            </Button>
          </Table.Td>
        </Table.Tr>
      </Table.Tfoot>
    </Table>
  )
}
