import { Anchor, Flex, Stack, Text } from "@mantine/core"
import _ from "lodash"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { DatoDisclosureRequirementResponse } from "@kiosk/types/data/dato"

import {
  useDisclosureRequirementAnswersQuery,
  useMaterialityQuery,
} from "@kiosk/front/api/reports/index"
import { DatoStructuredText } from "@kiosk/front/components/DatoStructuredText"
import { CSRDForm } from "@kiosk/front/components/csrd/CSRDForm"
import { EsrsBadge } from "@kiosk/front/components/csrd/EsrsBadge"
import { MultiQueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import config, { findEsrsByCode } from "@kiosk/front/features/iro2/config"

import { MaterialitySwitch } from "./MaterialSwitch"

export const DisclosureRequirementFormLayout = ({
  disclosureRequirement,
}: DatoDisclosureRequirementResponse) => {
  const { esrsCode, name, instructions } = disclosureRequirement

  const disclosureRequirementAnswersQuery =
    useDisclosureRequirementAnswersQuery(disclosureRequirement.id)

  const esrs = extractEsrs(esrsCode)
  const hasMaterialitySwitch = esrs !== "ESRS2"

  const materialityQuery = useMaterialityQuery()

  const queries = {
    nonMaterialEsrs: {
      query: materialityQuery,
      allowEmptyArray: true,
    },
    disclosureRequirementAnswers: {
      query: disclosureRequirementAnswersQuery,
      allowEmptyArray: true,
    },
  }

  // TODO: persist in DB.
  const [isMaterial, setIsMaterial] = useState(true)

  return (
    <MultiQueryWrapper absolute fluid queries={queries}>
      {({ nonMaterialEsrs, disclosureRequirementAnswers }) => {
        const _esrs = findEsrsByCode(esrs)

        const esrsIsMaterial =
          hasMaterialitySwitch && !nonMaterialEsrs.includes(_esrs?.datoId ?? "")

        return (
          <Stack flex={1} pos="relative">
            <Stack p={24} align="stretch" justify="center">
              <Stack pb="xl">
                <Flex gap={16} wrap="nowrap" align="center">
                  <EsrsBadge topic={esrsCode} />
                  <Text flex="1" fz="xl" c="gray.9" fw={600} lineClamp={2}>
                    {name}
                  </Text>
                  {hasMaterialitySwitch ? (
                    <MaterialitySwitch
                      disabled={!esrsIsMaterial}
                      value={esrsIsMaterial && isMaterial}
                      onClick={() => setIsMaterial(!isMaterial)}
                    />
                  ) : null}
                </Flex>
                <DatoStructuredText content={instructions} />
              </Stack>
              {!hasMaterialitySwitch || esrsIsMaterial ? (
                <CSRDForm
                  key={disclosureRequirement.id}
                  initialValues={disclosureRequirementAnswers}
                  disclosureRequirement={disclosureRequirement}
                />
              ) : (
                <NonMaterialEsrsComponent />
              )}
            </Stack>
          </Stack>
        )
      }}
    </MultiQueryWrapper>
  )
}

const NonMaterialEsrsComponent = () => {
  const navigate = useNavigate()

  return (
    <Stack>
      <Text>This ESRS was determined to be non-material to your business.</Text>
      <Text>
        You can edit it in the{" "}
        <Anchor
          onClick={() =>
            navigate(
              `/csrd/${config.esrs2}/${config.disclosureRequirement.datoId}`,
            )
          }
        >
          IRO-2 page
        </Anchor>
        .
      </Text>
    </Stack>
  )
}

const extractEsrs = (code: string): string => {
  // ESRS2.BP-1 => ESRS2
  if (code.includes(".")) {
    return _.head(code.split("."))!
  }
  // E1-1 => E1
  if (code.includes("-")) {
    return _.head(code.split("-"))!
  }
  return code
}
