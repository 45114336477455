import { Badge, rem } from "@mantine/core"

interface EsrsBadgeProps {
  topic: string
}

export function EsrsBadge({ topic }: EsrsBadgeProps) {
  return (
    <Badge
      h={30}
      fz="sm"
      px={20}
      style={{
        borderColor: "var(--mantine-color-gray-2)",
        borderWidth: rem(1),
      }}
      color="white"
      c="gray.5"
      fw={400}
      fs="italic"
    >
      {topic}
    </Badge>
  )
}
