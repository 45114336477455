import { Badge, Group, Loader } from "@mantine/core"
import { useMemo } from "react"

import { useListDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { useSurveyQuestionsDataModel } from "@kiosk/front/api/surveys/questions/model/surveyQuestionsDataModel"

type Props = {
  questionDatoId: string
}

export const DimensionsCell = ({ questionDatoId }: Props) => {
  // TODO: that could be fetched upper in the react tree
  const { data: allDimensions } = useListDimensionsQuery()
  const { data: csrdCategories } = useSurveyQuestionsDataModel()

  const dimensions = useMemo(() => {
    if (!allDimensions || !csrdCategories) return []

    const allQuestions = csrdCategories.flatMap(({ topics }) =>
      topics.flatMap(({ disclosureRequirements }) =>
        disclosureRequirements.flatMap(({ questions }) => questions),
      ),
    )

    const datoQuestion = allQuestions.find(({ id }) => id === questionDatoId)

    return allDimensions.filter((dimension) =>
      datoQuestion?.dimensionIds.includes(dimension.id),
    )
  }, [allDimensions, csrdCategories, questionDatoId])

  // TODO: handle all query states
  if (!dimensions) return <Loader size="xs" />

  return (
    <Group gap={8}>
      {dimensions.map((dimension) => (
        <Badge
          key={dimension.id}
          tt="none"
          fw={500}
          size="lg"
          color="gray"
          variant="light"
        >
          {dimension.label}
        </Badge>
      ))}
    </Group>
  )
}
