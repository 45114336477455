import { BooleanRecord } from "@kiosk/types/data/dato"

import { MaterialitySwitch } from "../MaterialSwitch"
import { PlainBooleanChoice } from "../PlainBooleanChoice"

import { InputProps } from "./types"

type DisplayType = "switch" | "plain"

interface BooleanQuestionInputProps extends InputProps<BooleanRecord> {
  display: DisplayType
}

export const BooleanQuestion = ({
  content,
  formProps,
  display,
}: BooleanQuestionInputProps) => {
  const { yesLabel = "Yes" } = content
  const isOn = yesLabel === "Yes"

  return display === "plain"
    ? PlainBooleanChoice({ content, formProps })
    : MaterialitySwitch({
        value: isOn,
        onClick: () => formProps.onChange(!isOn),
      })
}
