import { Flex, Group, Stack, Text, TextInput, Title } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { USER_ROLES } from "@kiosk/types/user"

import { setupUserAccountSchema } from "@kiosk/shared/schemas/users"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { useSetupUserAccountMutation } from "@kiosk/front/api/users/setupAccount"
import { PasswordInput } from "@kiosk/front/components/PasswordInput"
import { routes } from "@kiosk/front/utils/constants/routes"

import { Footer } from "./Footer"

type Props = {
  setActive: Dispatch<SetStateAction<number>>
}

export const UserCreationStep = ({ setActive }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation(["common"])
  const { user } = useAuthState()
  const { getInputProps, errors, values, isValid, validate, clearFieldError } =
    useForm({
      validate: zodResolver(setupUserAccountSchema),
      initialValues: {
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        password: "",
        confirmPassword: "",
      },
      validateInputOnBlur: true,
      validateInputOnChange: true,
    })

  const { mutateAsync: setupUserAccountMutation, isPending } =
    useSetupUserAccountMutation(() =>
      user?.role === USER_ROLES.COMPANY_ADMIN &&
      user.company.status !== "ONBOARDED"
        ? setActive(1)
        : navigate(routes.CONGRATULATIONS.path),
    )

  const { firstName, lastName, password, confirmPassword } = values

  const handleUserCreation = async () => {
    if (password === confirmPassword && user) {
      await setupUserAccountMutation({
        userId: user?.id,
        data: {
          firstName,
          lastName,
          password,
          confirmPassword,
        },
      })
    }
  }

  useEffect(() => {
    // this is needed because the validator schema only checks whether the passwords match after all checks have
    // passed and only if the confirmPassword field is touched. This will validate the form again if the user
    // changes the password field again after inputting the confirmPassword
    // TODO
    if (password && confirmPassword && password !== confirmPassword) {
      validate()
    }
    if (password === confirmPassword && errors?.confirmPassword) {
      clearFieldError("confirmPassword")
    }
  }, [errors, clearFieldError, password, confirmPassword, validate])

  return (
    <Flex direction="column" flex={1} align="center" gap={40}>
      <Stack flex={1} h="100%" maw={506} w="100%" gap={48}>
        <Stack w="100%" gap={16}>
          <Title fz="xxl" fw={600} c="black">
            &#128075; &nbsp;
            {t("welcome")}
          </Title>
          <Text c="gray.5" fz="md">
            {t("welcomeDescription")}
          </Text>
        </Stack>
        <form
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flex: "1 1 0%",
          }}
        >
          <Stack flex={1} w="100%" gap={32}>
            <Group grow gap={16} align="flex-start">
              <TextInput
                label={t("fields.firstNameLabel")}
                placeholder={t("fields.firstNamePlaceholder")}
                type="text"
                {...getInputProps("firstName")}
              />
              <TextInput
                error
                label={t("fields.lastNameLabel")}
                placeholder={t("fields.lastNamePlaceholder")}
                type="text"
                {...getInputProps("lastName")}
              />
            </Group>
            <PasswordInput
              label={t("fields.passwordLabel")}
              {...getInputProps("password")}
            />
            <PasswordInput
              label={t("fields.confirmPasswordLabel")}
              {...getInputProps("confirmPassword")}
            />
          </Stack>
        </form>
      </Stack>
      <Footer
        isLoading={isPending}
        disableContinue={!isValid()}
        onContinue={handleUserCreation}
      />
    </Flex>
  )
}
