import { Button, FileButton } from "@mantine/core"
import { useTranslation } from "react-i18next"

import { useUploadPrivateFileMutation } from "@kiosk/front/api/uploadFile"

export const UploadAnswerFileButton = ({
  onSuccess,
}: {
  onSuccess: (name: string, path: string, signedUrl: string) => void
}) => {
  const { t } = useTranslation("survey")

  const { mutateAsync: uploadFile, isPending: isUploading } =
    useUploadPrivateFileMutation({
      destination: "survey-answer-file/",
      sendNotificationOnError: true,
    })

  return (
    <FileButton
      onChange={async (f) => {
        const response = await uploadFile(f)
        if (response)
          onSuccess(
            response.file.originalname,
            response.file.path,
            response.signedUrl,
          )
      }}
    >
      {(props) => (
        <Button variant="outline" color="gray" loading={isUploading} {...props}>
          {t("actions.upload")}
        </Button>
      )}
    </FileButton>
  )
}
