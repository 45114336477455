import { Flex, Text } from "@mantine/core"
import { IconTag } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

export const AssignmentsPlaceholder = () => {
  const { t } = useTranslation("survey")

  return (
    <Flex
      style={{
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "var(--mantine-color-gray-5)",
        borderRadius: 6,
        marginTop: 0,
        alignSelf: "stretch",
      }}
      bg="gray.0"
      align="center"
      justify="center"
      p={16}
      flex={1}
      w="100%"
    >
      <Flex direction="column" gap={16}>
        <IconTag />
        <Text w="208px" c="gray.5">
          {t("assignationsPlaceholder")}
        </Text>
      </Flex>
    </Flex>
  )
}
