import { useMemo } from "react"

import { CSRDQuestionAnswer } from "@kiosk/types/csrd"
import { Content } from "@kiosk/types/data/dato"

import { BooleanQuestion } from "@kiosk/front/components/csrd/questions/BooleanQuestion"
import { CardQuestion } from "@kiosk/front/components/csrd/questions/CardQuestion"
import { ChoiceQuestion } from "@kiosk/front/components/csrd/questions/ChoiceQuestion"
import { DateQuestion } from "@kiosk/front/components/csrd/questions/DateQuestion"
import { NumberQuestion } from "@kiosk/front/components/csrd/questions/NumberQuestion"
import { TextBlockQuestion } from "@kiosk/front/components/csrd/questions/TextBlockQuestion"
import { UploadQuestion } from "@kiosk/front/components/csrd/questions/UploadQuestion"
import { InputProps } from "@kiosk/front/components/csrd/questions/types"
import { EnrichedDimensionBreakdown } from "@kiosk/front/utils/dimensions"

type Props = InputProps<Content> & {
  datoQuestionId: string
  breakdown?: EnrichedDimensionBreakdown
}

export const CSRDQuestionContent = ({
  datoQuestionId,
  content,
  breakdown,
  formProps,
}: Props) => {
  const augmentedFormProps = useMemo(() => {
    return {
      ...formProps,
      value: formProps.value?.answer.value,
      // TODO: can we have a strong typing here, without duplicating the logic on all children components?
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange: (value: any) => {
        if (content.__typename === "TableRecord") return () => {}
        else {
          const unit = content.__typename === "NumberRecord" ? content.unit : ""
          return formProps.onChange({
            datoQuestionId,
            answer: { type: content.__typename, value, unit },
            dimensions:
              breakdown?.map((breakdownItem) => ({
                dimensionId: breakdownItem.dimensionId,
                optionId: breakdownItem.optionId,
              })) ?? [],
          } satisfies CSRDQuestionAnswer)
        }
      },
    }
  }, [breakdown, content, datoQuestionId, formProps])

  switch (content.__typename) {
    case "BooleanRecord":
      return (
        <BooleanQuestion
          content={content}
          formProps={augmentedFormProps}
          display="plain"
        />
      )
    case "TextBlockRecord":
      return (
        <TextBlockQuestion content={content} formProps={augmentedFormProps} />
      )
    case "NumberRecord":
      return <NumberQuestion content={content} formProps={augmentedFormProps} />
    case "DateRecord":
      return <DateQuestion content={content} formProps={augmentedFormProps} />
    case "ChoiceRecord":
      return <ChoiceQuestion content={content} formProps={augmentedFormProps} />
    case "ConditionalAnswerRecord":
      return (
        <BooleanQuestion
          content={content.initialQuestion}
          formProps={augmentedFormProps}
          display="plain"
        />
      )
    case "CardRecord":
      return <CardQuestion id={datoQuestionId} />
    case "UploadRecord":
      return <UploadQuestion id={datoQuestionId} />
    case "TableRecord":
      return null
  }
}
