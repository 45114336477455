import { Flex, Text, TextInput, Title } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import _ from "lodash"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { useCreateGeoDimensionMutation } from "@kiosk/front/api/onboarding/createGeoDimension"

import { Footer } from "./Footer"
import { StructureDefintion } from "./StructureDefintion"
import { TreeStructure } from "./TreeStructure"
import { GeoDimensionForm, geoDimensionSchema } from "./types"

type Props = {
  setActive: Dispatch<SetStateAction<number>>
}

export const GeoDimensionStep = ({ setActive }: Props) => {
  const { t } = useTranslation("onboarding")
  const { user } = useAuthState()

  const form = useForm<GeoDimensionForm>({
    initialValues: { tree: [] },
    validate: zodResolver(geoDimensionSchema),
  })

  const { mutateAsync: createGeoDimension, isPending } =
    useCreateGeoDimensionMutation(() => setActive(2))

  const onSubmit = async () => {
    const flattenedTree = form.values.tree.flatMap((region) => {
      return (region.children ?? []).concat(_.omit(region, "children"))
    })
    await createGeoDimension(flattenedTree)
  }

  return (
    <Flex direction="column" flex={1} w="100%" align="center" gap={40}>
      <Flex
        direction="column"
        w={837}
        justify="flex-start"
        align="flex-start"
        gap={16}
      >
        <Title c="gray.9" fz="xxl" fw={600}>
          🕵️‍&nbsp;
          {t("geoDimension.title")}
        </Title>
        <Text c="gray.5" fz="md">
          {t("geoDimension.description")}
        </Text>
      </Flex>
      <Flex flex={1} gap={30}>
        <Flex direction="column" w={470} gap={16}>
          <Text fz="lg" fw={600} c="gray.9">
            {t("createYourStructure")}
          </Text>
          <Flex
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "var(--mantine-color-gray-3)",
              borderRadius: 8,
            }}
            direction="column"
            p={16}
          >
            <TextInput readOnly value={user?.company.name ?? ""} />
            <TreeStructure
              treeLevel={form.values.tree}
              form={form}
              formKey="tree"
              depth={1}
            />
          </Flex>
        </Flex>
        <Flex direction="column" w={337} gap={16}>
          <Text fz="lg" fw={600} c="gray.9">
            {t("defineEachDimension")}
          </Text>
          <Flex
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "var(--mantine-color-gray-3)",
              borderRadius: 8,
            }}
            bg="gray.0"
            direction="column"
            p={16}
          >
            <StructureDefintion />
          </Flex>
        </Flex>
      </Flex>
      <Footer
        isLoading={isPending}
        onContinue={onSubmit}
        disableContinue={!form.isValid() || form.values.tree.length === 0}
      />
    </Flex>
  )
}
