import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { SurveyResponses } from "@kiosk/types/endpoints/surveys"

import { UpdateSurveyBody } from "@kiosk/shared/schemas/surveys"

import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

import { surveysKeys } from "./surveysKeys"

export const useUpdateSurveyMutation = (id: string) => {
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (
      data: UpdateSurveyBody,
    ): Promise<SurveyResponses.Survey> => {
      const response = await apiClient.put(`/surveys/${id}`, data)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      queryClient.invalidateQueries({
        queryKey: surveysKeys.get(id),
        refetchType: "all",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: t("messages.error"),
        message,
        color: "red",
      })
    },
  })
}
