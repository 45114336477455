import { useQuery } from "@tanstack/react-query"
import _, { keyBy } from "lodash"

import { CsrdCategory } from "@kiosk/types/data/dato"

import {
  ALL_QUESTIONS,
  ALL_NUMBERS,
  ALL_QUESTIONS_TO_CSRD_CATEGORY,
} from "@kiosk/shared/datocms/queries"

import { makeDatoPaginatedRequest } from "@kiosk/front/api/datocms/datocms"
import {
  DatoAllQuestionsResponse,
  DatoAllQuestionsToCsrdCategoryResponse,
  DatoAllNumbersResponse,
} from "@kiosk/front/api/datocms/types"

import { findOrCreate } from "./utils"

const buildQuestionDependencies = (allQuestions: DatoAllQuestionsResponse) => {
  const mapping: Record<string, string[]> = {}
  for (const entry of allQuestions.allQuestions) {
    if (entry._allReferencingQuestions.length === 0) continue
    mapping[entry.id] = entry._allReferencingQuestions.map(({ id }) => id)
  }
  return mapping
}

const insertQuestionToCsrdCategory = (
  allCategories: CsrdCategory[],
  questionToCsrdCategory: DatoAllQuestionsToCsrdCategoryResponse["allQuestions"][number],
  numberQuestion: DatoAllNumbersResponse["allNumbers"][number],
  dimensionIds: string[],
) => {
  const disclosureRequirement =
    questionToCsrdCategory._allReferencingDisclosureRequirements[0]
  const csrdTopic = disclosureRequirement?._allReferencingCsrdTopics[0]
  const csrdCategory = csrdTopic?._allReferencingCsrdCategories[0]
  if (!csrdCategory) return

  const targetCsrdCategory = findOrCreate(
    allCategories,
    ({ id }) => id === csrdCategory.id,
    {
      id: csrdCategory.id,
      name: csrdCategory.name,
      order: csrdCategory.order,
      baseColor: csrdCategory.baseColor,
      topics: [],
    },
  )

  const targetCsrdTopic = findOrCreate(
    targetCsrdCategory.topics,
    ({ id }) => id === csrdTopic.id,
    {
      id: csrdTopic.id,
      name: csrdTopic.name,
      disclosureRequirements: [],
    },
  )

  const targetDisclosureRequirement = findOrCreate(
    targetCsrdTopic.disclosureRequirements,
    ({ id }) => id === disclosureRequirement.id,
    {
      id: disclosureRequirement.id,
      name: disclosureRequirement.name,
      questions: [],
      esrs_code: disclosureRequirement.esrs_code,
    },
  )

  targetDisclosureRequirement.questions.push({
    unit: numberQuestion.unit,
    numberType: numberQuestion.numberType,
    dimensionIds,
    ...numberQuestion._allReferencingQuestions[0],
  })
}

export const buildCsrdCategories = (
  allQuestions: DatoAllQuestionsResponse,
  numberQuestions: DatoAllNumbersResponse,
  allQuestionsToCsrdCategory: DatoAllQuestionsToCsrdCategoryResponse,
) => {
  const result: CsrdCategory[] = []
  const questionDependencies = buildQuestionDependencies(allQuestions)
  const questionById = keyBy(allQuestions.allQuestions, "id")

  for (const numberQuestion of numberQuestions.allNumbers) {
    const stack: { questionId: string; dimensionIds: string[] }[] =
      numberQuestion._allReferencingQuestions.map(({ id }) => ({
        questionId: id,
        dimensionIds: [],
      }))

    let elem: (typeof stack)[number] | undefined
    while ((elem = stack.pop()) !== undefined) {
      const { questionId, dimensionIds } = elem

      if (questionId in questionDependencies) {
        if (questionDependencies[questionId].length > 1) {
          console.error(
            `Invalid question: question ${questionId} has several parents: ${questionDependencies[questionId]}`,
          )
          continue
        }
        for (const nextQuestionId of questionDependencies[questionId]) {
          const nextQuestionDimensionIds = questionById[
            nextQuestionId
          ].dimensions.map(({ id }) => id)
          stack.push({
            questionId: nextQuestionId,
            dimensionIds: _.uniq([
              ...dimensionIds,
              ...nextQuestionDimensionIds,
            ]),
          })
        }
      } else {
        const questionToCsrdCategory =
          allQuestionsToCsrdCategory.allQuestions.find(
            ({ id }) => id === questionId,
          )
        if (!questionToCsrdCategory) continue

        insertQuestionToCsrdCategory(
          result,
          questionToCsrdCategory,
          numberQuestion,
          dimensionIds,
        )
      }
    }
  }

  return result
}

export const getSurveyQuestionsDataModel = async (): Promise<
  CsrdCategory[]
> => {
  const [allQuestions, numberQuestions, allQuestionsToCsrdCategory] =
    await Promise.all([
      makeDatoPaginatedRequest<DatoAllQuestionsResponse>({
        query: ALL_QUESTIONS,
        fieldName: "allQuestions",
        metaFieldName: "_allQuestionsMeta",
      }),
      makeDatoPaginatedRequest<DatoAllNumbersResponse>({
        query: ALL_NUMBERS,
        fieldName: "allNumbers",
        metaFieldName: "_allNumbersMeta",
      }),
      makeDatoPaginatedRequest<DatoAllQuestionsToCsrdCategoryResponse>({
        query: ALL_QUESTIONS_TO_CSRD_CATEGORY,
        fieldName: "allQuestions",
        metaFieldName: "_allQuestionsMeta",
      }),
    ])

  return buildCsrdCategories(
    allQuestions,
    numberQuestions,
    allQuestionsToCsrdCategory,
  )
}

export const useSurveyQuestionsDataModel = () => {
  return useQuery({
    queryKey: ["dato", "surveys"],
    queryFn: async () => {
      return getSurveyQuestionsDataModel()
    },
  })
}
