import { ActionIcon, ThemeIcon } from "@mantine/core"
import { IconChevronLeft } from "@tabler/icons-react"

type Props = {
  onClick?: () => void
  type?: "button" | "submit" | "reset"
}

export const BackButton = ({ onClick, type }: Props) => (
  <ActionIcon
    w={42}
    h={42}
    p={12}
    variant="outline"
    color="gray.2"
    {...(onClick ? { onClick } : {})}
    {...(type ? { type } : {})}
  >
    <ThemeIcon c="black" w={17} h={17}>
      <IconChevronLeft />
    </ThemeIcon>
  </ActionIcon>
)
