import { AppShell as BaseAppShell, Box, Stack } from "@mantine/core"
import { useMemo } from "react"
import { Outlet, matchPath, useLocation } from "react-router-dom"

import { USER_ROLES } from "@kiosk/types/user"

import { useUser } from "@kiosk/front/components/auth/UserContext"
import { routes } from "@kiosk/front/utils/constants"
import { useNavBarItems } from "@kiosk/front/utils/constants/useNavbarItems"

import { AppHeader } from "./AppHeader"
import { Logo } from "./Logo"
import { NavBarItem } from "./NavBarItem"

export const AppShell = () => {
  const navbarItems = useNavBarItems()
  const user = useUser()

  const location = useLocation()

  const isSettingsActive = useMemo(
    () => !!matchPath(routes.SETTINGS.path, location.pathname),
    [location.pathname],
  )

  if (user.role == USER_ROLES.COMPANY_CONTRIBUTOR) {
    return (
      <BaseAppShell layout="alt">
        <AppHeader />
        <BaseAppShell.Main mih="calc(100vh - 75px)">
          <Outlet />
        </BaseAppShell.Main>
      </BaseAppShell>
    )
  }

  return (
    <BaseAppShell
      navbar={{ width: 270, breakpoint: "sm" }}
      header={{ height: isSettingsActive ? 120 : 75 }}
      layout="alt"
    >
      <BaseAppShell.Navbar withBorder={false} bg="gray.0" p={12}>
        <Box p={16}>
          <Logo height={28} />
        </Box>
        <Stack py={16} gap={8}>
          {navbarItems.map((item) => (
            <NavBarItem key={item.label} {...item} />
          ))}
        </Stack>
      </BaseAppShell.Navbar>
      <AppHeader />
      <BaseAppShell.Main h="100vh">
        <Outlet />
      </BaseAppShell.Main>
    </BaseAppShell>
  )
}
