import { Button, Divider, Group, Stack, Text } from "@mantine/core"
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone"
import {
  IconCircleCheck,
  IconCloudUpload,
  IconLoader2,
  IconX,
} from "@tabler/icons-react"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { formatBlobSize } from "@kiosk/front/utils/helpers"

import MultiLineTranslation from "./MultilineTranslation"

type Props = {
  title: string
  description?: string

  uploadFile: (file: FileWithPath) => void
  uploadError: Error | null
  hasUploadError?: boolean
  isUploading?: boolean
  clearFileUrl?: () => void
  errorTitle?: string
  errorMessage?: string
}
type DropzoneStatus = "accepted" | "idle" | "rejected"

export const DashedDropzone = ({
  uploadFile,
  uploadError,
  hasUploadError,
  isUploading,
  clearFileUrl,
  title,
  description,
  errorTitle,
  errorMessage,
}: Props) => {
  const openRef = useRef<() => void>(null)
  const acceptedMimeTypes = [
    MIME_TYPES.pdf,
    MIME_TYPES.doc,
    MIME_TYPES.docx,
    MIME_TYPES.xls,
    MIME_TYPES.xlsx,
    MIME_TYPES.ppt,
    MIME_TYPES.pptx,
    "text/plain",
  ]

  const { t } = useTranslation(["common", "sources"])
  const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneStatus>("idle")
  const [file, setFile] = useState<FileWithPath[] | null>(null)

  const handleDrop = (file: FileWithPath[]) => {
    setFile(file)
    uploadFile(file[0])
    if (hasUploadError) setDropzoneStatus("rejected")
    else setDropzoneStatus("accepted")
  }

  const handleReject = (status: DropzoneStatus) => {
    clearFileUrl && clearFileUrl()
    setFile(null)
    setDropzoneStatus(status)
  }

  const getStatusContent = () => {
    switch (true) {
      case isUploading:
        return (
          <Stack align="center">
            <IconLoader2
              stroke={2}
              height={52}
              width={52}
              color="var(--mantine-color-green-1)"
            />
            <Text size="lg" fw={600} c="black" inline>
              {t("onboarding.uploadingFile")}
            </Text>
          </Stack>
        )

      case dropzoneStatus === "idle" && !hasUploadError && !isUploading:
        return (
          <Stack align="center">
            <IconCloudUpload
              stroke={1.5}
              width={52}
              height={52}
              color="var(--mantine-color-dimmed)"
            />
            <Text size="lg" fw={600} c="black" inline>
              {title}
            </Text>
            {description ? (
              <Text size="sm" mt={7} c="gray.5" fz="md" ta="center">
                <MultiLineTranslation text={description} />
              </Text>
            ) : null}
          </Stack>
        )

      case dropzoneStatus === "accepted" && !hasUploadError && !isUploading:
        return (
          <Stack align="center">
            <IconCircleCheck
              stroke={1.5}
              height={52}
              width={52}
              color="var(--mantine-color-green-6)"
            />
            <Text size="lg" fw={600} c="black" mb={8} ta="center" inline>
              {t("onboarding.successUploadDsnFile")}
            </Text>
            <Group justify="center">
              <Text size="sm" c="gray.5" fz="md" ta="center">
                {file?.[0].name} {formatBlobSize(file?.[0].size || 0)}
              </Text>
              <Button
                style={{ pointerEvents: "all" }}
                size="xs"
                variant="transparent"
                onClick={() => handleReject("idle")}
                c="black"
              >
                <IconX />
              </Button>
            </Group>
          </Stack>
        )

      case (dropzoneStatus === "rejected" || hasUploadError) && !isUploading:
        return (
          <Stack align="center">
            <IconCloudUpload
              stroke={1.5}
              height={52}
              width={52}
              color="var(--mantine-color-dimmed)"
            />
            <Text size="lg" fw={600} c="black" inline>
              {errorTitle ?? t("onboarding.errorUploadDsnFile")}
            </Text>
            <Text size="sm" mt={7} c="red" fz="md">
              {uploadError
                ? errorMessage ?? uploadError?.message
                : t("onboarding.errorUploadDsnFileDescription")}
            </Text>
          </Stack>
        )

      default:
        return null
    }
  }

  return (
    <>
      <Divider variant="dashed" size="sm" />
      <Dropzone
        openRef={openRef}
        activateOnClick={false}
        onDrop={handleDrop}
        onReject={() => handleReject("rejected")}
        multiple={false}
        accept={acceptedMimeTypes}
      >
        <Group justify="center" mih={240} style={{ pointerEvents: "none" }}>
          <Divider variant="dashed" size="sm" orientation="vertical" />
          <Stack flex={1} align="center">
            {getStatusContent()}
          </Stack>
          <Divider variant="dashed" size="sm" orientation="vertical" />
        </Group>
      </Dropzone>

      <Divider variant="dashed" size="sm" />
      <Group justify="center" mt="md" pos="relative" bottom={38}>
        <Button
          onClick={() => openRef.current?.()}
          disabled={false}
          loading={false}
          color="green.6"
          c="white"
        >
          <Text fw={500} size="md">
            {t("buttons.selectFile")}
          </Text>
        </Button>
      </Group>
    </>
  )
}
