import { Tooltip } from "@mantine/core"
import { IconHelpCircleFilled } from "@tabler/icons-react"

import { colors } from "@kiosk/front/assets/theme/colors"

interface Props {
  content: string
}

export const QuestionTooltip = ({ content }: Props) => (
  <Tooltip label={content}>
    <IconHelpCircleFilled color={colors.gray[4]} size={16} />
  </Tooltip>
)
