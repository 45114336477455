import { Badge } from "@mantine/core"

interface TopicBadgeProps {
  topic: string
}

/**
 * Shows a gray badge with the code of topic (or disclosure requirement).
 * E.g. "E1" or "E1-1"
 */
export const TopicBadge = ({ topic }: TopicBadgeProps) => (
  <Badge
    h={26}
    fz="xxs"
    w={70}
    px={4}
    color="gray.1"
    c="gray.7"
    fw={400}
    fs="italic"
  >
    {topic}
  </Badge>
)
