import { Group, Stack } from "@mantine/core"
import { useNavigate } from "react-router-dom"

import { Company } from "@kiosk/types/prisma-client"

import { useCompaniesQuery } from "@kiosk/front/api/companies/queryCompanies"
import { Table } from "@kiosk/front/components/generic"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { CompanyForm } from "@kiosk/front/pages/Companies/CompanyForm"
import { useCompaniesColumns } from "@kiosk/front/pages/Companies/columns"
import { routes } from "@kiosk/front/utils/constants"

export const Companies = () => {
  const navigate = useNavigate()

  const handleOnRowClick = (record: Company) =>
    navigate(routes.USERS.path.replace(":id", record.id))

  const companiesQuery = useCompaniesQuery()

  const columns = useCompaniesColumns()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={companiesQuery}>
        {({ data: companies }) => (
          <Stack gap={30}>
            <Group justify="flex-end">
              <CompanyForm />
            </Group>
            <Table
              columns={columns}
              dataSource={companies.data}
              onRowClick={handleOnRowClick}
            />
          </Stack>
        )}
      </QueryWrapper>
    </PageLayout>
  )
}
