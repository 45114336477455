import { i18n } from "@kiosk/i18n/index"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"

import {
  DatoDisclosureRequirementNamesResponse,
  DatoDisclosureRequirementResponse,
  DatoRequest,
} from "@kiosk/types/data/dato"

import {
  ALL_CATEGORIES_TOPICS_NAMES_QUERY,
  CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
} from "@kiosk/shared/datocms/queries"

type PaginatedDatoRequest = DatoRequest & {
  fieldName: string
  metaFieldName: string
}

const PAGINATION_SIZE = 100

export const makeDatoRequest = ({ query, variables }: DatoRequest) =>
  axios.post(
    "https://graphql.datocms.com/",
    { query, variables: { locale: i18n.language, ...variables } },
    {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_DATOCMS_API_TOKEN}`,
        "X-Exclude-Invalid": "true",
        "X-Environment": "test-import-dr-2",
        ...(import.meta.env.VITE_ENVIRONMENT === "production"
          ? {}
          : { "X-Include-Drafts": "true" }),
      },
    },
  )

export const makeDatoPaginatedRequest = async <T>({
  query,
  variables,
  fieldName,
  metaFieldName,
}: PaginatedDatoRequest): Promise<T> => {
  const response = await makeDatoRequest({
    query,
    variables: {
      ...variables,
      first: PAGINATION_SIZE.toString(),
      skip: "0",
    },
  })
  const data = response.data.data
  const nbResults = data[metaFieldName].count

  const paginationPromises = Array.from(
    { length: Math.floor(nbResults / PAGINATION_SIZE) },
    (_, i) => i + 1,
  ).map(async (i) => {
    const nextResponse = await makeDatoRequest({
      query,
      variables: {
        ...variables,
        first: PAGINATION_SIZE,
        skip: PAGINATION_SIZE * i,
      },
    })
    const nextData = nextResponse.data.data

    data[fieldName] = data[fieldName].concat(nextData[fieldName])
  })

  await Promise.all(paginationPromises)

  return data
}

export const useDatoQuery = <T>({ query, variables }: DatoRequest) => {
  return useQuery<T>({
    queryKey: ["dato", query, variables],
    queryFn: async () => {
      const response = await makeDatoRequest({ query, variables })
      return response.data.data
    },
  })
}

export const useDatoDisclosureRequirement = (id: string) => {
  return useDatoQuery<DatoDisclosureRequirementResponse>({
    query: CSRD_DISCLOSURE_REQUIREMENT_BY_ID,
    variables: { id },
  })
}

export const useDatoCategoriesTopicsNames = () => {
  return useDatoQuery<DatoDisclosureRequirementNamesResponse>({
    query: ALL_CATEGORIES_TOPICS_NAMES_QUERY,
  })
}
