import { z } from "zod"

const baseTreeSchema = z.object({
  label: z.string().min(1),
  dimensionType: z.union([z.literal("region"), z.literal("site")]),
})

type TreeChildren = z.infer<typeof baseTreeSchema> & {
  children?: TreeChildren[]
}

const treeSchema: z.ZodType<TreeChildren> = baseTreeSchema.extend({
  children: z.lazy(() => treeSchema.array()).optional(),
})

export const geoDimensionSchema = z.object({
  tree: z.array(treeSchema),
})

export type GeoDimensionForm = z.infer<typeof geoDimensionSchema>
