import { Flex, Group, Stack } from "@mantine/core"
import { useEffect, useState } from "react"

import { useAuthState } from "@kiosk/front/api/account/queryMe"
import { Logo } from "@kiosk/front/components/layout/Logo"
import { OnboardingStepper } from "@kiosk/front/pages/Onboarding/OnboardingStepper"
import { UserCreationStep } from "@kiosk/front/pages/Onboarding/UserCreationStep"
import { companyStep } from "@kiosk/front/utils/constants"

import "./Onboarding.css"

export const Onboarding = () => {
  const { user } = useAuthState()
  const [active, setActive] = useState(0)
  const [companyOnboarding, setCompanyOnboarding] = useState(false)

  useEffect(() => {
    const isCompanyAdmin = user?.role === "COMPANY_ADMIN"
    const companyStatus = user?.company?.status

    if (isCompanyAdmin && companyStatus) {
      setActive(companyStep[companyStatus])
      setCompanyOnboarding(companyStatus !== "ONBOARDED")
    }
  }, [user?.role, user?.company?.status])

  return (
    <Stack mih="100vh" align="center" gap={40}>
      <Group
        mih={74}
        w="100%"
        py={16}
        px={24}
        style={{ borderBottom: "solid 1px var(--mantine-color-gray-3)" }}
        justify="center"
      >
        <Logo height={28} />
      </Group>
      {companyOnboarding ? (
        <OnboardingStepper active={active} setActive={setActive} />
      ) : (
        <Flex flex={1} direction="column" w="100%">
          <UserCreationStep setActive={setActive} />
        </Flex>
      )}
    </Stack>
  )
}
