import { Divider, Group, ScrollArea, Stack } from "@mantine/core"
import { round } from "lodash"
import { useMemo } from "react"
import { useParams } from "react-router-dom"

import { ReportsResponses } from "@kiosk/types/endpoints/reports"

import { useDisclosureRequirementCategoriesQuery } from "@kiosk/front/api/reports"
import { PageLayout } from "@kiosk/front/components/layout/PageLayout"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"
import { CSRDHeader } from "@kiosk/front/pages/CSRD/CSRDHeader"
import { CSRDSubTopicsList } from "@kiosk/front/pages/CSRD/CSRDSubTopicsList"
import { CSRDTopicsSideBar } from "@kiosk/front/pages/CSRD/CSRDTopicsSideBar"
import { getOverallProgress, getTopicById } from "@kiosk/front/services/report"

// TODO: fetch from Dato
const DEFAULT_TOPIC_ID = "MyoZq5MPSkqxl6jY1_TdNA"

// todo: don’t pipe the dato response like that
interface Props {
  categories: ReportsResponses.GetCategories
}

const CSRDCategories: React.FC<Props> = ({ categories }) => {
  const params = useParams()
  const activeTopicId = params.datoTopicId ?? DEFAULT_TOPIC_ID

  const activeTopic = useMemo(
    () => getTopicById(categories, activeTopicId),
    [activeTopicId, categories],
  )

  const progress = useMemo(() => getOverallProgress(categories), [categories])

  return (
    <Stack w="100%" h="100%">
      <CSRDHeader progress={round(progress, 0)} />
      <Group gap={0} flex={1} align="flex-start">
        <ScrollArea w="30%" h="75vh">
          <CSRDTopicsSideBar
            categories={categories}
            activeTopicId={activeTopicId}
          />
        </ScrollArea>
        <Divider orientation="vertical" color="gray.2" />
        <ScrollArea flex={1} h="75vh">
          <CSRDSubTopicsList topic={activeTopic} />
        </ScrollArea>
      </Group>
    </Stack>
  )
}

export const CSRD = () => {
  const csrdCategoriesQuery = useDisclosureRequirementCategoriesQuery()

  return (
    <PageLayout contained>
      <QueryWrapper fluid query={csrdCategoriesQuery}>
        {({ data }) => <CSRDCategories categories={data} />}
      </QueryWrapper>
    </PageLayout>
  )
}
