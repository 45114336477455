import { notifications } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { SurveyQuestionResponses } from "@kiosk/types/endpoints/surveyQuestions"

import { i18n } from "@kiosk/i18n"

import {
  CreateSurveyQuestionBody,
  createSurveyQuestionSchema,
} from "@kiosk/shared/schemas/surveyQuestions"

import { surveysKeys } from "@kiosk/front/api/surveys/surveysKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const useCreateSurveyQuestionMutation = (surveyId: string) =>
  useMutation({
    mutationFn: async (
      data: CreateSurveyQuestionBody,
    ): Promise<SurveyQuestionResponses.SurveyQuestion> => {
      const response = await apiClient.post(
        `/surveys/${surveyId}/questions`,
        await createSurveyQuestionSchema.parseAsync(data),
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: surveysKeys.list(),
      })
      await queryClient.invalidateQueries({
        queryKey: surveysKeys.get(surveyId),
        refetchType: "all",
      })
    },
    onError: ({ message }) => {
      notifications.show({
        title: i18n.t("common:messages.error"),
        message,
        color: "red",
      })
    },
  })
