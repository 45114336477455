import { useQuery } from "@tanstack/react-query"

import { TaskResponses } from "@kiosk/types/endpoints/tasks"
import { Task } from "@kiosk/types/prisma-client"

import { taskKeys } from "@kiosk/front/api/tasks/taskKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const getTask = async (id: string): Promise<Task> => {
  const response = await apiClient.get<TaskResponses.GetTask>(`/tasks/${id}`)
  return response.data
}

export const useGetTaskQuery = (id: string) => {
  return useQuery({
    queryKey: taskKeys.id(id),
    queryFn: () => getTask(id),
    enabled: !!id,
  })
}
