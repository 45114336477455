import {
  Text,
  Input,
  Pill,
  PillsInput,
  useCombobox,
  Combobox,
} from "@mantine/core"
import { groupBy } from "lodash"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { cleanUpDimensionLabel } from "@kiosk/shared/utils/dimensions/consolidateDimensions"

type Props = {
  optionIds: string[]
  dimension: ConsolidatedDimension
  onChange: (value: string[]) => void
  isCommitted: boolean
}

export const OptionsPicker = ({
  optionIds,
  dimension,
  onChange,
  isCommitted,
}: Props) => {
  const { t } = useTranslation("survey")

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  })

  const handleValueSelect = (optionId: string) =>
    onChange(
      optionIds.includes(optionId) ? optionIds : [...optionIds, optionId],
    )

  const handleValueRemove = (optionId: string) =>
    onChange(optionIds.filter((id) => id !== optionId))

  const pills = optionIds.map((optionId) => {
    const option = dimension.options.find(({ id }) => id === optionId)
    return (
      <Pill
        key={optionId}
        onRemove={() => handleValueRemove(optionId)}
        withRemoveButton={!isCommitted}
      >
        {option?.label}
      </Pill>
    )
  })

  const comboboxOptions = useMemo(() => {
    const groupedOptions = Object.entries(groupBy(dimension.options, "group"))
    if (groupedOptions.length === 1) {
      const options = groupedOptions[0][1]
      return options.map((option) => (
        <Combobox.Option value={option.id} key={option.id}>
          <Text>{option.label}</Text>
        </Combobox.Option>
      ))
    }
    return groupedOptions.map(([groupName, groupOptions]) => (
      <Combobox.Group label={groupName} key={groupName}>
        {groupOptions.map((option) => (
          <Combobox.Option value={option.id} key={option.id}>
            <Text>{option.label}</Text>
          </Combobox.Option>
        ))}
      </Combobox.Group>
    ))
  }, [dimension.options])

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          pointer
          onClick={() => combobox.toggleDropdown()}
          label={<Text fw={500}>{cleanUpDimensionLabel(dimension.label)}</Text>}
        >
          <Pill.Group>
            {pills.length > 0 ? (
              pills
            ) : (
              <Input.Placeholder>
                {t("pickOptionsPlaceholder")}
              </Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    event.preventDefault()
                    handleValueRemove(optionIds[optionIds.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown hidden={isCommitted}>
        <Combobox.Options mah={300} style={{ overflowY: "auto" }}>
          {comboboxOptions}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
