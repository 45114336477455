import { Stack } from "@mantine/core"
import { PropsWithChildren } from "react"

export const RelatedQuestionsWrapper = ({ children }: PropsWithChildren) => (
  <Stack
    pl="xl"
    mt="sm"
    ml="xs"
    gap={32}
    style={{ borderLeft: "1px solid var(--mantine-color-gray-2)" }}
  >
    {children}
  </Stack>
)
