import {
  Card,
  Collapse,
  Group,
  Pill,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core"
import { IconChevronRight, IconChevronUp } from "@tabler/icons-react"
import { useState } from "react"

import { colors } from "@kiosk/front/assets/theme/colors"

interface CollapsibleCardProps {
  title: string
  pill?: string
  isOpened?: boolean
  children: React.ReactNode
}

export function CollapsibleCard(props: CollapsibleCardProps) {
  const [isOpened, setOpened] = useState(props?.isOpened ?? true)

  return (
    <Card bg={colors.gray[0]} withBorder>
      <Group w="100%" justify="space-between" wrap="nowrap">
        <Group mb="md">
          <UnstyledButton
            variant="outline"
            color="gray.2"
            onClick={() => setOpened((o) => !o)}
          >
            <ThemeIcon c="black">
              {isOpened ? <IconChevronUp /> : <IconChevronRight />}
            </ThemeIcon>
          </UnstyledButton>

          <Group wrap="nowrap">
            {props.pill && (
              <Pill bg={colors.teal[2]} c={colors.teal[7]}>
                {props.pill}
              </Pill>
            )}
            <Text fw="bold" size="lg">
              {props.title}
            </Text>
          </Group>
        </Group>
      </Group>
      <Collapse in={isOpened}>
        <Stack gap={18}>{props.children}</Stack>
      </Collapse>
    </Card>
  )
}
