import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import {
  CompanyRequests,
  CompanyResponses,
} from "@kiosk/types/endpoints/companies"

import { companyKeys } from "@kiosk/front/api/companies/companyKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const createCompany = async (data: CompanyRequests.CreateCompany) => {
  const response = await apiClient.post<CompanyResponses.Company>(
    "/companies",
    data,
  )
  return response.data
}

export const useCreateCompanyMutation = (onCancel: () => void) => {
  const { t } = useTranslation(["company"])
  return useMutation({
    mutationFn: (data: CompanyRequests.CreateCompany) => createCompany(data),
    onSuccess: async (data: CompanyResponses.Company) => {
      await queryClient.invalidateQueries({ queryKey: companyKeys.all() })
      showNotification({
        message: `${t("success.created")}: ` + data.name,
      })
      onCancel()
    },
    onError: (error: Error) => {
      //TODO: Handle errors
      showNotification({ message: error.message })
    },
  })
}
