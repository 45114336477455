import { get } from "lodash"

const removeAccents = (s: string) =>
  s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const searchInArray = <T>(
  values: T[],
  search: string,
  ...paths: (string | string[])[]
) => {
  if (!search) return values
  const query = removeAccents(search).toLowerCase().trim()
  return values.filter((s) =>
    paths.some((path) => {
      const value = get(s, path)
      return !!value && removeAccents(value).toLowerCase().includes(query)
    }),
  )
}

/**
 * Hashes the string into `buckets` buckets.
 *
 * @param s: string to hash
 * @param buckets: number of buckets to hash into
 * @returns an int between 0 and buckets-1
 */
export const hashToBucket = (s: string, buckets: number): number => {
  if (buckets < 1) {
    throw new Error(
      "Cannot hash: the number of buckets has to be greater or equal to 1",
    )
  }
  const sum = Array.from(s)
    .map((c) => c.charCodeAt(0))
    .reduce((acc, n) => acc + n)
  return sum % buckets
}
