import { Stack, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"

export const SurveyAnswered = () => {
  const { t } = useTranslation("survey")
  return (
    <Stack gap={24} align="center" mt={56}>
      <Text fz={64}>🎉</Text>
      <Stack align="center" gap={0}>
        <Text fz={32}>{t("congratulations")}</Text>
        <Text size="xxl" c="gray">
          {t("surveySent")}
        </Text>
      </Stack>
    </Stack>
  )
}
