import { Box, Container } from "@mantine/core"
import React from "react"

interface PageLayoutProps {
  children: React.ReactNode
  contained?: boolean // force the page to be contained in the main layout (no overlaping)
}

export const PageLayout = ({ children, contained }: PageLayoutProps) => {
  return (
    <Container h={contained ? "100%" : undefined} maw={1440} p={0} mx="auto">
      <Box w="100%" h={contained ? "100%" : undefined} p={24}>
        {children}
      </Box>
    </Container>
  )
}
