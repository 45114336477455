import { Alert, Button, Stack, Text } from "@mantine/core"
import { IconChevronRight, IconTagOff } from "@tabler/icons-react"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import { useListDimensionsQuery } from "@kiosk/front/api/dimensions/queries"
import { TypedDimension } from "@kiosk/front/api/dimensions/types"
import { EditDimensionDrawer } from "@kiosk/front/components/dimensions/EditDimensionDrawer"
import { QueryWrapper } from "@kiosk/front/components/layout/QueryWrapper"

type Props = {
  datoDimensionIds: string[]
  children: (props: {
    dimensions: ConsolidatedDimension[]
  }) => JSX.Element | null
}

const _TypedDimensionFillerWrapper = ({
  allDimensions,
  datoDimensionIds,
  children,
}: Props & { allDimensions: ConsolidatedDimension[] }) => {
  const { t } = useTranslation("dimensions")

  const [editingDimension, setEditingDimension] = useState<
    TypedDimension | undefined
  >(undefined)

  const { dimensions, typedDimensionsToFill } = useMemo(() => {
    const dimensions = allDimensions.filter((d) =>
      datoDimensionIds.includes(d.id),
    )

    const typedDimensionsToFill = dimensions.filter(
      (d): d is TypedDimension =>
        d.dimensionType === "typed" && d.options.length === 0,
    )

    return { dimensions, typedDimensionsToFill }
  }, [allDimensions, datoDimensionIds])

  if (typedDimensionsToFill.length === 0) {
    return children({ dimensions })
  }

  return (
    <Alert
      title={t("typedDimensionFiller.title")}
      c="orange.8"
      bg="orange.0"
      variant="light"
      icon={<IconTagOff />}
      styles={(theme) => ({
        root: { border: `1px solid ${theme.colors.orange[3]}` },
      })}
    >
      <Stack align="flex-start">
        <Text>{t("typedDimensionFiller.description")}</Text>
        {typedDimensionsToFill.map((dimension) => (
          <Button
            variant="outline"
            key={dimension.id}
            rightSection={<IconChevronRight />}
            onClick={() => setEditingDimension(dimension)}
            color="orange"
            c="orange.8"
            styles={(theme) => ({
              root: { border: `1px solid ${theme.colors.orange[8]}` },
            })}
          >
            {dimension.label}
          </Button>
        ))}
      </Stack>
      <EditDimensionDrawer
        dimension={editingDimension}
        onClose={() => setEditingDimension(undefined)}
      />
    </Alert>
  )
}

export const TypedDimensionFillerWrapper = ({
  datoDimensionIds,
  children,
}: Props) => {
  const dimensionsQuery = useListDimensionsQuery()
  return (
    <QueryWrapper query={dimensionsQuery}>
      {({ data }) => (
        <_TypedDimensionFillerWrapper
          datoDimensionIds={datoDimensionIds}
          allDimensions={data}
        >
          {children}
        </_TypedDimensionFillerWrapper>
      )}
    </QueryWrapper>
  )
}
