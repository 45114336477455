import { Modal, Group, Textarea, Button, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconPlus } from "@tabler/icons-react"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

export const EditComment = ({
  comment,
  setComment,
}: {
  comment: string | null
  setComment: (comment: string | null) => void
}) => {
  const { t } = useTranslation("survey")
  const [opened, { open, close }] = useDisclosure(false)
  const [value, setValue] = useState(comment)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      })
    })
  }, [opened])

  useEffect(() => {
    if (!opened) setValue(comment)
  }, [opened, comment])

  const action = comment ? t("editComment") : t("addComment")

  return (
    <>
      {comment ? (
        <Button variant="subtle" onClick={open}>
          {t("actions.edit")}
        </Button>
      ) : (
        <Button variant="subtle" onClick={open} c="gray">
          <IconPlus size={20} color="var(--mantine-color-green-5)" />
          {t("actions.add")}
        </Button>
      )}
      <Modal
        styles={{
          body: {
            padding: 0,
          },
        }}
        size={520}
        opened={opened}
        onClose={close}
        title={action}
      >
        <Stack gap={0}>
          <Textarea
            px={24}
            py={16}
            style={{
              borderTop: "1px solid var(--mantine-color-gray-3)",
              borderBottom: "1px solid var(--mantine-color-gray-3)",
            }}
            ref={inputRef}
            value={value ?? ""}
            onChange={(e) => setValue(e.target.value)}
          />
          <Group px={24} py={16} justify="end" wrap="nowrap">
            {!comment && (
              <Button color="gray" variant="light" onClick={close}>
                {t("actions.cancel")}
              </Button>
            )}
            {comment && (
              <Button
                color="red"
                onClick={() => {
                  setComment(null)
                  close()
                }}
              >
                {t("actions.deleteComment")}
              </Button>
            )}
            <Button
              onClick={() => {
                setComment(value)
                close()
              }}
            >
              {action}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  )
}
