import { notifications } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { AllDimensions } from "@kiosk/types/data/dato"
import {
  DimensionResponses,
  EditDimensionForm,
} from "@kiosk/types/endpoints/dimensions"
import { ConsolidatedDimension } from "@kiosk/types/utils/dimensions"

import {
  ALL_DIMENSIONS_QUERY,
  ALL_TYPED_DIMENSIONS_QUERY,
} from "@kiosk/shared/datocms/queries"
import { consolidateDimensions } from "@kiosk/shared/utils/dimensions/consolidateDimensions"

import {
  makeDatoPaginatedRequest,
  makeDatoRequest,
} from "@kiosk/front/api/datocms/datocms"
import { DatoAllTypedDimensionsResponse } from "@kiosk/front/api/datocms/types"
import { dimensionsKeys } from "@kiosk/front/api/dimensions/dimensionsKeys"
import { TypedDimension } from "@kiosk/front/api/dimensions/types"
import { apiClient } from "@kiosk/front/lib/apiClient"
import { queryClient } from "@kiosk/front/lib/queryClient"

export const listDimensions = async (): Promise<ConsolidatedDimension[]> => {
  const [datoDimensions, typedDimensionsOptionsByDatoId] = await Promise.all([
    makeDatoPaginatedRequest<AllDimensions>({
      query: ALL_DIMENSIONS_QUERY,
      fieldName: "allDimensions",
      metaFieldName: "_allDimensionsMeta",
    }),
    apiClient.get<DimensionResponses.TypedDimensions>("/dimensions/typed"),
  ])

  return consolidateDimensions(
    datoDimensions,
    typedDimensionsOptionsByDatoId.data,
  )
}

export const useListDimensionsQuery = () => {
  return useQuery<ConsolidatedDimension[]>({
    queryKey: dimensionsKeys.all(),
    queryFn: listDimensions,
  })
}

export const useListTypedDimensionsQuery = () => {
  return useQuery<TypedDimension[]>({
    queryKey: dimensionsKeys.typed(),
    queryFn: async () => {
      const [datoTypedDimensions, typedDimensionsOptionsByDatoId] =
        await Promise.all([
          makeDatoRequest({
            query: ALL_TYPED_DIMENSIONS_QUERY,
          }),
          apiClient.get<DimensionResponses.TypedDimensions>(
            "/dimensions/typed",
          ),
        ])

      return datoTypedDimensions.data.data.allDimensions.map(
        (d: DatoAllTypedDimensionsResponse["allDimensions"][number]) => ({
          ...d,
          options: typedDimensionsOptionsByDatoId.data[d.id] ?? [],
        }),
      )
    },
  })
}

export const useEditTypedDimensionMutation = (
  datoDimensionId: string,
  onSuccess: () => void,
) => {
  const { t } = useTranslation("dimensions")
  return useMutation({
    mutationFn: async (payload: EditDimensionForm) => {
      const { data } = await apiClient.post("/dimensions/typed", {
        datoDimensionId,
        ...payload,
      })
      return data
    },
    onSuccess: async () => {
      notifications.show({
        title: t("notifications.edit.success.title"),
        message: t("notifications.edit.success.message"),
        color: "green",
      })
      await queryClient.invalidateQueries({ queryKey: dimensionsKeys.all() })
      onSuccess()
    },
    onError: () => {
      notifications.show({
        title: t("notifications.edit.error.title"),
        message: t("notifications.edit.error.message"),
        color: "red",
      })
    },
  })
}
