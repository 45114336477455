import { useClerk } from "@clerk/clerk-react"
import { Avatar, Flex, Group, Menu, Text } from "@mantine/core"
import { IconLogout2 } from "@tabler/icons-react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import { useUser } from "@kiosk/front/components/auth/UserContext"
import { composeSettingsTabRoute } from "@kiosk/front/utils/constants/index"

export const UserPopover = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("common")

  const user = useUser()
  const { signOut } = useClerk()

  const handleSignOut = async () => {
    await signOut(() => navigate("/sign-in"))
  }

  const fullName = `${user.firstName} ${user.lastName}`

  return (
    <Group gap={14}>
      <Menu offset={12} position="bottom-end">
        <Menu.Target>
          <Group style={{ cursor: "pointer" }}>
            <Text c="gray.5" fw={500}>
              {fullName}
            </Text>
            <Avatar
              radius="xl"
              src={user?.avatarUrl}
              alt="user-profile-picutre"
              color="green"
              bg={user?.avatarUrl ? "transparent" : "green.1"}
            />
          </Group>
        </Menu.Target>
        <Menu.Dropdown style={{ boxShadow: "0px 6px 12px 0px #75819240" }}>
          <Menu.Item>
            <Link to={composeSettingsTabRoute("account")}>
              <Group gap={12} p={0} justify="space-between">
                <Avatar
                  radius="xl"
                  src={user?.avatarUrl}
                  alt="user-profile-picutre"
                  color="green"
                  bg={user?.avatarUrl ? "transparent" : "green.1"}
                />
                <Group>
                  <Flex direction="column">
                    <Text fw={600} c="black">
                      {fullName}
                    </Text>
                    <Text fz="xs" fw={400} c="gray.5">
                      {user.email}
                    </Text>
                  </Flex>
                </Group>
              </Group>
            </Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            leftSection={<IconLogout2 size={20} />}
            onClick={handleSignOut}
          >
            {t("auth.logout")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}
