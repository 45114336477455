import { useQuery } from "@tanstack/react-query"

import { AssignmentResponses } from "@kiosk/types/endpoints/assignments"

import { assignmentsKeys } from "@kiosk/front/api/surveys/assignments/assignmentsKeys"
import { apiClient } from "@kiosk/front/lib/apiClient"

export const useGetContributorAssignmentsQuery = (surveyId: string) =>
  useQuery({
    queryKey: assignmentsKeys.getContributorAssignments(surveyId),
    queryFn: async (): Promise<AssignmentResponses.ContributorAssignments> => {
      const response =
        await apiClient.get<AssignmentResponses.ContributorAssignments>(
          "/assignments",
          { params: { surveyId } },
        )

      return response.data
    },
  })
