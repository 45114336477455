import { PieChart } from "@mantine/charts"
import { Box, Group, Stack, Text } from "@mantine/core"
import { useMemo } from "react"

import { DimensionedDataPoint } from "@kiosk/front/api/dashboard/types"
import { colors } from "@kiosk/front/assets/theme/colors"
import { formatNumber } from "@kiosk/front/utils/format"

type Props = {
  dataPoint: DimensionedDataPoint
  projectedDimensionId: string
  fixedDimensions: Record<string, string | null>
}

const colorScale = colors.green.reduce<string[]>((acc, color, index) => {
  if (index % 3) acc.push(color)
  return acc
}, [])

export const DashboardPieChart = ({
  dataPoint,
  projectedDimensionId,
  fixedDimensions,
}: Props) => {
  const formattedValues = useMemo(() => {
    return dataPoint.values
      .filter((value) =>
        Object.entries(fixedDimensions).every(([dimensionId, optionId]) => {
          if (optionId === null) return true
          return value.dimensions.find(
            (d) => d.dimensionId === dimensionId && d.optionId === optionId,
          )
        }),
      )
      .map((value, index) => {
        const projectedDimension = value.dimensions.find(
          (d) => d.dimensionId === projectedDimensionId,
        )
        return {
          name: projectedDimension!.optionLabel,
          value: value.value,
          color: colorScale[index % colorScale.length],
        }
      })
  }, [dataPoint.values, fixedDimensions, projectedDimensionId])

  const projectedDimensionLabel = useMemo(() => {
    const projectedDimension = dataPoint.values[0].dimensions.find(
      (d) => d.dimensionId === projectedDimensionId,
    )
    return projectedDimension!.dimensionLabel
  }, [dataPoint.values, projectedDimensionId])

  return (
    <Group gap={24} wrap="nowrap" align="flex-start">
      <PieChart
        size={150}
        data={formattedValues}
        tooltipDataSource="segment"
        withLabels
        labelsPosition="inside"
        labelsType="percent"
        valueFormatter={(value) => formatNumber(value)}
        withTooltip
      />
      <Stack mt={4} gap={12}>
        <Text c="gray.5" fw={500}>
          {projectedDimensionLabel}
        </Text>
        {formattedValues.map((value) => (
          <Group key={value.name} gap={8} wrap="nowrap">
            <Box bg={value.color} w={8} h={8} style={{ borderRadius: 4 }} />
            <Text c="gray.5" fz="xs">
              {value.name}
            </Text>
          </Group>
        ))}
      </Stack>
    </Group>
  )
}
